import { useEffect, useRef, useState } from 'react';
import AdminTab from 'app/components/AdminTab';
import { PageMainLabel } from 'app/components/SHLabel';
import { tabNamesArray } from 'app/pages/components';
import styled from 'styled-components';
import BackButton from 'app/components/BackButton';
import { CommonPageWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { TeamProfile, teamProfileDefault } from './components/TeamProfile';
import { seasonValue, useNavigateToRoute } from 'utils/common';
import AdminCustomButton from 'app/components/AdminCustomButton';
import TeamRiderListing from './components/TeamRiderListing';
import TeamCoachesList from './components/team-coaches';
import { useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import useTeamApi from 'admin/hooks/useTeamApi';
import {
  FinancialResponsiblity,
  GeneralLookup,
  TeamRequest,
} from 'services/openapi';
import useTeamDetailsApi from './hooks/useTeamDetailsApi';
import SimpleReactValidator from 'simple-react-validator';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import {
  breadCrumpitemsTeams,
  financialResInitialData,
} from './staticDefaultData';
import { CommonLoader } from 'app/components/CommonLoader';
export const TeamIndividual = () => {
  const {
    getAllLookupData,
    lookUps,
    loading: lookUpLoading,
  } = useCommonLookUpDatas();
  const {
    createTeamProfile,
    setImageUploadBlobPayload,
    imageUploadBlobPayload,
    loading,
    teamCodeValue,
    generateTeamCode,
  } = useTeamApi();
  const {
    getTeamProfileData,
    teamProfileData: teamViewRes,
    teamDataLoading,
  } = useTeamDetailsApi();
  const redirect = useNavigateToRoute();
  let location = useLocation();
  const { action, id: teamId, tab } = useParams();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [tabSelected, setTabSelected] = useState<number>(
    parseInt(Cookies.get('teamsTab')) ?? 0,
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [teamProfileData, setTeamProfileData] =
    useState<TeamRequest>(teamProfileDefault);
  const [clonedteamProfileData, setClonedTeamProfileData] =
    useState<TeamRequest>();
  const [teamSigData, setTeamSigData] = useState<
    FinancialResponsiblity | undefined
  >(financialResInitialData);
  const [clonedTeamSigData, setClonedTeamSigData] = useState<
    FinancialResponsiblity | undefined
  >();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [financialDefault, setFinancialDefault] = useState<GeneralLookup>();
  const [imgSrc, setImgSrc] = useState<string | null>('');
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const baseUrl = isPastDataFlag
    ? '/admin/past-data/teams/view'
    : '/admin/teams/view';
  const handleBackClick = () => {
    if (isPastDataFlag) {
      redirect(`/admin/past-data/teams`);
    } else {
      redirect(`/admin/teams`);
    }
  };

  const handleEditPageBackClick = () => {
    if (action === 'edit') {
      redirect(`/admin/teams/view/profile-info/${teamId}`);
    } else {
      redirect(`/admin/teams`);
    }
  };

  const handleCancelClick = () => {
    if (action === 'add') {
      setImgSrc(teamProfileDefault?.teamImageUrl ?? '');
      setTeamProfileData(teamProfileDefault);
      setTeamSigData(financialResInitialData);
      simpleValidator.current.hideMessages();
      simpleValidator.current.visibleFields = [];
      setFinancialDefault(undefined);
    } else if (action === 'edit') {
      setImgSrc(teamViewRes?.teamImageUrl ?? '');
      setTeamProfileData(clonedteamProfileData!);
      setTeamSigData(clonedTeamSigData!);
    }
    setImageUploadBlobPayload(null);
    setForceUpdate(!forceUpdate);
  };

  const showMessagesForFields = (fields: string[]): boolean => {
    let messagesShown = false;
    fields.forEach(field => {
      simpleValidator.current.showMessageFor(field);
      if (!simpleValidator.current.fieldValid(field)) {
        messagesShown = true;
      }
    });
    // Force re-render to show error messages
    setForceUpdate(!forceUpdate);
    return messagesShown;
  };
  const handleSaveClick = () => {
    const messagesShown = showMessagesForFields([
      'Team Name',
      'Team Code',
      'Team Status',
      'Zip',
      'Description',
      'Contact Email',
      'Contact Phone/Mobile',
      'Contact State',
      'Contact Address',
      'Live Ranking Link',
      'Team Status',
      'Season',
      'Contact City',
      'Team Category',
    ]);

    const isValid = simpleValidator.current.allValid();

    const hasSignature =
      teamProfileData.finanacialResponsibleSignature === true;

    const createPayload = financialResponsibility => ({
      ...teamProfileData,
      financialResponsibility,
      mainContactName: null,
      season: seasonValue(lookUps),
    });
    const handleCreateTeamProfile = financialResponsibility => {
      createTeamProfile(
        createPayload(financialResponsibility),
        (imageUploadBlobPayload as Blob) ?? undefined,
      );
    };

    if (isValid && hasSignature) {
      handleCreateTeamProfile(teamSigData);
    } else if (!messagesShown && !hasSignature) {
      handleCreateTeamProfile(undefined);
    } else {
      simpleValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  };
  const handleEditClick = () => {
    redirect(`/admin/teams/edit/profile-info/${teamId}`);
    setIsDisabled?.(false);
  };

  const onTabSelectRedirect = e => {
    const { selected } = e;
    Cookies.set('teamsTab', selected);
    switch (selected) {
      case 0:
        redirect(`${baseUrl}/profile-info/${teamId}`);
        break;
      case 1:
        redirect(`${baseUrl}/coaches/${teamId}`);
        break;
      case 2:
        redirect(`${baseUrl}/payment-details/${teamId}`);
        break;
      case 3:
        redirect(`${baseUrl}/riders/${teamId}`);
        break;
      case 4:
        redirect(`${baseUrl}/important/${teamId}`);
        break;
      default:
        return '';
    }
  };
  //swicth tab active on browser back
  const switchTabsOnBrowserBack = (tab: string) => {
    if (tab) {
      switch (tab) {
        case 'profile-info':
          Cookies.set('teamsTab', 0);
          setTabSelected(0);
          break;
        case 'coaches':
          Cookies.set('teamsTab', 1);
          setTabSelected(1);
          break;
        case 'payment-details':
          Cookies.set('teamsTab', 2);
          setTabSelected(2);
          break;
        case 'riders':
          Cookies.set('teamsTab', 3);
          setTabSelected(3);
          break;
        case 'important':
          Cookies.set('teamsTab', 4);
          setTabSelected(4);
          break;
        default:
          return;
      }
    }
  };

  useEffect(() => {
    switchTabsOnBrowserBack(tab ?? '');
  }, [location.pathname]);

  useEffect(() => {
    if (action === 'view' || action === 'edit') {
      if (action === 'view') {
        simpleValidator.current.visibleFields = [];
        simpleValidator.current.hideMessages();
        setIsDisabled(true);
      }
      const filteredPhoneData = teamViewRes?.contactPhone
        ? teamViewRes?.contactPhone.replaceAll('-', '')
        : '';
      if (teamViewRes) {
        setTeamProfileData({
          ...teamViewRes,
          contactPhone: filteredPhoneData,
        });
        setTeamSigData(
          teamViewRes?.financialResponsibility === null
            ? {
                name: '',
                phone: '',
                street: '',
                city: '',
                state: { id: '', name: '', children: null },
                zip: '',
              }
            : teamViewRes?.financialResponsibility,
        );
        setClonedTeamProfileData({
          ...teamViewRes,
          contactPhone: filteredPhoneData,
        });
        setClonedTeamSigData(teamViewRes.financialResponsibility);
        setImgSrc(teamViewRes?.teamImageUrl ?? '');
      }
    } else if (action === 'add' || action === 'edit') {
      setIsDisabled(false);
    }
  }, [action, teamViewRes]);
  useEffect(() => {
    if (action !== 'add') getTeamProfileData(teamId ?? '');
  }, [teamId, action]);
  useEffect(() => {
    getAllLookupData('STATE,SEASON,ACTIVESTATUS,TEAM_CATEGORY');
  }, []);
  return (
    <CommonPageWrapper>
      {loading && <CommonLoader loaderType="main" />}
      <HeadingWrapper>
        <PageMainLabel>Teams</PageMainLabel>
        <CustomBreadCrumb preData={breadCrumpitemsTeams} />
      </HeadingWrapper>
      <SubWrapper
        style={{ justifyContent: 'space-between', padding: '15px 20px 15px 0' }}
      >
        {(action === 'add' || action === 'edit') && (
          <>
            <BackButton
              className="back-button"
              onClick={handleEditPageBackClick}
            />
            <ButtonWrapper>
              <AdminCustomButton
                buttonType="cancel"
                onClick={handleCancelClick}
              >
                Cancel
              </AdminCustomButton>
              <AdminCustomButton buttonType="save" onClick={handleSaveClick}>
                Save
              </AdminCustomButton>
            </ButtonWrapper>
          </>
        )}
      </SubWrapper>
      {action === 'add' || action === 'edit' ? (
        <TeamProfile
          isDisabled={isDisabled}
          teamProfileData={teamProfileData}
          setTeamProfileData={setTeamProfileData}
          setIsDisabled={setIsDisabled}
          onEditClick={handleEditClick}
          setImageUploadBlobPayload={setImageUploadBlobPayload}
          simpleValidator={simpleValidator}
          setTeamSigData={setTeamSigData}
          teamSigData={teamSigData}
          financialDefault={financialDefault}
          setFinancialDefault={setFinancialDefault}
          lookUps={lookUps}
          loading={teamDataLoading || lookUpLoading}
          setImgSrc={setImgSrc}
          imgSrc={imgSrc}
          teamCodeValue={teamCodeValue}
          generateTeamCode={generateTeamCode}
        />
      ) : (
        <>
          <TabWrapper>
            <AdminTab
              data={tabNamesArray}
              tabSelected={tabSelected}
              setTabSelected={setTabSelected}
              onTabSelect={onTabSelectRedirect}
            />
            <BackButton className="back-button" onClick={handleBackClick} />
          </TabWrapper>
          {tab === 'profile-info' && (
            <TeamProfile
              isDisabled={isDisabled}
              teamProfileData={teamProfileData}
              setTeamProfileData={setTeamProfileData}
              setIsDisabled={setIsDisabled}
              onEditClick={handleEditClick}
              setImageUploadBlobPayload={setImageUploadBlobPayload}
              simpleValidator={simpleValidator}
              setTeamSigData={setTeamSigData}
              teamSigData={teamSigData}
              lookUps={lookUps}
              loading={teamDataLoading || lookUpLoading}
              setImgSrc={setImgSrc}
              imgSrc={imgSrc}
              teamCodeValue={teamCodeValue}
              generateTeamCode={generateTeamCode}
            />
          )}
          {tab === 'coaches' && <TeamCoachesList id={teamId} />}
          {tab === 'riders' && <TeamRiderListing />}
        </>
      )}
    </CommonPageWrapper>
  );
};
const SubWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
`;
const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TabWrapper = styled.div`
  position: relative;
  .back-button {
    cursor: pointer;
    position: absolute;
    right: -12px;
    top: 7px;
    z-index: 2;
    span {
      font-weight: 600;
    }
    @media (min-width: 100px) and (max-width: 991px) {
      top: -38px;
    }
  }
`;
