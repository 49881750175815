import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '@fontsource/oswald';
import horseBg from './assets/img/bg/associateReturning.jpg';
import '@fontsource/roboto';
import useApi from 'services/mavapi/useApi';
import {
  Associate,
  AssociateService,
  GeneralLookup,
  LookupService,
} from 'services/openapi';
import { AssociateFormFields } from './components/AssociateFormFields';
import { useNavigate } from 'react-router-dom';
import { BackNextButton } from 'app/components/BackNextButton';
import { useDispatch, useSelector } from 'react-redux';
import { useManagePageSlice } from '../slice';
import { selectPageLoader } from '../slice/selectors';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import Cookies from 'js-cookie';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import { useRiderRegistrationStateSlice } from '../RiderRegistrationPage/slice';
import { selectPhotoUpload } from '../RiderRegistrationPage/slice/selectors';
import isApiError from 'utils/isApiError';
import { useErrorHandlingSlice } from 'app/error/slice';

type Props = {};
const defaultValue: Associate = {
  firstName: '',
  lastName: '',
  primaryPhone: '',
  email: '',
  street: '',
  city: '',
  state: {
    id: '',
    name: '',
    code: '',
    children: null,
    otherName: '',
    isAdminLive: false,
  },
  zip: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  role: {
    id: '',
    name: '',
    code: '',
    children: null,
    otherName: '',
    isAdminLive: false,
  },
};
const defaultPhotoValue = {
  formData: {
    file: undefined,
  },
};

export const AssociateMembershipPage = (props: Props) => {
  const currentSeason = Cookies.get('isAdminLiveFiltered');
  const { handleRequest } = useApi();
  const { actions } = useErrorHandlingSlice();
  const { associateCreateAssociate, associateUploadAssociatePhoto } =
    AssociateService;
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const { lookupGetAllLookups } = LookupService;
  const [stateData, setStateData] = useState<GeneralLookup[]>([]);
  const [rolesData, setRolesData] = useState<GeneralLookup[]>([]);
  const [associateFormPayload, setAssociateFormPayload] =
    useState<Associate>(defaultValue);
  const navigate = useNavigate();
  const [valid, setValid] = useState<boolean>(false);
  //for Loader
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState();
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultPhotoValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const photoUploadDataRedux = useSelector(selectPhotoUpload);

  const handleBack = () => {
    Cookies.remove('payload');
    Cookies.remove('paymentCancel');

    navigate('/login');
  };
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const handleGetLookups = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(
      lookupGetAllLookups('STATE,PAYER_TYPES,ASSOCIATE_ROLES')
        .then(res => {
          let varPayerTypes = res?.filter(item => {
            if (item?.type === 'PAYER_TYPES') {
              return item.values;
            }
          });
          let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
            if (item?.name === 'Associate') {
              return item ?? {};
            }
          });
          let roles = res.filter(item => {
            if (item.type === 'ASSOCIATE_ROLES') {
              return item.values;
            }
          });

          setPayerTypes(payerTypeObj);
          let varState = res?.filter(item => {
            if (item?.type === 'STATE') {
              return item.values;
            }
          });

          setStateData(varState?.[0]?.values!);
          setRolesData(roles?.[0].values ?? []);
          dispatch(PageActions.setPageLoader(false));
        })

        .catch(error => {}),
    );
  };

  const handleSubmit = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(associateCreateAssociate(associateFormPayload))
      .then(res => {
        if (res && !isApiError(res)) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          dispatch(PageActions.setPageLoader(false));
          const associateDataString = JSON.stringify(associateFormPayload);
          Cookies.set('payload', associateDataString);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'User created successfully',
              show: true,
              errorType: 'success',
            }),
          );
          navigate(`/payment/${payerTypes?.id}/${res}/false/false`);
          if (photoUploadPayload.formData?.file !== undefined) {
            handlePhotoUpload(res.toString(), photoUploadPayload.formData);
            dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
          }
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
        }
      })
      .catch(error => {
        dispatch(
          actions.dispatchToast({
            errorTxt: error.description,
            show: true,
            errorType: 'error',
          }),
        );
        dispatch(PageActions.setPageLoader(false));
      });
  };
  const handlePhotoUpload = (
    riderId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(associateUploadAssociatePhoto(riderId, formData))
      .then(res => {})
      .catch(error => {});
  };
  useEffect(() => {
    handleGetLookups();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
  }, []);

  useEffect(() => {
    const associateData = Cookies.get('payload');
    if (associateData) {
      const associateDataParse = JSON.parse(associateData);
      setAssociateFormPayload(associateDataParse);
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
    }
  }, [cancelPaymentCookie]);

  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper className="associate-MainWrapper">
          <LeftWrapper className="associate-LeftWrapper">
            <ShowyedaLogo />
            <div className="left-para">
              <p>Examples of Associate Membership:</p>
              <p>
                Show Stewards, Show Secretaries, Paddock Manager, Ring Stewards,
                Announcers, Any Paid or Volunteering member who is in the
                position of staff and decision making for a horse show.
                Individuals who plan to assist EWD riders at a horse show in the
                show ring.
              </p>
            </div>
          </LeftWrapper>
          <RightWrapper className="associate-RightWrapper">
            <BorderWrapper className="associate-BorderWrapper">
              <TopWrapper>
                <h3>
                  YEDA Associate Membership Form
                  <br />{' '}
                  {currentSeason === undefined ? '2023 - 2024' : currentSeason}
                </h3>
              </TopWrapper>
              <FirstParaWrapper>
                <p>
                  <i>
                    Payment of $30 fee and must be accompanied with this form
                  </i>
                </p>
              </FirstParaWrapper>
              <AssociateFormFields
                associateFormPayload={associateFormPayload}
                setAssociateFormPayload={setAssociateFormPayload}
                stateData={stateData}
                setValid={setValid}
                valid={valid}
                setPhotoUploadPayload={setPhotoUploadPayload}
                photoUploadPayload={photoUploadPayload}
                setFileAvailability={setFileAvailability}
                fileAvailability={fileAvailability}
                setUploadFile={setUploadFile}
                uploadFile={uploadFile}
                rolesData={rolesData}
              />
              <ButtonWrapper className="bg-color-change">
                <BackNextButton
                  classNameBack="btn-back"
                  classNameNxt="btn-nxt"
                  onClickNxt={handleSubmit}
                  onClickBack={handleBack}
                  next="Submit and Pay"
                  back="Cancel"
                />
              </ButtonWrapper>
            </BorderWrapper>
          </RightWrapper>
        </MainWrapper>
      )}
    </>
  );
};

const MainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  @media (min-width: 100px) and (max-width: 479px) {
    .associate-LeftWrapper {
      display: none;
    }
    .associate-RightWrapper {
      width: 100%;
      padding: 30px 20px 20px;
      .associate-BorderWrapper {
        padding: 20px 20px 10px !important;
      }
    }
  }
`;
const LeftWrapper = styled.div`
  width: 36%;
  height: 100%;
  background: url(${horseBg});
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center top;
  padding: 20px 35px 0px;

  p {
    color: #898989;
    font-family: 'Oswald';
    font-size: 16px;
  }
  .left-para {
    background: black;
    opacity: 0.7;
    padding: 0 20px;
    position: absolute;
    top: 66%;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    p {
      font-size: 11px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    p {
      font-size: 12px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    p {
      font-size: 13px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    p {
      font-size: 14px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    p {
      font-size: 14px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    p {
      font-size: 15px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding: 60px 35px;
    p {
      font-size: 16px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    p {
      font-size: 17px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    p {
      font-size: 19px;
    }
  }
`;
const RightWrapper = styled.div`
  width: 64%;
  height: 100%;
  background: #2a2a2a;
  padding: 30px 35px 20px;

  @media (min-width: 992px) and (max-width: 1199px) {
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
  }
`;
const FirstParaWrapper = styled.div`
  border-bottom: 1px solid #585858;
  padding-top: 40px;
  width: 100%;
  p {
    color: #ff9900;
    font-family: 'Roboto';
    font-size: 12px;
  }
`;
const BorderWrapper = styled.div`
  height: 100%;
  border: 1px solid #585858;
  border-radius: 20px;
  padding: 20px 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;

const TopWrapper = styled.div`
  background: #2a2a2a;
  padding: 0px 10px;
  position: absolute;
  top: -12px;
  h3 {
    font-weight: 500;
    color: #ffffff;
    margin: 0px !important;
    font-family: 'Roboto';
    text-align: center;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    h3 {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    h3 {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    h3 {
      font-size: 18px;
    }
  }
`;
