import * as React from 'react';
import styled from 'styled-components';
import Arrow from './assets/img/icon/arrow.png';
import { Dialog, DialogCloseEvent } from '@progress/kendo-react-dialogs';
import { GeneralLookup, LookupService } from 'services/openapi';
import useApi from 'services/mavapi/useApi';

interface PageState {
  skip: number;
  take: number;
}
type props = {
  onClose?: (event: DialogCloseEvent) => void;
};
const data = [
  { id: 1, team: 'Spartan Sprinters' },
  { id: 2, team: 'Club Majesty' },
];

const itemsPerPage = 54;
const TeamListPopUp = ({ onClose }: props) => {
  const { error: apiError, handleRequest } = useApi();
  const { lookupGetAllLookups } = LookupService;
  const [teamData, setTeamData] = React.useState<GeneralLookup[]>();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [activePage, setActivePage] = React.useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = teamData?.slice(startIndex, endIndex);
  const totalPages = Math.ceil(teamData?.length! / itemsPerPage);
  const paginationText = `Showing ${currentPage ? currentPage : 1}-${
    totalPages ? totalPages : 0
  } of ${totalPages ? totalPages : 0} pages`;

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
  };
  const handleGetLookups = () => {
    handleRequest(lookupGetAllLookups('TEAM'))
      .then(res => {
        console.log('res', res);
        let varState = res?.filter(item => {
          if (item?.type === 'TEAM') {
            return setTeamData(item?.values!);
          }
        });
      })
      .catch(error => {
        console.log('error', error);
      });
  };
  React.useEffect(() => {
    handleGetLookups();
  }, []);
  return (
    <StyledDialog title={'Team list 2023-24'} onClose={onClose}>
      <MainWrapper>
        <div className="grid-container">
          {displayedData?.map((item, index) => (
            <div className={`column-${(index % 18) + 1}`} key={item.id}>
              <div className="column-nested">
                <div className="team-id">{index + 1}</div>
                <div className="nested-backnumber">{item.name}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination">
          <div className="pagination-wrapper">
            <div className="pagination-btns">
              <button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <img src={Arrow} alt="prev-arrow" />
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  disabled={currentPage === index + 1}
                  className={activePage === index + 1 ? 'active' : ''}
                >
                  {index + 1}
                </button>
              ))}
              <button
                disabled={currentPage === totalPages}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <img src={Arrow} className="next-arrow" alt="next-arrow" />
              </button>
            </div>
            <div className="pagination-info">
              {teamData?.length! ? paginationText : ''}
            </div>
          </div>
        </div>
      </MainWrapper>
    </StyledDialog>
  );
};
const StyledDialog = styled(Dialog)`
  &.k-dialog-wrapper .k-dialog {
    width: 60%;
    height: 80%;
    background: #27292d;
    color: #a7a7a7;
    border-radius: 15px;
    border: 1px solid;
    border-color: #707070;
  }
  .k-window-title {
    color: #9597a6;
    font-size: 15px;
    font-weight: 600;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    font-size: 17px;
  }
  p {
    text-align: justify !important;
    margin: 10px !important;
  }
  .k-window-titlebar {
    border-bottom-color: transparent;
    padding: 5px;
    height: 10%;
  }
  &.k-dialog-wrapper {
    backdrop-filter: blur(10px) !important;
  }
  .k-window-content,
  .k-prompt-container {
    padding: 0px;
  }
  .pagination {
    padding: 10px 20px;
    display: grid;
    align-items: center;
    height: 60px;
  }
  .pagination-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .pagination-btns {
    gap: 20px;
    display: flex;
  }
  button {
    background: transparent;
    border: unset;
    color: white;
    border-radius: 5px;
  }
  .next-arrow {
    transform: rotate(180deg);
  }
  .active {
    background-color: #306fdb;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    &.k-dialog-wrapper .k-dialog {
      width: 95%;
      height: 85%;
      max-height: 665px;
    }
    .k-window-title {
      font-size: 11px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    &.k-dialog-wrapper .k-dialog {
      width: 90%;
      height: 85%;
      max-height: 665px;
    }
    .k-window-title {
      font-size: 12px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    &.k-dialog-wrapper .k-dialog {
      width: 80%;
      height: 85%;
      max-height: 665px;
    }
    .k-window-title {
      font-size: 13px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    &.k-dialog-wrapper .k-dialog {
      width: 70%;
      height: 85%;
      max-height: 665px;
    }
    .k-window-title {
      font-size: 15px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    &.k-dialog-wrapper .k-dialog {
      width: 65%;
      height: 85%;
      max-height: 665px;
    }
    .k-window-title {
      font-size: 16px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    &.k-dialog-wrapper .k-dialog {
      width: 60%;
      height: 85%;
      max-height: 665px;
    }
    .k-window-title {
      font-size: 17px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    &.k-dialog-wrapper .k-dialog {
      width: 50%;
      height: 85%;
      max-height: 665px;
    }
    .k-window-title {
      font-size: 18px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    &.k-dialog-wrapper .k-dialog {
      width: 50%;
      height: 85%;
      max-height: 665px;
    }
    .k-window-title {
      font-size: 19px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    &.k-dialog-wrapper .k-dialog {
      width: 50%;
      height: 85%;
      max-height: 665px;
    }
    .k-window-titlebar {
      border-bottom-color: transparent;
      padding: 5px;
      height: 8%;
    }
    .k-window-title {
      font-size: 21px;
    }
  }
`;
const MainWrapper = styled.div`
  border-top: 1px solid #707070;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .team-id {
    max-width: 40px;
    border-right: 1px solid #707070;
  }
  .nested-backnumber {
    color: #306fdb;
    max-width: 237px;
    border-right: 1px solid #707070;
  }
  .k-window-content {
    padding: 0px !important;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
    font-size: 12px;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    border-color: #707070;
    border: 1px solid #707070;
    width: 100%;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 5px;
      margin-left: 3px;
    }
    ::-webkit-scrollbar-track {
      background: transparent !important;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      width: 9px;
      background: #707070 !important;
      border-radius: 10px;
    }
  }

  .grid-container > div {
    background-color: #27292d;
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
  .column-nested {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
  .column-nested > div {
    flex: 1;
    padding: 5px 10px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .column-1 {
    grid-row: 1;
  }

  .column-2 {
    grid-row: 2;
  }

  .column-3 {
    grid-row: 3;
  }
  .column-4 {
    grid-row: 4;
  }
  .column-5 {
    grid-row: 5;
  }
  .column-6 {
    grid-row: 6;
  }
  .column-7 {
    grid-row: 7;
  }
  .column-8 {
    grid-row: 8;
  }
  .column-9 {
    grid-row: 9;
  }
  .column-10 {
    grid-row: 10;
  }
  .column-11 {
    grid-row: 11;
  }

  .column-12 {
    grid-row: 12;
  }

  .column-13 {
    grid-row: 13;
  }
  .column-14 {
    grid-row: 14;
  }
  .column-15 {
    grid-row: 15;
  }
  .column-16 {
    grid-row: 16;
  }
  .column-17 {
    grid-row: 17;
  }
  .column-18 {
    grid-row: 18;
    border-bottom: unset !important;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .team-id {
      max-width: 62px;
    }
    .column-nested > div {
      font-size: 8px;
      padding: 2px 5px;
    }
    .nested-backnumber {
      text-align: left;
    }
    .pagination {
      padding: 10px 5px;
    }
    .pagination-info {
      font-size: 8px;
      padding: 0 2px;
    }
    .pagination-btns {
      gap: 2px;
      button {
        font-size: 8px;
        height: 20px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .team-id {
      max-width: 62px;
    }
    .column-nested > div {
      font-size: 9px;
      padding: 2px 5px;
    }
    .nested-backnumber {
      text-align: left;
    }
    .pagination {
      padding: 10px 5px;
      height: 35px;
    }
    .pagination-info {
      font-size: 9px;
      padding: 0 2px;
    }
    .pagination-btns {
      gap: 4px;
      button {
        font-size: 9px;
        height: 20px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .team-id {
      max-width: 62px;
    }
    .column-nested > div {
      font-size: 10px;
      padding: 2px 5px;
    }
    .nested-backnumber {
      text-align: left;
    }
    .pagination {
      padding: 10px 5px;
    }
    .pagination-info {
      font-size: 10px;
      padding: 0 2px;
    }
    .pagination-btns {
      gap: 6px;
      button {
        font-size: 10px;
        height: 20px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .team-id {
      max-width: 62px;
    }
    .column-nested > div {
      font-size: 11px;
      padding: 2px 5px;
    }
    .nested-backnumber {
      /* padding: 2px 5px; */
      /* text-align: left; */
    }
    .pagination {
      padding: 10px 5px;
    }
    .pagination-info {
      font-size: 11px;
      padding: 0 2px;
    }
    .pagination-btns {
      gap: 10px;
      button {
        font-size: 11px;
        height: 20px;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .team-id {
      max-width: 62px;
    }
    .column-nested > div {
      font-size: 12px;
      padding: 4px 5px;
    }
    .nested-backnumber {
      /* padding: 2px 5px; */
      /* text-align: left; */
    }
    .pagination {
      padding: 10px 5px;
    }
    .pagination-info {
      font-size: 12px;
      padding: 0 2px;
    }
    .pagination-btns {
      gap: 10px;
      button {
        font-size: 12px;
        height: 20px;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .team-id {
      max-width: 62px;
    }
    .column-nested > div {
      font-size: 13px;
      padding: 5px 15px;
    }
    .nested-backnumber {
      /* padding: 2px 5px; */
      /* text-align: left; */
    }
    .pagination {
      padding: 10px 5px;
      height: 80px;
    }
    .pagination-info {
      font-size: 13px;
      padding: 0 2px;
    }
    .pagination-btns {
      gap: 10px;
      button {
        font-size: 14px;
        height: 20px;
      }
    }
    .pagination-wrapper {
      padding: 10px 0px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .team-id {
      max-width: 62px;
    }
    .column-nested > div {
      font-size: 14px;
      padding: 5px 15px 0px;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .nested-backnumber {
      /* padding: 2px 5px; */
      /* text-align: left; */
    }
    .pagination {
      padding: 10px 5px;
      height: 80px;
    }
    .pagination-info {
      font-size: 14px;
      padding: 0 5px;
    }
    .pagination-btns {
      gap: 15px;
      button {
        font-size: 15px;
        height: 20px;
      }
    }
    .pagination-wrapper {
      padding: 10px 0px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .team-id {
      max-width: 62px;
    }
    .column-nested > div {
      font-size: 17px;
      padding: 5px 15px 0px;
    }
    .nested-backnumber {
      /* padding: 2px 5px; */
      /* text-align: left; */
    }
    .pagination {
      padding: 10px 15px;
      height: 80px;
    }
    .pagination-info {
      font-size: 17px;
      padding: 0 5px;
    }
    .pagination-btns {
      gap: 25px;
      button {
        font-size: 18px;
        height: 25px;
      }
    }
    .pagination-wrapper {
      padding: 10px 0px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .team-id {
      max-width: 65px;
    }
    .column-nested > div {
      font-size: 18px;
      padding: 5px 15px 0px;
    }
    .nested-backnumber {
      /* padding: 2px 5px; */
      /* text-align: left; */
    }
    .pagination {
      padding: 10px 15px;
      height: 80px;
    }
    .pagination-info {
      font-size: 18px;
      padding: 0 5px;
    }
    .pagination-btns {
      gap: 30px;
      button {
        font-size: 19px;
        height: 30px;
      }
    }
    .pagination-wrapper {
      padding: 10px 0px;
    }
  }
`;

export default TeamListPopUp;
