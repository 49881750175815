import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import CoachProfileCell from 'app/components/CustomDataGrid/Cells/CoachProfileCell';
import IdProfileCell from 'app/components/CustomDataGrid/Cells/IdProfileCell';
import TeamMainContactCell from 'app/components/CustomDataGrid/Cells/MainContactCell';
import TeamStatusCell from 'app/components/CustomDataGrid/Cells/StatusCell';
import TeamNameCell from 'app/components/CustomDataGrid/Cells/TeamNameCell';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import { CustomDeletePopup } from 'app/components/CustomDeletePopup';
import React, { useState } from 'react';
import { GeneralLookup, TeamsWrapper } from 'services/openapi';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useErrorHandlingSlice } from 'app/error/slice';
import { useNavigateToRoute } from 'utils/common';
import TeamContactAddressCell from 'app/components/CustomDataGrid/Cells/ContactAddressCell';
import { useLocation } from 'react-router-dom';
import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import HorizontalKebabCell from 'app/components/CustomDataGrid/Cells/HorizontalKebabCell';
import PaymentStatusCell from 'app/components/CustomDataGrid/Cells/PaymentStatusCell';

type Props = {
  teamProfileData?: TeamsWrapper;
  teamActive?: TeamsWrapper;
  setPageSkip: React.Dispatch<React.SetStateAction<number | undefined>>;
  pageSkip: number | undefined;
  fetchManageGetTeams: (
    searchInput?: string | undefined,
    status?: string | undefined,
    seasonId?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  teamActiveOrInactive: (
    id: string,
    activeStatusId?: string | undefined,
    seasonId?: string | undefined,
    searchInput?: string | undefined,
    status?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
    item?: string,
  ) => void;
  debounceTeamSearch: string;
  deleteTeam: (
    id?: string | undefined,
    searchInput?: string | undefined,
    status?: string | undefined,
    seasonId?: string,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  selectedItemId: string | null;
  lookup: GeneralLookup[];
  sortField: string | undefined;
  sortOrder: number | undefined;
  setSortField: React.Dispatch<React.SetStateAction<string>>;
  setSortOrder: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
};

const TeamsTable = ({
  teamProfileData,
  setPageSkip,
  pageSkip,
  debounceTeamSearch,
  fetchManageGetTeams,
  teamActiveOrInactive,
  deleteTeam,
  selectedItemId,
  lookup,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  loading,
}: Props) => {
  const redirect = useNavigateToRoute();
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const [teamID, setTeamID] = useState('');
  const activeId = lookup.find(item => item.name === 'Active');
  const inActiveId = lookup.find(item => item.name === 'Inactive');
  const initialSort: Array<SortDescriptor> = [
    { field: 'teamName', dir: 'asc' },
  ];
  const [sort, setSort] = React.useState<Array<SortDescriptor>>(initialSort);
  const handleRowClick = () => {};
  const handleMoreClick = (item: string, e) => {
    const id = e.dataItem.id;

    if (item === 'Make active') {
      teamActiveOrInactive(
        id,
        activeId?.id,
        e.dataItem.season?.id,
        debounceTeamSearch,
        selectedItemId ?? '',
        sortField,
        sortOrder,
        pageSkip,
        10,
        item,
      );
    } else if (item === 'Make inactive') {
      teamActiveOrInactive(
        id,
        inActiveId?.id,
        e.dataItem.season?.id,
        debounceTeamSearch,
        selectedItemId ?? '',
        sortField,
        sortOrder,
        pageSkip,
        10,
        item,
      );
    } else if (item === 'Delete team') {
      setShowDeletePopup(true);
    } else if (item === 'Edit team info') {
      redirect(`/admin/teams/${'edit'}/profile-info/${id}`);
    }
  };

  const handleViewClick = id => {
    if (isPastDataFlag) {
      redirect(`/admin/past-data/teams/${`view`}/profile-info/${id}`);
    } else {
      redirect(`/admin/teams/${`view`}/profile-info/${id}`);
    }
  };

  const onDataClick = (data: any) => {
    setTeamID(data.id);
  };
  const handleDeleteTeam = () => {
    deleteTeam(
      teamID,
      debounceTeamSearch,
      selectedItemId ?? '',
      '',
      sortField,
      sortOrder,
      pageSkip,
      10,
    );
    setShowDeletePopup(false);
    dispatch(
      actions.dispatchToast({
        errorTxt: 'Team deleted successfully',
        show: true,
        errorType: 'success',
      }),
    );
  };
  const handleSortChange = e => {
    let sortOrderValue = e.dir === 'asc' ? 0 : 1;
    setSortOrder(sortOrderValue);
    setSortField(e.field);
    fetchManageGetTeams(
      debounceTeamSearch,
      selectedItemId ?? '',
      '',
      e.field,
      sortOrderValue,
      pageSkip,
      10,
    );
  };

  const TeamsColumn = [
    {
      field: 'teamId',
      title: 'Team ID',
      cell: e => <IdProfileCell e={e} onClick={handleRowClick} />,
      width: '100',
      sortable: false,
    },
    {
      field: 'teamName',
      title: 'Team Name',
      cell: e => (
        <TeamNameCell
          e={e}
          onClick={handleRowClick}
          onViewClick={event => handleViewClick(event)}
        />
      ),
      width: '300',
      locked: true,
      sortable: true,
    },

    {
      field: 'coach',
      title: 'Coach (s)',
      cell: e => <CoachProfileCell e={e} onClick={handleRowClick} />,
      width: '250',
      sortable: true,
    },
    {
      field: '',
      title: 'Main Contact',
      cell: e => <TeamMainContactCell e={e} onClick={handleRowClick} />,
      width: '250',
      sortable: false,
    },
    {
      field: 'contactAddress',
      title: 'Contact Address',
      cell: e => <TeamContactAddressCell e={e} onClick={handleRowClick} />,
      width: '250',
      sortable: false,
    },
    {
      field: 'financialRespSignature',
      title: 'Financial Resp Signature',
      width: '200',
      sortable: false,
    },
    {
      field: 'isPaymentComplete',
      title: 'Payment Completed',
      cell: e => <PaymentStatusCell e={e} />,
      width: '150',
      sortable: false,
    },
    {
      field: 'teamStatus',
      title: 'Status',
      cell: e => <TeamStatusCell e={e} onClick={handleRowClick} />,
      width: isPastDataFlag ? '100' : '150',
      sortable: false,
    },
    {
      field: 'action',
      title: 'Action',
      cell: e => (
        <HorizontalKebabCell
          isAssigned={true}
          e={e}
          onMoreClick={handleMoreClick}
          isPastDataFlag={isPastDataFlag}
        />
      ),
      width: isPastDataFlag ? '100' : '150',
      sortable: false,
    },
  ];
  const TeamsPastDataColumn = TeamsColumn.slice(0, -1);
  const handlePageChange = event => {
    setPageSkip(event.page.skip);
    fetchManageGetTeams(
      debounceTeamSearch,
      selectedItemId ?? '',
      '',
      sortField,
      sortOrder,
      event.page.skip,
      10,
    );
  };
  return (
    <>
      {loading ? (
        <TableSkeletonLoader rowContent={8} width={100} headingWidth={80} />
      ) : (
        <>
          <CardWrapper style={{ paddingTop: '15px' }}>
            <CardSubWrapper className="d-flex"></CardSubWrapper>
            <GridWrapper>
              <CustomDataGrid
                showPagination
                data={teamProfileData?.teams ?? []}
                columns={isPastDataFlag ? TeamsPastDataColumn : TeamsColumn}
                pageSize={10}
                totalCount={teamProfileData?.pageDetails?.count}
                onPageChange={event => {
                  handlePageChange(event);
                }}
                skip={pageSkip}
                sortable={{
                  allowUnsort: false,
                  mode: 'single',
                }}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                  let sortvalue = e.sort[0];
                  setSort(e.sort);
                  handleSortChange(sortvalue);
                }}
              />
            </GridWrapper>
          </CardWrapper>
          <CustomDeletePopup
            deleteIcon={true}
            headerUnderline={false}
            popupSubLabel="Are you sure you want to delete this team?"
            className="body-scroll-lock height-full delete-update"
            popupLabel="Delete team"
            setVisible={setShowDeletePopup}
            visible={showDeletePopup}
            align="centre"
            btnLabel1="Cancel"
            btnLabel2="Delete"
            handlebtn2Click={handleDeleteTeam}
            handlebtn1Click={() => {
              setShowDeletePopup(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default TeamsTable;

const GridWrapper = styled.div``;
