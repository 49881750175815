import React from 'react';
import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import SHdropdown from 'app/components/SHdropdown';
import { GeneralLookup, TeamRegistration } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import { BackNextButton } from 'app/components/BackNextButton';
import CustomDragAndDropFiles from 'app/components/CustomDragAndDropFiles';
import { useDispatch } from 'react-redux';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';

type Props = {
  tryItTeamData: TeamRegistration;
  setTryItTeamData: React.Dispatch<React.SetStateAction<TeamRegistration>>;
  handleRegBack: () => void;
  createNewTeam: (data: TeamRegistration) => void;
  stateData?: GeneralLookup[];
  setPhotoUploadPayload: React.Dispatch<
    React.SetStateAction<{
      formData?:
        | {
            file?: Blob | undefined;
          }
        | undefined;
    }>
  >;
  photoUploadPayload: {
    formData?:
      | {
          file?: Blob | undefined;
        }
      | undefined;
  };
  setFileAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  fileAvailability: boolean;
  setUploadFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  uploadFile: File | null | undefined;
};
export const FinancialInfoForm = ({
  tryItTeamData,
  setTryItTeamData,
  handleRegBack,
  createNewTeam,
  stateData,
  setPhotoUploadPayload,
  photoUploadPayload,
  setFileAvailability,
  fileAvailability,
  setUploadFile,
  uploadFile,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const dispatch = useDispatch();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const [valid, setValid] = useState<boolean>(false);
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setTryItTeamData({
        ...tryItTeamData,
        financialResponsibility: {
          ...tryItTeamData.financialResponsibility!,
          phone: inputValue,
        },
      });
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setTryItTeamData({
        ...tryItTeamData,
        financialResponsibility: {
          ...tryItTeamData.financialResponsibility!,
          zip: inputValue,
        },
      });
    }
  };
  const handleFileUpload = e => {
    var file = e.target.files[0];
    dispatch(manageAppActions.setPhotoUpload(file));

    setUploadFile?.(file);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: file },
    });
  };
  const handleRemoveFile = e => {
    setUploadFile?.(undefined);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  const handleFileOnCancelClick = e => {
    setUploadFile?.(undefined);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };

  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [tryItTeamData]);

  React.useEffect(() => {
    if (uploadFile === undefined) {
      setFileAvailability?.(true);
    } else {
      setFileAvailability?.(false);
    }
  }, [uploadFile]);
  return (
    <MainWrapper className="sub-MainWrapper">
      <OverflowWrapper>
        <h4 className="Financial-heading">Financial Responsibility</h4>
        <p className="first-para">
          In order to qualify as a YEDA Team, at least one member of the team’s
          Coaching Staff, School, Farm, or other named Individual must sign the
          below indicating financial responsibilityfor the team and any debts
          incurred.
        </p>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              onChange={e =>
                setTryItTeamData({
                  ...tryItTeamData,
                  financialResponsibility: {
                    ...tryItTeamData.financialResponsibility!,
                    name: e.target.value!,
                  },
                })
              }
              value={tryItTeamData?.financialResponsibility?.name}
              label="Name"
              type="text"
              name="name"
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Phone Number"
              type="tel"
              name="phoneNumber"
              onChange={handlePhChange}
              value={tryItTeamData?.financialResponsibility?.phone}
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <MainAddressWrapper>
          <div className="horizontal-line">
            <span>Main Address</span>
          </div>
          <TextFieldGrid>
            <TextFieldWrapper>
              <StyledTextField
                onChange={e =>
                  setTryItTeamData({
                    ...tryItTeamData,
                    financialResponsibility: {
                      ...tryItTeamData.financialResponsibility!,
                      street: e.target.value!,
                    },
                  })
                }
                value={tryItTeamData?.financialResponsibility?.street}
                label="Street Address"
                type="text"
                name="streetAddress"
                validations={'required'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={e =>
                  setTryItTeamData({
                    ...tryItTeamData,
                    financialResponsibility: {
                      ...tryItTeamData.financialResponsibility!,
                      city: e.target.value!,
                    },
                  })
                }
                value={tryItTeamData?.financialResponsibility?.city}
                label="City"
                type="text"
                name="city"
                validations={'required|max:40'}
                validator={simpleValidator.current}
                maxLength={40}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <SHdropdown
                className="drop-down"
                label="State"
                data={stateData}
                onDataChange={e =>
                  setTryItTeamData({
                    ...tryItTeamData,
                    financialResponsibility: {
                      ...tryItTeamData.financialResponsibility!,
                      state: e,
                    },
                  })
                }
                defaultValue={tryItTeamData?.financialResponsibility?.state}
                validations={'required'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                onChange={handleZipChange}
                value={tryItTeamData?.financialResponsibility?.zip}
                label="Zip"
                type="text"
                name="zip"
                validations={'required|alpha_num|min:5|max:9'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
          </TextFieldGrid>
        </MainAddressWrapper>
        {/* <CodeWrapper>
          <Button
            className="btn-generate"
            onClick={() => generateTeamCode(tryItTeamData.name)}
          >
            Generate Team Code:
          </Button>
          <span style={teamCodeStyle}>{teamCodeValue}</span>
        </CodeWrapper> */}
        <PhotoUploadWrapper>
          <h3 className="photo-title"> Upload your current photo below. </h3>
          <CustomDragAndDropFiles
            className="dragNdrop"
            onCancel={e => handleFileOnCancelClick(e)}
            onChange={e => handleFileUpload(e)}
            uploadBtnDisabled={!fileAvailability}
            onRemoveFile={handleRemoveFile}
            chooseFileBtnDisabled={!fileAvailability}
            uploadFile={uploadFile}
          />
        </PhotoUploadWrapper>
        <BottomRightWrapper className="bg-color-change">
          <ButtonWrapper>
            <BackNextButton
              classNameBack="btn-back"
              classNameNxt="btn-nxt"
              onClickNxt={() => createNewTeam(tryItTeamData)}
              onClickBack={handleRegBack}
              next="Submit and pay"
              back="Back"
              disableNext={valid}
            />
          </ButtonWrapper>
        </BottomRightWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 70px;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 10px;
    left: 0;
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
const ButtonWrapper = styled.div``;
const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 250px);

  .Financial-heading {
    margin: 0px 12px;
    font-weight: 100;
    font-family: 'Roboto';
    color: #9597a6;
    font-size: 17px;
  }

  .first-para {
    margin: 12px 12px;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: oblique;
  }
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  .btn-generate {
    background-color: #306fdb;
    border: 1px solid #2a2a2a;
    color: #ffffff;
    border-radius: 6px;
    width: 170px;
    height: 40px;
    font-weight: 700;
    font-size: 13px;
    margin: 15px 10px 15px 0px;
  }
`;
const OverflowWrapper = styled.div`
  height: 100%;
  overflow: auto;
  padding: 10px 0px 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
`;
const TextFieldWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;
const MainAddressWrapper = styled.div`
  .horizontal-line {
    display: flex;
    align-items: baseline;
  }
  .horizontal-line span {
    margin: 0px 12px 20px;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const PhotoUploadWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .photo-title {
    color: #a7a7a7;
    font-family: 'Roboto';
    font-size: 15px;
    text-align: center;
  }
`;
const CodeWrapper = styled.div`
  & .k-button-solid-base:focus {
    box-shadow: unset !important;
  }
`;
