import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useSIFReportsApi from 'admin/hooks/useSIFReportApi';
import { SIFReportSummary } from 'services/openapi';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { HeadingWrapper } from 'admin/components/wrappers';
import { PageMainLabel } from 'app/components/SHLabel';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { breadCrumbDataModel } from '../TeamsPage';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import { SHToolTip } from 'app/components/SHToolTip';

type Props = {};

interface TransformedMember {
  teamShortName: string;
  firstName: string;
  lastName: string;
  backNumber: string;
  grade: string;
  graduationDate?: number;
  totalAmountPaid: number;
  lifeTimePoints?: number;
  [key: string]: any;
}

interface TransformedScholarship {
  classOf: string;
  totalAmountPaidForGraduation: number;
  members: TransformedMember[];
}

const breadCrumbItems = [
  {
    id: '',
    text: 'Showyeda admin',
    disabled: true,
  },
  {
    id: '',
    text: 'SIF Report',
    disabled: true,
  },
];

export const SIFReports: React.FC<Props> = () => {
  const [breadcrumbPreData, setBreadcrumbPreData] =
    React.useState<breadCrumbDataModel[]>(breadCrumbItems);
  const { getSIFDetails, SIFData, downloadSIFReport, loading } =
    useSIFReportsApi();
  const [transformedData, setTransformedData] = useState<
    TransformedScholarship[]
  >([]);
  const [expandedIndex, setExpandedIndex] = useState<number>();

  const handleDownloadTeampointReport = async () => {
    try {
      const url = await downloadSIFReport();
      const filename = url.split('/').pop();
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const objectUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objectUrl;
          link.download = filename!;

          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(objectUrl);
        })
        .catch(error => {
          console.error('Error downloading file:', error);
        });
      return url;
    } catch (error) {
      console.error('Error downloading team point report:', error);
      throw error;
    }
  };

  const transformData = (
    inputData: SIFReportSummary,
  ): TransformedScholarship[] => {
    const { seasons, enrolledScholarShip } = inputData;

    if (!enrolledScholarShip || !seasons) return [];

    return enrolledScholarShip.map(scholarship => {
      const members: TransformedMember[] =
        scholarship.members?.map(member => {
          const transformedMember: TransformedMember = {
            teamShortName: member.teamShortName ?? '',
            firstName: member.firstName ?? '',
            lastName: member.lastName ?? '',
            backNumber: member.backNumber ?? '',
            grade: member.grade ?? '',
            graduationDate: member.graduationDate ?? 0,
            totalAmountPaid: member.totalAmountPaid ?? 0,
            lifeTimePoints: member.lifeTimePoints ?? 0,
          };

          seasons.forEach(season => {
            const memberSeason = member.season?.find(
              s => s.seasonId === season.seasonId,
            );
            if (memberSeason && season.seasonName) {
              transformedMember[season.seasonName] = {
                seasonId: season.seasonId ?? '',
                seasonName: season.seasonName,
                isLive: season.isLive ?? false,
                amount: memberSeason.amount ?? 0,
                points: memberSeason.points ?? 0,
              };
            }
          });

          return transformedMember;
        }) ?? [];

      const totalAmountMember: TransformedMember = {
        teamShortName: '',
        firstName: '',
        lastName: '',
        backNumber: '',
        grade: '',
        graduationDate: undefined,
        totalAmountPaid: scholarship.totalAmountPaidForGraduation ?? 0,
        lifeTimePoints: undefined,
      };

      seasons.forEach(season => {
        const seasonData = scholarship.totalAmountPerSeason?.find(
          s => s.seasonId === season.seasonId,
        );
        if (seasonData && season.seasonName) {
          totalAmountMember[season.seasonName] = {
            seasonId: season.seasonId,
            seasonName: season.seasonName,
            isLive: season.isLive ?? false,
            amount: seasonData.amount === 0 ? undefined : seasonData.amount,
            points: undefined,
          };
        }
      });

      members.push(totalAmountMember);

      return {
        classOf: scholarship.classOf ?? '',
        totalAmountPaidForGraduation:
          scholarship.totalAmountPaidForGraduation ?? 0,
        members: members,
      };
    });
  };

  useEffect(() => {
    if (SIFData) {
      const transformed = transformData(SIFData);
      setTransformedData(transformed);
    }
  }, [SIFData]);

  useEffect(() => {
    getSIFDetails();
  }, []);

  const CustomHeaderCell = ({ topText, bottomText }) => (
    <CustomHeaderCellWrapper>
      <div>{topText}</div>
      <div>{bottomText}</div>
    </CustomHeaderCellWrapper>
  );

  const renderGrid = (scholarship: TransformedScholarship) => {
    const seasons =
      (SIFData?.seasons
        ?.map(season => season?.seasonName)
        .filter(Boolean) as string[]) || [];
    const seasonLiveStatus = seasons.reduce((acc, season) => {
      acc[season] = scholarship.members.some(member => member[season]?.isLive);
      return acc;
    }, {} as Record<string, boolean>);
    const seasonColumns = seasons.map(season => ({
      field: season,
      title: season,
      width: '170px',
      headerCell: () => (
        <th
          style={{
            backgroundColor: seasonLiveStatus[season] ? '#8EE08E' : 'inherit',
            border: 'none',
            width: '170px',
            height: '70px',
          }}
        >
          <div style={{ padding: ' 0.5rem 0.5rem' }}> {season}</div>
        </th>
      ),
      cell: (props: { dataItem: TransformedMember }) => (
        <td>
          <SeasonDiv
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <SHToolTip position="top">
              <span
                title={
                  props.dataItem[season]?.amount === undefined
                    ? '$0.00'
                    : props.dataItem[season]?.amount === 0
                    ? '_'
                    : '$' + props.dataItem[season]?.amount?.toFixed(2)
                }
              >
                {props.dataItem[season]?.amount === undefined
                  ? '$0.00'
                  : props.dataItem[season]?.amount === 0
                  ? '_'
                  : '$' + props.dataItem[season]?.amount?.toFixed(2)}
              </span>
            </SHToolTip>
            <SHToolTip position="top">
              <span
                title={
                  props.dataItem[season]?.points === 0
                    ? '_'
                    : props.dataItem[season]?.points
                }
              >
                {props.dataItem[season]?.points === 0
                  ? '_'
                  : props.dataItem[season]?.points}
              </span>
            </SHToolTip>
          </SeasonDiv>
        </td>
      ),
    }));

    return (
      <CustomGrid data={scholarship.members} style={{ widows: '100%' }}>
        <Column field="teamShortName" title="Team" width="90px" locked />
        <Column field="firstName" title="First Name" width="130px" locked />
        <Column field="lastName" title="Last Name" width="130px" locked />
        <Column field="backNumber" title="Back #" width="70px" locked />
        <Column field="grade" title="Grade" locked />
        <Column
          field="graduationDate"
          title="Graduation Date"
          width="120px"
          locked
        />
        <Column
          field="totalAmountPaid"
          title="Tot. Amount Paid"
          width="100px"
          format="{0:c2}"
          locked
          headerCell={props => (
            <CustomHeaderCell
              {...props}
              topText="Tot. Amount"
              bottomText="Paid"
            />
          )}
        />
        <Column
          field="lifeTimePoints"
          title="Life Time Points"
          width="100px"
          locked
          headerCell={props => (
            <CustomHeaderCell
              {...props}
              topText="Life Time"
              bottomText="Points"
            />
          )}
        />
        {seasonColumns.map((column, index) => (
          <Column key={index} {...column} />
        ))}
      </CustomGrid>
    );
  };

  const handleSelect = (e: any) => {
    setExpandedIndex(e.target.props.index);
  };

  return (
    <Wrapper>
      <HeadingWrapper>
        <PageMainLabel>SIF Report</PageMainLabel>
        <CustomBreadCrumb
          preData={breadcrumbPreData}
          setPreData={setBreadcrumbPreData}
        />
      </HeadingWrapper>
      <HeaderWrapper>
        <div></div>
        <AdminCustomButton
          buttonType="primary"
          isActive={false}
          className="fill-button"
          onClick={handleDownloadTeampointReport}
        >
          Download
        </AdminCustomButton>
      </HeaderWrapper>
      {loading ? (
        <TableSkeletonLoader rowContent={10} width={100} headingWidth={40} />
      ) : (
        <>
          <TitleWrapper>
            Enrolled Scholarship Incentive Fund Members
          </TitleWrapper>
          <PanelBar expandMode="single" onSelect={handleSelect}>
            {transformedData.map((scholarship, index) => (
              <PanelBarItem
                key={index}
                title={scholarship.classOf}
                expanded={index === expandedIndex}
                animation
              >
                {renderGrid(scholarship)}
              </PanelBarItem>
            ))}
          </PanelBar>
        </>
      )}
    </Wrapper>
  );
};

export default SIFReports;
const SeasonDiv = styled.div`
  > div {
    width: 50%;
  }
  .tooltip-style {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
const Wrapper = styled.div`
  width: calc(100% - 200px);
  padding: 26px 40px 0px 40px;
  overflow: scroll;
  height: 100vh;
  ::-webkit-scrollbar {
    height: 0;
    margin-top: 3px;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #dce4fe;
    border-radius: 5px;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    width: 1px;
    background: #caced8;
    border-radius: 5px;
  }

  .k-panelbar > .k-panelbar-header > .k-link.k-selected {
    background-color: #e7ecff;
    color: black;
  }
`;

const CustomGrid = styled(Grid)`
  .k-grid-content {
    ::-webkit-scrollbar {
      height: 5px;
      margin-top: 3px;
      width: 0;
    }

    ::-webkit-scrollbar-track {
      background: #dce4fe;
      border-radius: 5px;
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      width: 1px;
      background: #caced8;
      border-radius: 5px;
    }
  }
  .k-grid-header {
    padding-inline-end: 0;
  }
  .k-column-title {
    color: #475467;
    font-weight: 600;
    font-size: 13px;
  }
  &.k-grid th {
    align-content: center;
    padding: 0;
    color: #475467;
    font-weight: 600;
    font-size: 13px;
  }
  .k-header > .k-cell-inner {
    margin: unset;
  }
  .k-table-md .k-table-td {
    font-size: 13px;
  }
  // Styles for the last row
  .k-grid-content table tr:last-child {
    background-color: #f0f0f0;
    font-weight: bold;
    font-size: 13px;
  }

  .k-grid-content table tr:last-child td {
    border-top: 1px solid #000;
    font-size: 13px;
  }
  .k-master-row .k-grid-content-sticky {
    border-color: #dee2e6;
  }
  .k-grid-header .k-header.k-grid-header-sticky {
    border-color: #dee2e6;
  }
`;

const CustomHeaderCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: 'Inter';
  font-size: 13px;
  line-height: 1.2;
  color: #475467;
  font-weight: 600;
  padding: 0.5rem;
  border-bottom: none !important;

  div {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0 30px 0;
  .fill-button {
    margin-right: 0px;
  }
`;
const TitleWrapper = styled.label`
  display: block;
  font-family: 'Inter';
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  padding: 15px 0;
  text-align: center;
  background: #000;
`;
