import AdminTab from 'app/components/AdminTab';
import { PageMainLabel } from 'app/components/SHLabel';
import styled from 'styled-components';
import BackButton from 'app/components/BackButton';
import { CommonPageWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigateToRoute } from 'utils/common';
import { ShowViewProfile } from './Components/ShowViewProfile/ShowViewProfile';
import {
  showsViewbreadCrumpitems,
  tabShowsNamesArray,
} from './Components/ShowViewProfile/staticData';
import useShowApi from 'admin/hooks/useShowApi';
import ShowManageRider from './Components/ShowManageRider';
import { ShowRegistration } from 'services/openapi';
import Cookies from 'js-cookie';

export const ShowIndividual = () => {
  const { id: showId } = useParams();
  const redirect = useNavigateToRoute();
  const [tabSelected, setTabSelected] = useState<number>(0);
  const location = useLocation();
  const isPastDataFlag = location.pathname.includes('past-data');
  const [showData, setShowData] = useState<ShowRegistration>();
  const handleBackClick = () => {
    if (isPastDataFlag) {
      redirect(`/admin/past-data/shows`);
    } else {
      redirect('/admin/shows');
    }
  };
  const onTabSelectRedirect = e => {
    const { selected } = e;
    Cookies.set('teamsTab', selected);
    switch (selected) {
      case 0:
        if (isPastDataFlag) {
          redirect(`/admin/past-data/shows/${showId}/show-info/view`);
        } else {
          redirect(`/admin/shows/view/${showId}/show-info`);
        }
        break;
      case 1:
        if (isPastDataFlag) {
          redirect(`/admin/past-data/shows/view/manage-rider/${showId}`);
        } else {
          redirect(`/admin/shows/view/${showId}/manage-rider`);
        }
        break;
      default:
        return '';
    }
  };
  const {
    getShowInfo,
    showInfoData: showViewData,
    showDataLoading: loading,
  } = useShowApi();

  useEffect(() => {
    if (location.pathname.includes('/shows/view') && showId) {
      getShowInfo(showId ?? '');
    }
  }, [location, showId]);

  useEffect(() => {
    setShowData(showViewData);
  }, [showViewData]);

  useEffect(() => {
    if (location.pathname.includes('show-info')) {
      setTabSelected(0);
    } else if (location.pathname.includes('manage-rider')) {
      setTabSelected(1);
    }
  }, [location.pathname]);
  return (
    <StyledCommonPageWrapper>
      <HeadingWrapper>
        <PageMainLabel>Shows Information</PageMainLabel>
        <CustomBreadCrumb preData={showsViewbreadCrumpitems} />
      </HeadingWrapper>
      <TabWrapper
        className={
          tabSelected === 0
            ? 'show-info'
            : tabSelected === 1
            ? 'manage-rider'
            : ''
        }
      >
        <AdminTab
          data={tabShowsNamesArray}
          tabSelected={tabSelected}
          setTabSelected={setTabSelected}
          onTabSelect={onTabSelectRedirect}
        />
        <BackButton className="back-button" onClick={handleBackClick} />
      </TabWrapper>
      {tabSelected === 0 && (
        <ShowViewProfile showData={showData} loading={loading} />
      )}
      {tabSelected === 1 && <ShowManageRider />}
    </StyledCommonPageWrapper>
  );
};
const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TabWrapper = styled.div`
  position: relative;
  &.rides-info {
    .hPRKpe {
      margin: 0 0 2px 0;
    }
  }

  .back-button {
    cursor: pointer;
    position: absolute;
    right: -12px;
    top: 7px;
    z-index: 2;
    span {
      font-weight: 500;
    }
  }

  .user-count-tab .k-item.k-last {
    color: unset !important;
  }
`;
const StyledCommonPageWrapper = styled(CommonPageWrapper)`
  padding: 20px 40px 0px 40px;
`;
