import { CommonPageWrapper, HeadingWrapper } from 'admin/components/wrappers';
import AdminCustomButton from 'app/components/AdminCustomButton';
import { AdminSearch } from 'app/components/AdminSearchField';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'hooks/useDebounce';
import ShowManageRiderTable from './ShowManageRiderTable';
import AdminShowRiderCard from 'app/components/AdminShowRiderCard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useShowApi from 'admin/hooks/useShowApi';
import AdminMultiSelectDropDown from 'admin/components/AdminMultiSelectDropdown';
import { ShowMangeRiderFilteredData } from 'admin/components/AdminMultiSelectDropdown/FilteredList';
import { getLookupArray } from 'utils/common';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import { RiderSplitClass } from 'services/openapi';

type Props = {};
const ShowManageRider = ({}: Props) => {
  const { id: showId } = useParams();
  const {
    fetchManageGetShowRiderList,
    showRiderDataList,
    loading,
    deleteShowRider,
    updateClassSplitApi,
  } = useShowApi();
  const { getAllLookupData, lookUps } = useCommonLookUpDatas();
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const [active, setActive] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pageSkip, setPageSkip] = useState<number | undefined>(0);
  const debounceShowSearch = useDebounce(searchTerm);
  const [lookupData, setLookupData] = useState('');
  const [teamIds, setTeamIds] = useState('');
  const [preDataList, setPreDataList] =
    useState<{ name: string; id: string; type: string }[]>();
  const isTeam = preDataList?.find(item => item.type === 'TEAM');
  const teamIdsArray = teamIds ? teamIds.split(',') : [];
  const teamId = isTeam ? teamIdsArray : [];
  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };
  const handleSaveClick = (requestBody: Array<RiderSplitClass>) => {
    updateClassSplitApi(
      false,
      requestBody,
      showId,
      teamId,
      '',
      '',
      debounceShowSearch,
      '',
      0,
      pageSkip,
      10,
    );
  };
  useEffect(() => {
    if (debounceShowSearch.length > 0) {
      fetchManageGetShowRiderList(
        showId ?? '',
        teamId,
        '',
        '',
        debounceShowSearch,
        '',
        0,
        0,
        10,
      );
    } else if (debounceShowSearch.length === 0 && teamId.length === 0) {
      fetchManageGetShowRiderList(
        showId ?? '',
        teamId,
        '',
        '',
        debounceShowSearch,
        '',
        0,
        0,
        10,
      );
    } else {
      fetchManageGetShowRiderList(
        showId ?? '',
        teamId,
        '',
        '',
        debounceShowSearch,
        '',
        0,
        pageSkip,
        10,
      );
    }

    if (pageSkip !== 0) {
      if (debounceShowSearch?.length >= 0 || teamId.length === 0) {
        setPageSkip(0);
      }
    }
  }, [debounceShowSearch, teamIds]);
  useEffect(() => {
    getAllLookupData(`TEAM`);
  }, []);
  return (
    <>
      <StyledCommonPageWrapper>
        <HeadingWrapper>
          <ButtonWrapper>
            {!isPastDataFlag && (
              <AdminCustomButton
                buttonType="primary"
                isActive={false}
                className="fill-button"
                onClick={() => {
                  setActive(!active);
                  navigate(`/admin/shows/view/${showId}/ManageRider/create`);
                }}
              >
                Add Rider
              </AdminCustomButton>
            )}
            <AdminMultiSelectDropDown
              data={ShowMangeRiderFilteredData}
              setLookupData={setLookupData}
              filteredData={getLookupArray(lookupData, lookUps)}
              lookUps={lookUps}
              setTeamIds={setTeamIds}
              lookupData={lookupData}
              setPreDataList={setPreDataList}
              preDataList={preDataList}
            />
          </ButtonWrapper>
          <SearchButtonWrapper>
            {!isPastDataFlag && (
              <AdminCustomButton
                buttonType="danger"
                isActive={false}
                onClick={() => {
                  navigate(`/admin/shows/generate-split/${showId}`);
                }}
              >
                Generate Split
              </AdminCustomButton>
            )}
            <div style={{ width: '283px' }}>
              <AdminSearch
                placeholder="Search By Team or Rider"
                onSearchTextEntering={handleSearch}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            </div>
          </SearchButtonWrapper>
        </HeadingWrapper>
        <CardWrapper>
          <AdminShowRiderCard
            title="Elementary Pearl"
            totalTryitTeams={
              showRiderDataList?.showRiderSummary?.totalTryitPoint
            }
            totalScratch={showRiderDataList?.showRiderSummary?.totalScratch}
            totalRiders={showRiderDataList?.showRiderSummary?.totalRiders}
            totalRides={showRiderDataList?.showRiderSummary?.totalRides}
            totalTryitRides={
              showRiderDataList?.showRiderSummary?.totalTryitRiders
            }
          />
        </CardWrapper>
        {/* Show Manage Riders Table */}
        {loading ? (
          <TableSkeletonLoader rowContent={8} width={100} headingWidth={50} />
        ) : (
          <ShowManageRiderTable
            setPageSkip={setPageSkip}
            pageSkip={pageSkip}
            debounceShowSearch={debounceShowSearch}
            fetchManageGetShowRiderList={fetchManageGetShowRiderList}
            showRiderDataList={showRiderDataList}
            deleteShowRider={deleteShowRider}
            teamId={teamId}
            onSaveClick={handleSaveClick}
          />
        )}
      </StyledCommonPageWrapper>
    </>
  );
};

export default ShowManageRider;
const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const CardWrapper = styled.div`
  padding-top: 10px;
`;
const StyledCommonPageWrapper = styled(CommonPageWrapper)`
  height: calc(100vh - 135px) !important;
  padding: 0px 30px 0px 0px;
`;
const SearchButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
