import { useErrorHandlingSlice } from 'app/error/slice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useApi from 'services/mavapi/useApi';
import {
  ManageTeamService,
  RiderWrapper,
  TeamsWrapper,
  TeamRequest,
  TeamService,
} from 'services/openapi';
import { useNavigateToRoute } from 'utils/common';
import isApiError from 'utils/isApiError';

const useTeamApi = () => {
  const [loading, setLoading] = useState(false);
  const [, setError] = useState<unknown | null>();
  const dispatch = useDispatch();
  const { action } = useParams();
  const { actions } = useErrorHandlingSlice();
  const { error: apiError, handleRequest } = useApi();
  const [teamRiderData, setTeamRiderData] = useState<RiderWrapper>();
  const {
    manageTeamGetTeams,
    manageTeamGetRiders,
    manageTeamCreateOrEditTeam,
    manageTeamUploadTeamImage,
    manageTeamMakeTeamActiveOrInactive,
    manageTeamDeleteTeam,
    manageTeamUpdateRiderStatus,
    manageTeamDeleteRiderInfo,
  } = ManageTeamService;
  const [teamProfileData, setTeamProfileData] = useState<TeamsWrapper>();
  const [riderList, setRiderList] = useState(false);
  const [imageUploadBlobPayload, setImageUploadBlobPayload] =
    useState<Blob | null>(null);
  const redirect = useNavigateToRoute();
  const { teamGenerateTeamCode } = TeamService;
  const [teamCodeValue, setTeamCodeValue] = useState<string>();
  const fetchManageGetTeams = (
    searchInput?: string,
    status?: string,
    seasonId?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    let pageNumCall = pageNumber ? pageNumber / 10 + 1 : 1;
    handleRequest(
      manageTeamGetTeams(
        searchInput,
        status,
        seasonId,
        sortBy,
        sortOrder,
        pageNumCall,
        pageSize,
      ),
    )
      .then(res => {
        if (res) {
          setTeamProfileData(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const createTeamProfile = (requestBody?: TeamRequest, blob?: Blob) => {
    const formData = {
      file: blob,
    };
    setLoading(true);
    handleRequest(manageTeamCreateOrEditTeam(requestBody))
      .then(res => {
        if (typeof res === 'string') {
          if (blob) {
            teamImageUpload(res ?? '', formData, action);
          } else {
            redirect(`/admin/teams/${`view`}/profile-info/${res}`);
            if (action === 'add') {
              dispatch(
                actions.dispatchToast({
                  errorTxt: 'Team Created Successfully',
                  show: true,
                  errorType: 'success',
                }),
              );
            } else if (action === 'edit') {
              dispatch(
                actions.dispatchToast({
                  errorTxt: 'Team Updated Successfully',
                  show: true,
                  errorType: 'success',
                }),
              );
            }
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const teamImageUpload = (
    id: string,
    formData?: {
      file?: Blob;
    },
    action?: string,
  ) => {
    setLoading(true);
    handleRequest(manageTeamUploadTeamImage(id, formData))
      .then(res => {
        redirect(`/admin/teams/${`view`}/profile-info/${id}`);
        setImageUploadBlobPayload(null);
        if (action === 'add') {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Team Created Successfully',
              show: true,
              errorType: 'success',
            }),
          );
        } else if (action === 'edit') {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Team Edited Successfully',
              show: true,
              errorType: 'success',
            }),
          );
        }
        fetchManageGetTeams();
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const teamActiveOrInactive = (
    id: string,
    activeStatusId?: string,
    seasonId?: string,
    searchInput?: string,
    status?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
    item?: string,
  ) => {
    setLoading(true);
    handleRequest(
      manageTeamMakeTeamActiveOrInactive(id, activeStatusId, seasonId),
    )
      .then(res => {
        if (res) {
          fetchManageGetTeams(
            searchInput,
            status,
            seasonId,
            sortBy,
            sortOrder,
            pageNumber,
            pageSize,
          );
          if (item === 'Make active') {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Successfully Active',
                show: true,
                errorType: 'success',
              }),
            );
          } else {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Successfully Inactive',
                show: true,
                errorType: 'success',
              }),
            );
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const deleteTeam = (
    id?: string,
    searchInput?: string,
    status?: string,
    seasonId?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    if (!id) {
      setLoading(false);
      return;
    }
    setLoading(true);
    handleRequest(manageTeamDeleteTeam(id))
      .then(() => {
        setLoading(false);
        fetchManageGetTeams(
          searchInput,
          status,
          seasonId,
          sortBy,
          sortOrder,
          pageNumber,
          pageSize,
        );
      })
      .catch(apiError => {
        setError(apiError);
        setLoading(false);
      });
  };
  const fetchTeamRiderList = (
    id: string,
    searchInput?: string,
    season?: string,
    status?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
  ) => {
    setLoading(true);
    let pageNumCall = pageNumber ? pageNumber / 10 + 1 : 1;
    handleRequest(
      manageTeamGetRiders(
        id,
        searchInput,
        season,
        status,
        sortBy,
        sortOrder,
        pageNumCall,
        pageSize,
      ),
    )
      .then(res => {
        if (res) {
          setTeamRiderData(res);
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };

  const teamRiderActiveOrInactive = (
    id: string,
    teamId?: string,
    statusId?: string,
    seasonId?: string,
    searchInput?: string,
    status?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber: number = 0,
    pageSize: number = 10,
    item?: string,
  ) => {
    setLoading(true);
    handleRequest(manageTeamUpdateRiderStatus(id, statusId))
      .then(res => {
        if (res) {
          fetchTeamRiderList(
            teamId ?? '',
            searchInput,
            '',
            status,
            sortBy,
            sortOrder,
            pageNumber,
            pageSize,
          );
          if (item === 'Make active') {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Successfully Active',
                show: true,
                errorType: 'success',
              }),
            );
          } else {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Successfully Inactive',
                show: true,
                errorType: 'success',
              }),
            );
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setError(apiError);
        setLoading(false);
      });
  };
  const deleteTeamRiderList = (id?: string) => {
    setLoading(true);
    handleRequest(manageTeamDeleteRiderInfo(id ?? ''))
      .then(res => {
        if (res) {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Rider deleted successfully',
              show: true,
              errorType: 'success',
            }),
          );
          setRiderList(!riderList);
        }
        setLoading(false);
      })
      .catch(apiError => {
        setError(apiError);
        setLoading(false);
      });
  };
  const generateTeamCode = (teamName?: string) => {
    handleRequest(teamGenerateTeamCode(teamName))
      .then(res => {
        if (res && !isApiError(res)) {
          setTeamCodeValue(res);
        } else {
          setTeamCodeValue(res.code);
        }
      })
      .catch(() => setError(apiError));
  };
  return {
    fetchManageGetTeams,
    teamProfileData,
    loading,
    fetchTeamRiderList,
    teamRiderData,
    createTeamProfile,
    teamImageUpload,
    deleteTeam,
    teamActiveOrInactive,
    teamRiderActiveOrInactive,
    deleteTeamRiderList,
    riderList,
    setImageUploadBlobPayload,
    imageUploadBlobPayload,
    setTeamProfileData,
    teamCodeValue,
    generateTeamCode,
  };
};

export default useTeamApi;
