import React, { useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { FormSecondaryLabel, InfoLabel } from 'app/components/SHLabel';
import { BackNextButton } from 'app/components/BackNextButton';
import { CheckBox } from 'app/components/CheckBox';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import { AlumniNoticeInfo } from 'app/components/AlumniInformation/AlumniNoticeInfo';
import { Alumni, GeneralLookup } from 'services/openapi';
import { RadioValueModel } from '../../../AlumniNewMemberResgistration';
import CustomDragAndDropFiles from 'app/components/CustomDragAndDropFiles';
import { useDispatch } from 'react-redux';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';

type Props = {
  formNumber?: number;
  setFormNumber?: React.Dispatch<React.SetStateAction<number>>;
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  affiliations: GeneralLookup[];
  setAffiliations: React.Dispatch<React.SetStateAction<GeneralLookup[]>>;
  aluminiRiderData?: Alumni;
  setAluminiRiderData: React.Dispatch<React.SetStateAction<Alumni>>;
  radioGroupValues: RadioValueModel;
  setRadioGroupValues: React.Dispatch<React.SetStateAction<RadioValueModel>>;
  setPhotoUploadPayload: React.Dispatch<
    React.SetStateAction<{
      formData?:
        | {
            file?: Blob | undefined;
          }
        | undefined;
    }>
  >;
  photoUploadPayload: {
    formData?:
      | {
          file?: Blob | undefined;
        }
      | undefined;
  };
  setFileAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  fileAvailability: boolean;
  setUploadFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  uploadFile: File | null | undefined;
};

export const PersonalAffiliationsForm = ({
  formNumber,
  setFormNumber,
  handleNextForm,
  handleBackForm,
  affiliations,
  setAffiliations,
  aluminiRiderData,
  setAluminiRiderData,
  radioGroupValues,
  setRadioGroupValues,
  setPhotoUploadPayload,
  photoUploadPayload,
  setFileAvailability,
  fileAvailability,
  setUploadFile,
  uploadFile,
}: Props) => {
  const dispatch = useDispatch();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const handleCheckboxOptionChange = (id: string, name: string) => {
    setAluminiRiderData(prevState => {
      const existingItemIndex = prevState?.professionalAffiliations?.findIndex(
        item => item.id === id,
      );
      if (existingItemIndex! > -1) {
        // Item already exists, update its name
        const updatedCategory = [...prevState?.professionalAffiliations!];
        updatedCategory.splice(existingItemIndex!, 1);
        return { ...prevState, professionalAffiliations: updatedCategory };
      } else {
        return {
          ...prevState,
          professionalAffiliations: [
            ...prevState?.professionalAffiliations!,
            { id, name },
          ],
        };
      }
    });
  };
  const handleAcceptingRadioBtnChange = e => {
    setAluminiRiderData({
      ...aluminiRiderData!,
      usePhotoForPromotion: e?.value,
    });
    setRadioGroupValues({
      ...radioGroupValues,
      usePhotoForPromotion: e?.value,
    });
  };
  const handleFileUpload = e => {
    var file = e.target.files[0];
    dispatch(manageAppActions.setPhotoUpload(file));
    console.log(file, 'file');

    setUploadFile(file);
    setPhotoUploadPayload({
      ...photoUploadPayload,
      formData: { file: file },
    });
  };
  const handleRemoveFile = e => {
    setUploadFile(undefined);
    setPhotoUploadPayload({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  const handleFileOnCancelClick = e => {
    setUploadFile(undefined);
    setPhotoUploadPayload({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  React.useEffect(() => {
    if (uploadFile === undefined) {
      setFileAvailability(true);
    } else {
      setFileAvailability(false);
    }
  }, [uploadFile]);
  return (
    <MainWrapper className="form-MainWrapper" id="PersonalInfoFormMainWrapper">
      <SubMainWrapper className="SubMainWrapper">
        <BorderWrapper className="inner-BorderWrapper">
          <InfoLabelWrapper id="InfoLabelWrapper">
            <div>
              <InfoLabel>Please Mark all professional affiliations</InfoLabel>
            </div>
          </InfoLabelWrapper>
          <CheckBoxGrid className="CheckBoxGrid">
            {affiliations.map(e => (
              <CheckBoxWrapper className="CheckBoxWrapper">
                <CheckBox
                  className="styledCheckBox"
                  label={e.name!}
                  value={e.id}
                  onChange={() => handleCheckboxOptionChange(e.id!, e.name!)}
                  checked={aluminiRiderData?.professionalAffiliations?.some(
                    value => value.id === e.id,
                  )}
                />
              </CheckBoxWrapper>
            ))}
          </CheckBoxGrid>
          <MainAddressWrapper className="MainAddressWrapper">
            <div className="horizontal-line">
              <span>Photo Release</span>
            </div>
            <QuestionWrapper className="QuestionWrapper">
              <FormSecondaryLabel>
                May your likeness in photos taken in photos by our official
                photographers be used in promotional literature for YEDA.
              </FormSecondaryLabel>
              <RadioWrapper>
                <RadioButtonGroup
                  onChange={handleAcceptingRadioBtnChange}
                  value={radioGroupValues?.usePhotoForPromotion}
                />
                <NoteFieldWrapper className="text-left">
                  <span className="note-block">
                    <span className="note-head"></span> (If no, please enclose a
                    current photo of the rider so that we can ensure their
                    likeness is not used.)
                  </span>
                </NoteFieldWrapper>
              </RadioWrapper>
              {radioGroupValues?.usePhotoForPromotion === false && (
                <PhotoUploadWrapper>
                  <h3 className="photo-title">
                    {' '}
                    Upload your current photo below.{' '}
                  </h3>
                  <CustomDragAndDropFiles
                    className="dragNdrop"
                    onCancel={e => handleFileOnCancelClick(e)}
                    onChange={e => handleFileUpload(e)}
                    uploadBtnDisabled={!fileAvailability}
                    onRemoveFile={handleRemoveFile}
                    chooseFileBtnDisabled={!fileAvailability}
                    uploadFile={uploadFile}
                  />
                </PhotoUploadWrapper>
              )}
            </QuestionWrapper>
            <AlumniNoticeInfo />
          </MainAddressWrapper>
        </BorderWrapper>
      </SubMainWrapper>
      <BottomRightWrapper id="BottomRightWrapper">
        <ButtonWrapper>
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleNextForm}
            onClickBack={handleBackForm}
            disableNext={
              (radioGroupValues?.usePhotoForPromotion !== undefined
                ? false
                : true) ||
              (radioGroupValues?.usePhotoForPromotion === false &&
              photoUploadPayload.formData?.file == undefined
                ? true
                : false)
            }
            next="Submit and Pay"
            back="Back"
          />
        </ButtonWrapper>
      </BottomRightWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 242px);
  /* overflow: auto; */
  padding-top: 50px;
  position: relative;
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 40px;
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    padding-top: 45px;
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    padding-top: 50px;
    .first-para,
    label {
      font-size: 14px;
    }
  }
`;
const CheckBoxWrapper = styled.div`
  display: inline-flex;
  align-items: flex-start;
  .sub-label {
    margin-left: 10px;
  }
`;
const NoteFieldWrapper = styled.div`
  margin: 0px 20px;
  display: flex;
  align-items: center;
  &.text-left {
    margin: 0px 0px;
  }
  text-align: left;
  .note-block {
    display: inline-grid;
    font-size: 13px;
    color: #4f4f4f;
    .note-head {
    }
  }
  &.flex-NoteWrapper {
    .note-block {
      display: inline-grid;
      font-size: 12px;
      padding-top: 30px;
      .note-head {
      }
    }
  }
`;
const SubMainWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
const BorderWrapper = styled.div`
  height: 100%;
  overflow: auto;
  /* border: 1px solid #585858; */
  border-radius: 20px;
  padding: 20px 60px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    padding: 10px 5px 0px 5px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 10px 10px 0px;
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 0 20px 10px 20px;
  text-align: start;
  width: 100%;
  div {
    border-bottom: 1px solid #434343;
    padding: 6px 0;
  }
`;
const CheckBoxGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 0 20px;

  /* gap: 15px; */
  @media (min-width: 100px) and (max-width: 319px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 320px) and (max-width: 575px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
    /* gap: 5px; */
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    grid-template-columns: repeat(6, 1fr);
    padding: 0 20px 60px;
  }
`;
const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const QuestionWrapper = styled.div`
  width: 100%;
  padding: 30px 20px 30px 20px;
  /* border-bottom: 1px solid #434343; */
  display: flex;
  flex-direction: column;
  ${FormSecondaryLabel} {
    margin: 0 0 15px 0;
  }
`;
const PhotoUploadWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .photo-title {
    color: #a7a7a7;
    font-family: 'Roboto';
    font-size: 15px;
    text-align: center;
  }
`;
const MainAddressWrapper = styled.div`
  padding-bottom: 100px;
  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 20px 15px 20px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: -17px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
