/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GeneralLookupWrapper } from '../models/GeneralLookupWrapper';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LookupService {

    /**
     * Lists the lookup data based on the requested names
     * @param names name of the lookup
 * Comma Separated values for each lookup to be returned
 * Avialable lookups<br /><ul><li>TEAM_CATEGORY</li><li>STATE</li><li>RIDERCLASS</li><li>CATEGORY</li><li>DIVISION</li><li>TEAM</li><li>PROFESSIONALAFFILIATIONS</li><li>ADAPTIVEEQUIPMENTTYPES</li><li>ASSOCIATIONS</li><li>SIFTYPES</li><li>MEDICALCONDITIONS</li><li>PAYER_TYPES</li><li>COACH</li><li>ASSISTANT_COACH</li><li>SEASON</li><li>DIVISION_CLASS</li><li>GRADE</li><li>ASSOCIATE_ROLES</li><li>DOCUMENT_TYPES</li><li>ACTIVESTATUS</li><li>COACHTYPES</li><li>NATIONALINVITE</li><li>ACTIVETEAM</li><li>ACTIVECOACH</li><li>RIDERBACKNUMBERS</li></ul>
     * @returns GeneralLookupWrapper Success
     * @throws ApiError
     */
    public static lookupGetAllLookups(
names?: string,
): CancelablePromise<Array<GeneralLookupWrapper>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lookup',
            query: {
                'names': names,
            },
        });
    }

    /**
     * show list based on the season Id
     * @param seasonId 
     * @returns GeneralLookupWrapper Success
     * @throws ApiError
     */
    public static lookupGetShowsLookupData(
seasonId: string,
): CancelablePromise<Array<GeneralLookupWrapper>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lookup/{seasonId}/shows',
            path: {
                'seasonId': seasonId,
            },
        });
    }

    /**
     * team list based on the season Id
     * @param seasonId 
     * @returns GeneralLookupWrapper Success
     * @throws ApiError
     */
    public static lookupGetTeamsLookupData(
seasonId: string,
): CancelablePromise<Array<GeneralLookupWrapper>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lookup/{seasonId}/teams',
            path: {
                'seasonId': seasonId,
            },
        });
    }

    /**
     * category list based on the season Id
     * @param seasonId 
     * @returns GeneralLookupWrapper Success
     * @throws ApiError
     */
    public static lookupGetCategoryLookupData(
seasonId: string,
): CancelablePromise<Array<GeneralLookupWrapper>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/lookup/{seasonId}/category',
            path: {
                'seasonId': seasonId,
            },
        });
    }

}
