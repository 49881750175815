import AdminTab from 'app/components/AdminTab';
import { PageMainLabel } from 'app/components/SHLabel';
import styled from 'styled-components';
import BackButton from 'app/components/BackButton';
import { CommonPageWrapper } from 'admin/components/wrappers';
import CustomBreadCrumb from 'app/components/CustomBreadCrumb';
import { useEffect, useState } from 'react';
import RidesInformation from './components/Rides';
import { RiderViewProfile } from './components/RiderViewProfile';
import { useLocation, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useNavigateToRoute } from 'utils/common';
import { breadCrumpitems } from './components/RiderCreatePage/staticDatas';

export const tabRiderNamesArray = [
  { tab: 'Rider Profile', id: 1 },
  { tab: 'Rides', id: 2 },
  { tab: 'History', id: 3 },
];
export const RiderIndividual = () => {
  const { id, tab } = useParams();
  let location = useLocation();
  const redirect = useNavigateToRoute();
  const [tabSelected, setTabSelected] = useState<number>(0);
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const baseUrl = isPastDataFlag ? '/admin/past-data/riders' : '/admin/riders';
  const handleBackClick = () => {
    redirect(`/admin/riders`);
  };
  const onTabSelectRedirect = e => {
    const { selected } = e;
    Cookies.set('teamsTab', selected);
    switch (selected) {
      case 0:
        redirect(`${baseUrl}/view/profile-info/${id}`);
        break;
      case 1:
        redirect(`${baseUrl}/view/rides/${id}`);
        break;
      case 2:
        redirect(`${baseUrl}/view/history/${id}`);
        break;
      default:
        return '';
    }
  };

  //swicth tab active on browser back
  const switchTabsOnBrowserBack = (tab: string) => {
    if (tab) {
      switch (tab) {
        case 'profile-info':
          Cookies.set('teamsTab', 0);
          setTabSelected(0);
          break;
        case 'rides':
          Cookies.set('teamsTab', 1);
          setTabSelected(1);
          break;
        case 'history':
          Cookies.set('teamsTab', 2);
          setTabSelected(2);
          break;
        default:
          return;
      }
    }
  };

  useEffect(() => {
    switchTabsOnBrowserBack(tab ?? '');
  }, [location.pathname]);

  return (
    <CommonPageWrapper>
      <HeadingWrapper>
        <PageMainLabel>Rider Information</PageMainLabel>
        <CustomBreadCrumb preData={breadCrumpitems} />
      </HeadingWrapper>
      <TabWrapper className={tabSelected === 1 ? 'rides-info' : ''}>
        <AdminTab
          data={tabRiderNamesArray}
          tabSelected={tabSelected}
          setTabSelected={setTabSelected}
          onTabSelect={onTabSelectRedirect}
        />
        <BackButton className="back-button" onClick={handleBackClick} />
      </TabWrapper>
      {tab === 'profile-info' ? (
        <RiderViewProfile />
      ) : tab === 'rides' ? (
        <RidesInformation />
      ) : (
        ''
      )}
    </CommonPageWrapper>
  );
};
const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TabWrapper = styled.div`
  position: relative;
  &.rides-info {
    .hPRKpe {
      margin: 0 0 2px 0;
    }
  }

  .back-button {
    cursor: pointer;
    position: absolute;
    right: -12px;
    top: 7px;
    z-index: 2;
    span {
      font-weight: 600;
    }
  }

  .user-count-tab .k-item.k-last {
    color: unset !important;
  }
`;
