import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import React from 'react';
import { useState } from 'react';
import { BackNextButton } from 'app/components/BackNextButton';
import { useNavigate } from 'react-router-dom';
import SHDatePicker from 'app/components/SHDatePicker';
import SHdropdown from 'app/components/SHdropdown';
import { GeneralLookupWrapper, RegisterRider } from 'services/openapi';
import SHYear from 'app/components/SHYear';
import { useInternationalization } from '@progress/kendo-react-intl';
import SimpleReactValidator from 'simple-react-validator';
import Cookies from 'js-cookie';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import SHBackNumberDropdown from 'app/components/SHBackNumberDropdown';
import { getLookupArray } from 'utils/common';

type Props = {
  onClickNxtPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  riderData: RegisterRider | undefined;
  setRiderData: React.Dispatch<React.SetStateAction<RegisterRider>> | undefined;
  setHeightWeight: React.Dispatch<
    React.SetStateAction<heightWeightModel | undefined>
  >;
  heightWeight: heightWeightModel | undefined;
  backNumberData: string[] | undefined;
  lookUps: GeneralLookupWrapper[];
};

const PersonalInfoFields = ({
  onClickNxtPersonal,
  riderData,
  setRiderData,
  setHeightWeight,
  heightWeight,
  backNumberData,
  lookUps,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const intl = useInternationalization();
  const [valid, setValid] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/rider-registration-page');
    Cookies.remove('riderId');
    Cookies.remove('payloadPlacement');
    Cookies.remove('payload');
    Cookies.remove('response');
  };

  const expectedGraduationChange = e => {
    if (riderData) {
      setRiderData?.({
        ...riderData,
        expectedGraduationYear: e,
      });
    }
  };
  const handleDate = e => {
    let date = intl.formatDate(e.value, "yyyy-MM-dd'T'HH:mm:ss");
    if (riderData) {
      setRiderData!({
        ...riderData,
        dateOfBirth: date,
      });
    }
  };

  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [riderData]);
  console.log(riderData, 'riderData');
  const handleFeetChange = event => {
    const newFeet = parseInt(event.target.value);
    if (event.target.value && newFeet >= 0 && newFeet <= 9) {
      setHeightWeight(prevData => ({
        ...prevData!,
        feet: Math.floor(newFeet),
      }));
      if (riderData) {
        setRiderData?.({ ...riderData, height: newFeet });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({ ...prevData!, feet: undefined }));
    }
  };

  const handleInchesChange = event => {
    const newInches = parseInt(event.target.value);
    if (event.target.value && newInches >= 0 && newInches <= 11) {
      setHeightWeight(prevData => ({
        ...prevData!,
        inches: Math.floor(newInches),
      }));
      const newHeightInch = heightWeight?.feet! + newInches / 12;
      if (riderData) {
        setRiderData?.({ ...riderData, height: newHeightInch });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({
        ...prevData!,
        inches: undefined,
      }));
    }
  };
  const handleWeightChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 3 && /^[0-9]*$/.test(inputValue)) {
      if (riderData) {
        setRiderData?.({
          ...riderData,
          weight: inputValue,
        });
      }
      setHeightWeight(prevData => ({
        ...prevData!,
        weight: inputValue,
      }));
    }
  };
  const handleBackNumberChange = name => {
    if (riderData) {
      setRiderData?.({
        ...riderData,
        backNumber: name.value,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (riderData) {
      if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
        setRiderData?.({
          ...riderData,
          primaryPhone: inputValue,
        });
      }
    }
  };

  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (riderData) {
      if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
        setRiderData?.({
          ...riderData,
          zip: inputValue,
        });
      }
    }
  };
  return (
    <MainWrapper className="form-MainWrapper">
      <OverflowWrapper className="OverflowWrapper">
        <TextFieldGrid className="TextFieldGrid">
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="First Name"
              type="text"
              name="firstName"
              onChange={e => {
                if (riderData) {
                  setRiderData!({ ...riderData, firstName: e.target.value });
                }
              }}
              value={riderData?.firstName}
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Last Name"
              type="text"
              name="lastName"
              onChange={e => {
                if (riderData) {
                  setRiderData!({ ...riderData, lastName: e.target.value });
                }
              }}
              value={riderData?.lastName}
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>{' '}
          <TextFieldWrapper className="TextFieldWrapper">
            <BackFieldWrapper className="BackFieldWrapper">
              <SHBackNumberDropdown
                onChange={e => handleBackNumberChange(e.value)}
                data={backNumberData}
                defaultValue={riderData?.backNumber}
                label="Back Number (3 or 4 digits)"
                validations={'required|max:30'}
                validator={simpleValidator.current}
                isAbove={true}
              />
            </BackFieldWrapper>
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="StyledTextField current-grade"
              label="Current Grade"
              onDataChange={e => {
                if (riderData) {
                  setRiderData!({ ...riderData, currentGrade: e });
                }
              }}
              data={getLookupArray('GRADE', lookUps)}
              defaultValue={riderData?.currentGrade}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <SubWrapper className="SubWrapper">
            <GraduationDobWrapper className="GraduationDobWrapper">
              <GraduationSubWrapper className="GraduationSubWrapper">
                <SHYear
                  className="drop-down"
                  label="Expected Graduation Year"
                  defaultValue={
                    riderData?.expectedGraduationYear === 0
                      ? undefined
                      : riderData?.expectedGraduationYear
                  }
                  value={
                    riderData?.expectedGraduationYear === 0
                      ? undefined
                      : riderData?.expectedGraduationYear
                  }
                  onChange={expectedGraduationChange}
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </GraduationSubWrapper>
              <GraduationSubWrapper className="GraduationSubWrapper">
                <StyledSHDatePicker
                  onChange={handleDate}
                  placeholder=""
                  value={
                    riderData?.dateOfBirth
                      ? new Date(String(riderData?.dateOfBirth!))
                      : undefined
                  }
                  name="dob"
                  className="StyledSHDatePicker datepicker-style"
                  dateLabel="Date of Birth"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </GraduationSubWrapper>
            </GraduationDobWrapper>
            <HeightInchWeightWrapper className="HeightInchWeightWrapper">
              <StyledHeightTextField
                onChange={handleFeetChange}
                value={heightWeight?.feet}
                label="Height(Ft)"
                type="number"
                name="height"
                placeholder="Feet"
                validations={'required'}
                validator={simpleValidator.current}
              />
              <StyledInchTextField
                onChange={handleInchesChange}
                value={heightWeight?.inches}
                type="number"
                name="inch"
                label="Inches"
                placeholder="Inches"
              />
              <NoteFieldWrapper className="flex-NoteWrapper">
                <span className="note-block">
                  <span className="note-head"></span> eg. 5ft 10in
                </span>
              </NoteFieldWrapper>
              <StyledWeightTextField
                label="Weight"
                type="text"
                name="weight"
                placeholder="lbs"
                onChange={handleWeightChange}
                value={heightWeight?.weight}
                validations={'required'}
                validator={simpleValidator.current}
              />
            </HeightInchWeightWrapper>
          </SubWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Cell/Primary Ph#"
              type="tel"
              name="ph"
              onChange={handlePhChange}
              value={riderData?.primaryPhone}
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Email"
              type="email"
              name="email"
              onChange={e => {
                if (riderData) {
                  setRiderData!({ ...riderData, email: e.target.value });
                }
              }}
              value={riderData?.email}
              validations={'required|email'}
              validator={simpleValidator.current}
              maxLength={60}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <HomeAddressWrapper className="horizontal-line">
          <p className="home-address">Home mailing address</p>
        </HomeAddressWrapper>
        <TextFieldGrid>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Street Address"
              type="text"
              name="streetAddress"
              onChange={e => {
                if (riderData) {
                  setRiderData!({ ...riderData, street: e.target.value });
                }
              }}
              value={riderData?.street}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="City"
              type="text"
              name="city"
              onChange={e => {
                if (riderData) {
                  setRiderData!({ ...riderData, city: e.target.value });
                }
              }}
              value={riderData?.city}
              validations={'required|max:40'}
              validator={simpleValidator.current}
              maxLength={40}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="State"
              data={getLookupArray('STATE', lookUps)}
              onDataChange={data => {
                if (riderData) {
                  setRiderData!({
                    ...riderData,
                    state: data,
                  });
                }
              }}
              defaultValue={riderData?.state}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Zip"
              type="text"
              name="zip"
              onChange={handleZipChange}
              value={riderData?.zip}
              validations={'required|alpha_num|min:5|max:9'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <ButtonWrapper className="bg-color-change">
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={onClickNxtPersonal}
            onClickBack={handleBack}
            next="Next"
            back="Back"
            disableNext={valid}
          />
        </ButtonWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 201px);
  padding: 20px 0px 0px;
  position: relative;
  // overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const SubWrapper = styled.div`
  display: flex;
  align-items: end;
  @media (min-width: 992px) and (max-width: 1199px) {
    display: block;
  }
  @media (min-width: 100px) and (max-width: 991px) {
    display: block;
    width: 100%;
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
  padding: 20px 20px 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const HomeAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  padding: 0 12px;

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
    color: #4f4f4f;
  }
`;
const TextFieldWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
`;
const StyledTextField = styled(TextField)`
  &.styled-field {
    margin-bottom: 0px !important;
  }
  input {
    width: 100% !important;
    border: 1px solid #434343;
    &.back-number {
      &.green-border {
        border: 1px solid green;
      }
      &.red-border {
        border: 1px solid red;
      }
    }
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const BackFieldWrapper = styled.div`
  width: 80%;
  margin-bottom: 20px;
  .BackNumber {
    width: 65% !important;
    input {
      width: 100% !important;
    }
  }

  @media (min-width: 100px) and (max-width: 575px) {
    .SHdropdown {
      margin: 0px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .SHdropdown {
      margin: 0 8px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .SHdropdown {
      margin: 0 8px !important;
    }
  }
`;
const StyledBackTextField = styled(TextField)`
  &.styled-field {
    margin-bottom: 0px !important;
  }
  input {
    width: 60%;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const GraduationDobWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 10px 0px;
  padding: 0 12px 0 12px;
  display: flex;
  align-items: end;
  /* gap: 10px;
  flex: 0 0 calc(50% - 20px); */
  .k-input:not(:-webkit-autofill) {
    animation-name: unset !important;
  }
  .k-input-solid {
    width: 100% !important;
    height: 35px;
  }
  &.datepicker-style {
    display: flex;
    align-items: end;
  }
  .date {
    display: flex;
    flex-direction: column;
    p {
      margin: 0px;
    }
  }
`;
// const StyledGraduationDropdownWrapper = styled.div`
//   padding-top: 2px;
//   display: flex;
//   align-items: end;
//   width: 50%;

//   button {
//     width: 155px !important;
//   }
//   .drop-down {
//     margin: 0px;
//     width: 100%;
//   }
//   .drop-down > div {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     text-wrap: nowrap;
//     font-size: 14px;
//     width: 100%;
//   }
//   svg {
//     width: 35px !important;
//     height: auto;
//     position: absolute;
//     top: 20px;
//     right: -25px;
//   }
// `;
const GraduationSubWrapper = styled.div`
  /* padding-top: 2px;
  display: flex;
  align-items: end; */
  width: 50%;
  .SHYear {
    margin-left: 0px !important;
  }
  /* button {
    width: 155px !important;
  }
  .drop-down {
    margin: 0px;
    width: 100%;
  }
  .drop-down > div {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    font-size: 14px;
    width: 100%;
  }
  .k-button .k-button-icon {
    margin: 5px 0px 0 55px;
  } */
`;
const StyledSHDatePicker = styled(SHDatePicker)`
  display: flex;
  align-items: end;
  width: 50%;

  input {
    width: 100%;
    height: 35px;
  }
  span {
    border-color: #434343;
  }
`;
const HeightInchWeightWrapper = styled.div`
  display: flex;
  gap: 5px;
  width: 50%;
  padding: 0 12px;
  span {
    font-size: 10px;
    display: flex;
    align-items: center;
  }
`;
const StyledWeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 33%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledHeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 33%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledInchTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 33%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const NoteFieldWrapper = styled.div`
  margin: 0px 10px;
  text-align: center;
  .note-block {
    display: inline-grid;
    font-size: 12px;
    white-space: nowrap;
    .note-head {
    }
  }
  &.flex-NoteWrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .note-block {
      display: flex;
      font-size: 12px;
      padding-top: 30px;
      .note-head {
      }
    }
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  z-index: 2;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -5px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
export default PersonalInfoFields;
