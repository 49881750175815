import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import '@fontsource/roboto';
import { InfoLabel } from 'app/components/SHLabel';
import SHdropdown from 'app/components/SHdropdown';
import { TextField } from 'app/components/TextField';
import { BackNextButton } from 'app/components/BackNextButton';
import BackNumberTableComponent from 'app/components/BackNumberListComponent';
import { Alumni, GeneralLookup } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import SHBackNumberDropdown from 'app/components/SHBackNumberDropdown';
import InfoIconButton from 'app/components/InfoIconButton';

type Props = {
  handleNextForm?: (event) => void;
  handleBackForm?: (event) => void;
  alumniData: Alumni;
  setAlumniData: React.Dispatch<React.SetStateAction<Alumni>>;
  teamData: GeneralLookup[];
  stateData: GeneralLookup[];
  setHeightWeight: React.Dispatch<React.SetStateAction<heightWeightModel>>;
  heightWeight: heightWeightModel;
  backNumberData: string[] | undefined;
};

export const PersonalInfoForm = ({
  handleNextForm,
  handleBackForm,
  setAlumniData,
  alumniData,
  teamData,
  stateData,
  setHeightWeight,
  heightWeight,
  backNumberData,
}: Props) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [valid, setValid] = useState<boolean>(false);
  const [categoryCheck, setCategoryCheck] = useState<
    GeneralLookup[] | undefined
  >();

  const handleOpenBackNumberPopUp = () => {
    setVisible(!visible);
  };

  const handleFeetChange = event => {
    const newFeet = parseInt(event.target.value);
    if (event.target.value && newFeet >= 0 && newFeet <= 9) {
      setHeightWeight(prevData => ({ ...prevData, feet: Math.floor(newFeet) }));
      if (alumniData) {
        setAlumniData({
          ...alumniData,
          height: newFeet,
        });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({ ...prevData, feet: undefined }));
    }
  };

  const handleInchesChange = event => {
    const newInches = parseInt(event.target.value);
    if (event.target.value && newInches >= 0 && newInches <= 11) {
      setHeightWeight(prevData => ({
        ...prevData,
        inches: Math.floor(newInches),
      }));
      const newHeightInch = heightWeight.feet! + newInches / 12;
      if (alumniData) {
        setAlumniData({
          ...alumniData,
          height: newHeightInch,
        });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({
        ...prevData,
        inches: undefined,
      }));
    }
  };
  const handleBackNumberChange = name => {
    if (alumniData) {
      setAlumniData?.({
        ...alumniData,
        backNumber: name.value,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setAlumniData({
        ...alumniData,
        primaryPhone: inputValue,
      });
    }
  };
  const handleEmergencyPhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setAlumniData({
        ...alumniData,
        emergencyContactPhone: inputValue,
      });
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setAlumniData({
        ...alumniData,
        zip: inputValue,
      });
    }
  };
  const handleWeightChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 3 && /^[0-9]*$/.test(inputValue)) {
      setAlumniData({
        ...alumniData,
        weight: inputValue,
      });
      setHeightWeight(prevData => ({
        ...prevData,
        weight: inputValue,
      }));
    }
  };
  useEffect(() => {}, []);
  const handleTeamChange = data => {
    setAlumniData({
      ...alumniData,
      team: data,
    });
    if (data) {
      const findAlumni = data?.children?.filter(item => {
        return item.name === 'Alumni';
      });
      setCategoryCheck(findAlumni);
    }
  };

  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [alumniData]);
  console.log(alumniData, 'alumniData');
  return (
    <MainWrapper className="form-MainWrapper" id="PersonalInfoFormMainWrapper">
      <SubMainWrapper className="SubMainWrapper">
        <BorderWrapper className="inner-BorderWrapper">
          <InfoLabelWrapper id="InfoLabelWrapper">
            <div>
              <InfoLabel>
                Payment of $20 fee and proof of insurance must be accompanied
                with this form
              </InfoLabel>
            </div>
          </InfoLabelWrapper>
          <TextFieldGrid className="TextFieldGrid">
            <TextFieldWrapper className="TextFieldWrapper ">
              <StyledTextField
                className="StyledTextField"
                onChange={e =>
                  setAlumniData({
                    ...alumniData,
                    firstName: e?.target?.value,
                  })
                }
                value={alumniData?.firstName}
                label="First Name"
                type="text"
                name="name"
                validations={'required|max:30'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper className="TextFieldWrapper ">
              <StyledTextField
                className="StyledTextField"
                onChange={e =>
                  setAlumniData({
                    ...alumniData,
                    lastName: e?.target?.value,
                  })
                }
                value={alumniData?.lastName}
                label="Last Name"
                type="text"
                name="name"
                validations={'required|max:30'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper className="TextFieldWrapper BackFieldMainWrapper">
              <BackFieldWrapper className="BackFieldWrapper">
                <SHBackNumberDropdown
                  onChange={e => handleBackNumberChange(e.value)}
                  data={backNumberData}
                  defaultValue={alumniData?.backNumber!}
                  label="Back Number (3 or 4 digits)"
                  validations={'required|max:30'}
                  validator={simpleValidator.current}
                  isAbove={true}
                  className="BackfieldNumber"
                />
                <InfoIconButton
                  onClick={handleOpenBackNumberPopUp}
                  className="info-icon"
                />
              </BackFieldWrapper>
            </TextFieldWrapper>
            <TextFieldWrapper className="TextFieldWrapper ">
              <SHdropdown
                onDataChange={handleTeamChange}
                defaultValue={alumniData?.team}
                data={teamData}
                label="Team Name"
                validations={'required'}
                validator={simpleValidator.current}
                categoryCheck={categoryCheck}
                className="drop-down"
              />
            </TextFieldWrapper>
          </TextFieldGrid>
          <MainAddressWrapper className="MainAddressWrapper">
            <div className="horizontal-line">
              <span>Coach Address</span>
            </div>
            <TextFieldGrid className="TextFieldGrid">
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={e =>
                    setAlumniData({
                      ...alumniData,
                      street: e?.target?.value,
                    })
                  }
                  value={alumniData?.street}
                  label=" Address"
                  type="text"
                  name="Address"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={e =>
                    setAlumniData({
                      ...alumniData,
                      city: e?.target?.value,
                    })
                  }
                  value={alumniData?.city}
                  label=" City"
                  type="text"
                  name=" City"
                  validations={'required|max:40'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <SHdropdown
                  onDataChange={data => {
                    setAlumniData({
                      ...alumniData,
                      state: data,
                    });
                  }}
                  defaultValue={alumniData?.state}
                  data={stateData}
                  label="State"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleZipChange}
                  value={alumniData?.zip}
                  label="Zip"
                  type="text"
                  name="zip"
                  validations={'required|alpha_num|min:5|max:9'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handlePhChange}
                  value={alumniData?.primaryPhone}
                  label="Phone Number:"
                  type="tel"
                  name="primaryPhone"
                  validations={'required|numeric|min:10|max:10'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={e =>
                    setAlumniData({
                      ...alumniData,
                      email: e?.target?.value,
                    })
                  }
                  value={alumniData?.email}
                  label="Email:"
                  type="email"
                  name="email"
                  validations={'required|email'}
                  validator={simpleValidator.current}
                  maxLength={60}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={e =>
                    setAlumniData({
                      ...alumniData,
                      emergencyContactName: e?.target?.value,
                    })
                  }
                  value={alumniData?.emergencyContactName}
                  label="Emergency Contact"
                  type="text"
                  name="emergencyContactName"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper ">
                <StyledTextField
                  className="StyledTextField"
                  onChange={handleEmergencyPhChange}
                  value={alumniData?.emergencyContactPhone}
                  label="PH:"
                  type="tel"
                  name="emergencyContactPhone"
                  validations={'required|numeric|min:10|max:10'}
                  validator={simpleValidator.current}
                />
              </TextFieldWrapper>
            </TextFieldGrid>
            <TextFieldGrid className="height-note-grid TextFieldGrid">
              <TextFieldWrapper className="TextFieldWrapper TextNoteWrapper">
                <NoteFieldWrapper className="NoteFieldWrapper">
                  <span className="note-block">
                    <span className="note-head">YEDA Back #:</span> Returning
                    Members wright your back number, new members leave blank a
                    number will be assigned to you. The $10 for the YEDA Back
                    Number go towards the YEDA Scholarship Incentive Fund.
                  </span>
                </NoteFieldWrapper>
              </TextFieldWrapper>
              <TextFieldWrapper className="TextFieldWrapper flex-TextFieldWrapper height-weight-wrapper">
                <FlexFieldWrapper className="FlexFieldWrapper">
                  <HeightFieldWrapper className="HeightFieldWrapper">
                    <StyledHeightTextField
                      onChange={handleFeetChange}
                      value={heightWeight.feet}
                      label="Height"
                      type="number"
                      name="height"
                      placeholder="Feet"
                      className="StyledHeightTextField  StyledHeightTextField-first"
                      validations={'required'}
                      validator={simpleValidator.current}
                    />
                    <StyledHeightTextField
                      onChange={handleInchesChange}
                      value={heightWeight.inches}
                      type="number"
                      name="inch"
                      label="Inches"
                      placeholder="inches"
                      className="StyledHeightTextField"
                    />
                    <NoteFieldWrapper className="NoteFieldWrapper flex-NoteWrapper">
                      <span className="note-block">
                        <span className="note-head"></span> eg. 5ft 10in
                      </span>
                    </NoteFieldWrapper>
                  </HeightFieldWrapper>
                  <StyledWeightTextField
                    className="StyledWeightTextField"
                    onChange={handleWeightChange}
                    value={heightWeight.weight}
                    label="Weight"
                    type="text"
                    name="weight"
                    placeholder="lbs"
                    validations={'required'}
                    validator={simpleValidator.current}
                  />
                </FlexFieldWrapper>
              </TextFieldWrapper>
            </TextFieldGrid>
          </MainAddressWrapper>
        </BorderWrapper>
        {visible && (
          <BackNumberTableComponent
            onClose={handleOpenBackNumberPopUp}
            backNumberData={backNumberData}
            isAbove={true}
          />
        )}
      </SubMainWrapper>
      <BottomRightWrapper id="BottomRightWrapper">
        <ButtonWrapper>
          <BackNextButton
            classNameBack="btn-back"
            classNameNxt="btn-nxt"
            onClickNxt={handleNextForm}
            onClickBack={handleBackForm}
            disableNext={valid || categoryCheck?.length === 0}
            next="Next"
            back="Back"
          />
        </ButtonWrapper>
      </BottomRightWrapper>
    </MainWrapper>
  );
};

const SubMainWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
const BorderWrapper = styled.div`
  height: 100%;
  overflow: auto;
  /* border: 1px solid #585858; */
  border-radius: 20px;
  padding: 20px 60px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: #201e1e !important;
    border-radius: 10px;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    padding: 10px 5px 0px 5px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    padding: 10px 10px 0px;
  }
`;
const InfoLabelWrapper = styled.div`
  padding: 0 20px 10px 20px;
  text-align: start;
  width: 100%;
  div {
    border-bottom: 1px solid #434343;
    padding: 6px 0;
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 35px 0 0px;

  /* gap: 15px; */
  @media (min-width: 992px) and (max-width: 1199px) {
    /* gap: 5px; */
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    /* gap: 10px; */
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    /* gap: 15px; */
  }
`;
const NoteFieldWrapper = styled.div`
  margin: 0px 20px;
  text-align: left;
  .note-block {
    display: inline-grid;
    font-size: 12px;
    font-style: italic;
    .note-head {
      font-style: normal;
    }
  }
  &.flex-NoteWrapper {
    .note-block {
      display: inline-grid;
      font-size: 12px;
      padding-top: 30px;
      .note-head {
      }
    }
  }
`;
const TextFieldWrapper = styled.div`
  /* flex: 0 0 50%;
  margin-bottom: 20px;*/
  width: 50%;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }
  &.BackNumberFieldWrapper {
    width: 100% !important;
    /* .BackFieldWrapper {
      width: 50% !important;
    } */
  }

  /* padding: 0px 20px; */
  /* @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  } */
`;
const FlexFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const HeightFieldWrapper = styled.div`
  display: flex;
`;

const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
  input {
    width: 100% !important;
    border: 1px solid #434343;
    &.back-number {
      &.green-border {
        border: 1px solid green;
      }
      &.red-border {
        border: 1px solid #c62828;
      }
    }
  }
`;
const StyledHeightTextField = styled(TextField)`
  /* margin: 0px 0px 0px 20px; */
  input {
    width: 60px;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledWeightTextField = styled(TextField)`
  /* margin: 0px 20px 0px 0px; */
  input {
    width: 100%;
    width: 60px;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const BackFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .BackfieldNumber {
    width: 80%;
  }
  .info-icon {
    margin: 24px 12px 0px 0px !important;
  }

  @media (min-width: 100px) and (max-width: 575px) {
    width: 100%;
    .info-icon {
      margin: 17px 0px 0px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    .info-icon {
      margin: 17px 12px 0px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 100%;
    .info-icon {
      margin: 20px 12px 0px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .info-icon {
      margin: 18px 12px 0px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .info-icon {
      margin: 21px 12px 0px !important;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .info-icon {
      margin: 23px 12px 0px !important;
    }
  }
`;

const MainAddressWrapper = styled.div`
  margin-bottom: 40px;
  width: 100%;

  .horizontal-line {
    display: flex;
    align-items: baseline;
    margin: 20px 20px 15px 20px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: -18px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    /* position: absolute;
    bottom: 40px;
    padding-top: 30px;
    background: linear-gradient(
      to bottom,
      rgb(48 48 48 / 79%) 0%,
      rgb(42 42 42) 100%
    ); */
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 40px;
    &.bg-color-change {
      bottom: 25px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 45px;
    &.bg-color-change {
      bottom: 30px;
      padding-top: 20px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 60px;
    &.bg-color-change {
      bottom: 20px;
      padding-top: 20px;
    }
  }
`;
const ButtonWrapper = styled.div``;
const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 245px);
  /* overflow: auto; */
  padding-top: 15px;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .first-para,
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .first-para,
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .first-para,
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .first-para,
    label {
      font-size: 14px;
    }
  }

  //Responsive

  @media (min-width: 576px) and (max-width: 767px) {
    ${InfoLabelWrapper} {
      label {
        font-size: 10px !important;
      }
    }
    .height-weight-wrapper {
      .note-block {
        font-size: 11px !important;
        padding-top: 22px !important;
      }
    }
    .note-block {
      font-size: 10px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ${StyledTextField} {
      margin: 0px 8px;
    }
    .note-block {
      font-size: 10px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${StyledTextField} {
      margin: 0px 12px;
    }
    ${StyledWeightTextField} {
      width: 50px;
      /* input {
        width: 50px;
      } */
    }
    .height-weight-wrapper {
      width: 100%;
    }

    .note-block {
      font-size: 10px;
    }
    .inner-BorderWrapper {
      padding: 20px 25px 0px 25px;
    }
  }
`;
