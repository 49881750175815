import { GridCellProps } from '@progress/kendo-react-grid';
import DropdownComponent from 'app/components/CustomFilterPopup';
import { DetailArrowIcon, ThreeDotIcon } from 'app/assets/icons';
import styled from 'styled-components';

type Props = {
  className?: string;
  e: GridCellProps;
  onMoreClick: (item: string, e) => void;
  onDataClick: (data: any) => void;
  onViewClick?: (e) => void;
  isPastDataFlag?: boolean;
};

export const MoreIconCell = ({
  className,
  e,
  onMoreClick,
  onDataClick,
  onViewClick,
  isPastDataFlag,
}: Props) => {
  const data = e?.dataItem;
  const dropDowndata = [
    data.status.id === 'bc1e49f9-0f4a-4222-ba22-c4f4af0b4f75'
      ? 'Make inactive'
      : 'Make active',
    // 'Edit rider info', hide for now....
    'Delete rider',
  ];
  return (
    <td className={className ?? ''}>
      <Wrapper>
        {!isPastDataFlag && (
          <DropdownComponent
            data={dropDowndata}
            onItemClick={item => {
              onMoreClick(item, e);
            }}
            onDataClick={() => onDataClick(data)}
            svgIcon={<ThreeDotIcon />}
            className="custom-dropdown"
          />
        )}
        <div onClick={() => onViewClick?.(data)}>
          <DetailArrowIcon />
        </div>
      </Wrapper>
    </td>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .custom-dropdown {
    right: 15px;
    top: 25px;
  }
  .k-button:hover::before {
    opacity: 0;
  }
  .filter-button button {
    border: none !important;
    width: 10px;
    padding: 5px;
    background-color: red !important;
    :hover {
      background: transparent !important;
      background-color: transparent !important;
      border: none !important;
    }
  }
`;
