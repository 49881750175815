import React, { useEffect, useState } from 'react';
import { PersonalInfoForm } from './components/PersonalInfoForm';
import styled from 'styled-components';
import { ProfessionalAffiliationsForm } from './components/ProfessionalAffiliationsForm';
import {
  CoachRegistration,
  CoachService,
  GeneralLookup,
  LookupService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import { useNavigate } from 'react-router-dom';
import { useManagePageSlice } from 'app/pages/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageLoader } from 'app/pages/slice/selectors';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import Cookies from 'js-cookie';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import { selectPhotoUpload } from 'app/pages/RiderRegistrationPage/slice/selectors';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/joy/Box';
import check from '../../assets/img/icons/check.png';
import isApiError from 'utils/isApiError';
import { useErrorHandlingSlice } from 'app/error/slice';

type Props = {};
const items = [
  { label: 'Personal Information', number: '1' },
  { label: 'Professional Affiliation', number: '2' },
];
const defaultValue: CoachRegistration = {
  id: null,
  isAssistant: false,
  firstName: '',
  lastName: '',
  email: '',
  primaryPhone: '',
  street: '',
  city: '',
  state: { id: '', name: '', children: null },
  zip: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  isTrial: true,
  hasSubmittedCertificateOfInsurance: false,
  hasVerifiedCertificateOfInsurance: false,
  isTeamAcceptingNewMembers: true,
  isInterestedInReferralProgram: true,
  professionalAffiliationMap: [],
  isReturning: false,
  team: {
    id: '',
    name: '',
    code: '',
    children: null,
    otherName: '',
    isAdminLive: false,
  },
  isMainCoach: false,
};
const defaultPhotoValue = {
  formData: {
    file: undefined,
  },
};

const CoachTryItResgistration = (props: Props) => {
  const { handleRequest } = useApi();

  //for Loader
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const { coachAddNewCoach, coachUploadCoachPhoto } = CoachService;
  const { lookupGetAllLookups } = LookupService;
  const [coachRegisterPayload, setCoachRegisterPayload] =
    useState<CoachRegistration>(defaultValue);
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const [teamData, setTeamData] = useState<GeneralLookup[]>([]);
  const [stateData, setStateData] = useState<GeneralLookup[]>([]);
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const [affiliationsData, setAffiliationsData] = useState<GeneralLookup[]>([]);
  const navigate = useNavigate();
  const [formNumber, setFormNumber] = useState<number>(0);
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState<boolean>();
  const [isTeamAccepting, setIsTeamAccepting] = useState<boolean | null>(true);
  const [isInterestedIn, setIsInterestedIn] = useState<boolean | null>(true);
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultPhotoValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const photoUploadDataRedux = useSelector(selectPhotoUpload);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);

  const totalPages = 2;
  const determinateValue = ((formNumber + 1) / totalPages) * 100;

  const handleNextForm = () => {
    setFormNumber(formNumber => formNumber + 1);
  };
  const handleBackForm = () => {
    setFormNumber(formNumber =>
      formNumber === 0 ? formNumber : formNumber - 1,
    );
    if (formNumber === 0) {
      navigate('/login');
    }
  };
  //HAndle get lookups
  const handleGetLookups = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(
      lookupGetAllLookups('STATE,TEAM,PROFESSIONALAFFILIATIONS,PAYER_TYPES'),
    )
      .then(res => {
        let varTeam = res?.filter(item => {
          if (item?.type === 'TEAM') {
            return item.values;
          }
        });
        let varState = res?.filter(item => {
          if (item?.type === 'STATE') {
            return item.values;
          }
        });
        let varAffiliations = res?.filter(item => {
          if (item?.type === 'PROFESSIONALAFFILIATIONS') {
            return item.values?.map(data => {
              return data.name;
            });
          }
        });
        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Coach') {
            return item ?? {};
          }
        });

        setTeamData(varTeam?.[0]?.values!);
        setStateData(varState?.[0]?.values!);
        setAffiliationsData(varAffiliations?.[0].values!);
        setPayerTypes(payerTypeObj);
        dispatch(PageActions.setPageLoader(false));
      })

      .catch(error => {});
  };
  const handleSubmit = (data: CoachRegistration) => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(coachAddNewCoach(coachRegisterPayload))
      .then(res => {
        if (res && !isApiError(res)) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          dispatch(PageActions.setPageLoader(false));
          const coachNewDataString = JSON.stringify(coachRegisterPayload);
          Cookies.set('payload', coachNewDataString);
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Coach created successfully',
              show: true,
              errorType: 'success',
            }),
          );
          navigate(`/payment/${payerTypes?.id}/${res.id}/false/false`);
          if (photoUploadPayload.formData?.file !== undefined) {
            handlePhotoUpload(res.id?.toString(), photoUploadPayload.formData);
            dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
          }
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
        }
      })
      .catch(error => {
        dispatch(
          actions.dispatchToast({
            errorTxt: error?.description,
            show: true,
            errorType: 'error',
          }),
        );
        dispatch(PageActions.setPageLoader(false));
      });
  };
  const handlePhotoUpload = (
    riderId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(coachUploadCoachPhoto(riderId, formData))
      .then(res => {})
      .catch(error => {});
  };
  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== formNumber) {
      setFormNumber(index);
    }
  };
  const handleItemClick = (index: number) => {};
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    handleGetLookups();
    const tryItCoachDataCookie = Cookies.get('payload');
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
    if (tryItCoachDataCookie) {
      setFormNumber(1);
      const updatedValue = JSON.parse(tryItCoachDataCookie);
      setCoachRegisterPayload(updatedValue);
      setIsTeamAccepting(updatedValue.isTeamAcceptingNewMembers);
      setIsInterestedIn(updatedValue.isInterestedInReferralProgram);
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
    }
  }, []);
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper id="AlumniNewMemberResgistration">
          <div className="Stepper-wrapper">
            {isSmallScreen ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <CircularProgress
                  size="lg"
                  determinate
                  value={determinateValue}
                >
                  {`${formNumber + 1} / ${totalPages}`}
                </CircularProgress>
                {items
                  .filter((_, index) => index === formNumber)
                  .map((item, index) => (
                    <h2 className="circular-stepper-heading" key={index}>
                      {item.label}
                    </h2>
                  ))}
              </Box>
            ) : (
              <Stepper
                value={formNumber}
                onChange={handleChangeStepper}
                items={items}
                className="form-stepper"
              >
                {items.map((item, index) => (
                  <StepperStep
                    key={index}
                    className={`stepper-step ${
                      index < formNumber ? 'completed' : ''
                    }`}
                    onClick={() => handleItemClick(index)}
                  >
                    {index < formNumber ? (
                      <span className="tick-icon">&#10003;</span>
                    ) : (
                      item.number
                    )}
                  </StepperStep>
                ))}
              </Stepper>
            )}
          </div>
          {formNumber === 0 ? (
            <PersonalInfoForm
              handleNextForm={handleNextForm}
              handleBackForm={handleBackForm}
              coachRegisterPayload={coachRegisterPayload}
              setCoachRegisterPayload={setCoachRegisterPayload}
              teamData={teamData}
              stateData={stateData}
            />
          ) : formNumber === 1 ? (
            <ProfessionalAffiliationsForm
              handleNextForm={handleSubmit}
              handleBackForm={handleBackForm}
              coachRegisterPayload={coachRegisterPayload}
              setCoachRegisterPayload={setCoachRegisterPayload}
              affiliationsData={affiliationsData}
              setIsTeamAccepting={setIsTeamAccepting}
              isTeamAccepting={isTeamAccepting}
              setIsInterestedIn={setIsInterestedIn}
              isInterestedIn={isInterestedIn}
              setPhotoUploadPayload={setPhotoUploadPayload}
              photoUploadPayload={photoUploadPayload}
              setFileAvailability={setFileAvailability}
              fileAvailability={fileAvailability}
              setUploadFile={setUploadFile}
              uploadFile={uploadFile}
            />
          ) : (
            <></>
          )}
        </MainWrapper>
      )}
    </>
  );
};

export default CoachTryItResgistration;
const MainWrapper = styled.div`
  height: 100%;
  .circular-stepper-heading {
    color: #8a8a8a;
  }
  .MuiCircularProgress-progress,
  .MuiCircularProgress-sizeLg {
    color: #8a8a8a;
    stroke: #2dce98;
  }
  .css-zitgxn-JoyCircularProgress-track {
    stroke: #434343;
  }
  .k-step-done {
    .k-step-indicator-text {
      position: relative;
      ::after {
        position: absolute;
        content: '';
        z-index: 15;
        background: #2dce98;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        border-radius: 50px;
        left: -8px;
        height: 26px;
        width: 26px;
        top: -1px;
      }
    }
  }
  .k-stepper.form-stepper {
    pointer-events: none;
  }
  input {
    ::placeholder {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .k-stepper .k-step-indicator::after {
    box-shadow: unset !important;
  }
  .k-stepper .k-step-indicator::after {
    border-style: unset !important;
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
  .k-stepper {
    padding-bottom: 20px;
  }
  .Stepper-wrapper {
    padding-top: 50px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        padding: 0 10px;
        height: 55px;
        gap: 5px;
      }
      .circular-stepper-heading {
        font-size: 13px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 50px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 15px;
      }
      .circular-stepper-heading {
        font-size: 18px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 55px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 20px;
        gap: 25px;
      }
      .circular-stepper-heading {
        font-size: 22px;
      }
    }
  }
`;
const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;

  &.completed {
    background-color: green !important;
  }
`;
