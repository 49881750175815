import React from 'react';
import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import SHdropdown from 'app/components/SHdropdown';
import { GeneralLookup, TeamRegistration } from 'services/openapi';
import SimpleReactValidator from 'simple-react-validator';
import { BackNextButton } from 'app/components/BackNextButton';
import CustomDragAndDropFiles from 'app/components/CustomDragAndDropFiles';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import { useDispatch } from 'react-redux';

type Props = {
  addTeamData: TeamRegistration;
  setAddTeamData: React.Dispatch<React.SetStateAction<TeamRegistration>>;
  generateTeamCode: (teamName?: string) => void;
  teamCodeValue: string | undefined;
  handleRegBack: () => void;
  createNewTeam: (data: TeamRegistration) => void;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
  stateLookupData: GeneralLookup[];
  isReturningValue: boolean | null;
  cancelPaymentCookie: boolean | undefined;
  setPhotoUploadPayload: React.Dispatch<
    React.SetStateAction<{
      formData?:
        | {
            file?: Blob | undefined;
          }
        | undefined;
    }>
  >;
  photoUploadPayload: {
    formData?:
      | {
          file?: Blob | undefined;
        }
      | undefined;
  };
  setFileAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  fileAvailability: boolean;
  setUploadFile: React.Dispatch<React.SetStateAction<File | null | undefined>>;
  uploadFile: File | null | undefined;
};
export const FinancialInfoForm = ({
  addTeamData,
  setAddTeamData,
  generateTeamCode,
  teamCodeValue,
  handleRegBack,
  createNewTeam,
  setShowLoader,
  stateLookupData,
  isReturningValue,
  cancelPaymentCookie,
  setPhotoUploadPayload,
  photoUploadPayload,
  setFileAvailability,
  fileAvailability,
  setUploadFile,
  uploadFile,
}: Props) => {
  const [valid, setValid] = useState<boolean>(false);
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const dispatch = useDispatch();
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [addTeamData]);
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setAddTeamData({
        ...addTeamData,
        financialResponsibility: {
          ...addTeamData.financialResponsibility!,
          phone: inputValue,
        },
      });
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setAddTeamData({
        ...addTeamData,
        financialResponsibility: {
          ...addTeamData.financialResponsibility!,
          zip: inputValue,
        },
      });
    }
  };
  const handleFileUpload = e => {
    var file = e.target.files[0];
    dispatch(manageAppActions.setPhotoUpload(file));

    setUploadFile?.(file);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: file },
    });
  };
  const handleRemoveFile = e => {
    setUploadFile?.(undefined);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };
  const handleFileOnCancelClick = e => {
    setUploadFile?.(undefined);
    setPhotoUploadPayload?.({
      ...photoUploadPayload,
      formData: { file: undefined },
    });
  };

  React.useEffect(() => {
    if (uploadFile === undefined) {
      setFileAvailability?.(true);
    } else {
      setFileAvailability?.(false);
    }
  }, [uploadFile]);
  return (
    <MainWrapper>
      <OverflowWrapper>
        <h4 className="header">Financial Responsibility</h4>
        <p className="first-para">
          In order to qualify as a YEDA Team, at least one member of the team’s
          Coaching Staff, School, Farm, or other named Individual must sign the
          below indicating financial responsibility for the team and any debts
          incurred.
        </p>
        <TextFieldGrid>
          <TextFieldWrapper>
            <StyledTextField
              label="Name"
              type="text"
              name="name"
              onChange={e =>
                setAddTeamData({
                  ...addTeamData,
                  financialResponsibility: {
                    ...addTeamData.financialResponsibility!,
                    name: e.target.value!,
                  },
                })
              }
              value={addTeamData?.financialResponsibility?.name}
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <StyledTextField
              label="Phone Number"
              type="tel"
              name="phoneNumber"
              onChange={handlePhChange}
              value={addTeamData?.financialResponsibility?.phone}
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <MainAddressWrapper>
          <div className="horizontal-line">
            <span>Main Address</span>
          </div>
          <TextFieldGrid>
            <TextFieldWrapper>
              <StyledTextField
                label="Street Address"
                type="text"
                name="streetAddress"
                onChange={e =>
                  setAddTeamData({
                    ...addTeamData,
                    financialResponsibility: {
                      ...addTeamData.financialResponsibility!,
                      street: e.target.value!,
                    },
                  })
                }
                value={addTeamData?.financialResponsibility?.street}
                validations={'required'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                label="City"
                type="text"
                name="city"
                onChange={e =>
                  setAddTeamData({
                    ...addTeamData,
                    financialResponsibility: {
                      ...addTeamData.financialResponsibility!,
                      city: e.target.value!,
                    },
                  })
                }
                value={addTeamData?.financialResponsibility?.city}
                validations={'required|max:40'}
                validator={simpleValidator.current}
                maxLength={40}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <SHdropdown
                className="drop-down"
                label="State"
                data={stateLookupData}
                onDataChange={e =>
                  setAddTeamData({
                    ...addTeamData,
                    financialResponsibility: {
                      ...addTeamData.financialResponsibility!,
                      state: e,
                    },
                  })
                }
                defaultValue={addTeamData?.financialResponsibility?.state}
                validations={'required'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
            <TextFieldWrapper>
              <StyledTextField
                label="Zip"
                type="text"
                name="zip"
                onChange={handleZipChange}
                value={addTeamData?.financialResponsibility?.zip}
                validations={'required|alpha_num|min:5|max:9'}
                validator={simpleValidator.current}
              />
            </TextFieldWrapper>
          </TextFieldGrid>
        </MainAddressWrapper>
        {/* {!isReturningValue && (
          <CodeWrapper>
            <Button
              className="btn-generate"
              onClick={() => {
                generateTeamCode(addTeamData.name);
              }}
              disabled={valid}
            >
              Generate Team Code:
            </Button>
            <span style={teamCodeStyle}>
              {teamCodeValue ?? addTeamData?.teamCode}
            </span>
          </CodeWrapper>
        )} */}
        <PhotoUploadWrapper>
          <h3 className="photo-title"> Upload your current photo below. </h3>
          <CustomDragAndDropFiles
            className="dragNdrop"
            onCancel={e => handleFileOnCancelClick(e)}
            onChange={e => handleFileUpload(e)}
            uploadBtnDisabled={!fileAvailability}
            onRemoveFile={handleRemoveFile}
            chooseFileBtnDisabled={!fileAvailability}
            uploadFile={uploadFile}
          />
        </PhotoUploadWrapper>
        <BottomRightWrapper className="bg-color-change">
          <ButtonWrapper>
            <BackNextButton
              classNameBack="btn-back"
              classNameNxt="btn-nxt"
              onClickNxt={() => createNewTeam(addTeamData)}
              onClickBack={handleRegBack}
              next="Submit and Pay"
              back="Back"
              disableNext={valid}
            />
          </ButtonWrapper>
        </BottomRightWrapper>
      </OverflowWrapper>
    </MainWrapper>
  );
};
const BottomRightWrapper = styled.div`
  z-index: 1;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
const ButtonWrapper = styled.div``;
const MainWrapper = styled.div`
  color: #9597a6;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 100%;
  height: calc(100vh - 205px);

  .first-para {
    font-family: 'Roboto';
    font-size: 14px;
    font-style: oblique;
    margin: 12px 12px;
  }
  .btn-generate {
    background-color: #306fdb;
    border: 1px solid #2a2a2a;
    color: #ffffff;
    border-radius: 6px;
    width: auto;
    height: 40px;
    font-weight: 500;
    font-size: 13px;
    box-shadow: none;
    margin: 15px 10px 15px 0px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .first-para {
      font-size: 11px;
    }
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    .first-para {
      font-size: 12px;
    }
  }
  @media (min-width: 767.99px) and (max-width: 991px) {
    .first-para {
      font-size: 13px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .first-para {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .first-para {
      font-size: 14px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .first-para {
      font-size: 15px;
    }
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
  .header {
    margin: 0px 12px;
    font-weight: 100;
    font-family: 'Roboto';
    color: #9597a6;
    font-size: 17px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .header {
      font-size: 13px;
    }
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    .header {
      font-size: 14px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .header {
      font-size: 15px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .header {
      font-size: 16px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .header {
      font-size: 17px;
    }
  }
`;

const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
`;
const TextFieldWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
  &.styled-TextFieldWrapper {
    display: grid;
    align-items: end;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    width: 100% !important;
  }
  @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;
const MainAddressWrapper = styled.div`
  .horizontal-line {
    display: flex;
    align-items: baseline;
    padding-bottom: 3%;
    margin: 0px 12px;
  }
  .horizontal-line span {
    margin: 0 10px 0 0;
    white-space: nowrap;
    color: #4f4f4f;
    font-size: 15px;
  }
  .horizontal-line::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #434343;
    height: 3px;
  }
`;
const PhotoUploadWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  .photo-title {
    color: #a7a7a7;
    font-family: 'Roboto';
    font-size: 15px;
    text-align: center;
  }
`;
const CodeWrapper = styled.div`
  padding: 0 10px;
`;
