import { CardSubWrapper, CardWrapper } from 'admin/components/wrappers';
import IconCell from 'app/components/CustomDataGrid/Cells/IconCell';
import TeamCell from 'app/components/CustomDataGrid/Cells/TeamCell';
import CustomDataGrid from 'app/components/CustomDataGrid/Index';
import { CustomDeletePopup } from 'app/components/CustomDeletePopup';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  GeneralLookup,
  CoachListWrapper,
  ChangeCoachActiveStatusRequest,
} from 'services/openapi';
import { useNavigateToRoute } from 'utils/common';
import {
  GridColumnProps,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import AdminCoachProfileCell from 'app/components/CustomDataGrid/Cells/AdminCoachProfileCell';
import CoachAddressCell from 'app/components/CustomDataGrid/Cells/CoachAddressCell';
import EmailCell from 'app/components/CustomDataGrid/Cells/EmailCell';
import CoachAffiliationCell from 'app/components/CustomDataGrid/Cells/CoachAffiliationCell';
import CoachphoneCell from 'app/components/CustomDataGrid/Cells/CoachPhoneCell';
import CoachStatus from 'app/components/CustomDataGrid/Cells/CoachStatus';
import ExtrasCell from 'app/components/CustomDataGrid/Cells/ExtrasCell';
import { useLocation } from 'react-router-dom';
import { SortDescriptor } from '@progress/kendo-data-query';
import { TableSkeletonLoader } from 'app/components/TableSkeletonLoader';
import HorizontalKebabCell from 'app/components/CustomDataGrid/Cells/HorizontalKebabCell';
import PaymentStatusCell from 'app/components/CustomDataGrid/Cells/PaymentStatusCell';
type Props = {
  coachProfileData?: CoachListWrapper;
  setPageSkip: React.Dispatch<React.SetStateAction<number | undefined>>;
  pageSkip: number | undefined;
  debounceCoachSearch: string;
  selectedItemId: string | null;
  lookup: GeneralLookup[];
  fetchManageGetCoaches: (
    season?: string | undefined,
    searchInput?: string | undefined,
    status?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  CoachActiveOrInactive: (
    coachId: string,
    season?: string | undefined,
    requestBody?: ChangeCoachActiveStatusRequest,
    searchInput?: string,
    status?: string,
    sortBy?: string,
    sortOrder?: number,
    pageNumber?: number,
    pageSize?: number,
    item?: string,
  ) => void;
  deleteCoachList: (
    id?: string | undefined,
    season?: string | undefined,
    searchInput?: string | undefined,
    status?: string | undefined,
    sortBy?: string | undefined,
    sortOrder?: number | undefined,
    pageNumber?: number,
    pageSize?: number,
  ) => void;
  sortField: string | undefined;
  sortOrder: number | undefined;
  setSortField: React.Dispatch<React.SetStateAction<string>>;
  setSortOrder: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
};
const CoachesTable = ({
  coachProfileData,
  setPageSkip,
  pageSkip,
  debounceCoachSearch,
  fetchManageGetCoaches,
  selectedItemId,
  deleteCoachList,
  lookup,
  CoachActiveOrInactive,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  loading,
}: Props) => {
  const redirect = useNavigateToRoute();
  const currentLocation = useLocation();
  const isPastDataFlag = currentLocation.pathname.includes('past-data');
  const activeId = lookup.find(item => item.name === 'Active');
  const inActiveId = lookup.find(item => item.name === 'Inactive');
  const [coachId, setCoachId] = useState('');
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const initialSort: Array<SortDescriptor> = [
    { field: 'coachName', dir: 'asc' },
  ];
  const [sort, setSort] = React.useState(initialSort);
  const handleMoreClick = (item: string, e) => {
    const seasonId = e.dataItem.season.id;
    let activeRequestBody = {
      statusId: activeId?.id ?? '',
      seasonId: seasonId,
    };
    let inActiveRequestBody = {
      statusId: inActiveId?.id ?? '',
      seasonId: seasonId,
    };
    const id = e.dataItem.id;
    if (item === 'Make active') {
      CoachActiveOrInactive(
        id,
        e.dataItem.season?.id,
        activeRequestBody,
        debounceCoachSearch,
        selectedItemId ?? '',
        sortField,
        sortOrder,
        pageSkip,
        10,
        item,
      );
    } else if (item === 'Make inactive') {
      CoachActiveOrInactive(
        id,
        e.dataItem.season?.id,
        inActiveRequestBody,
        debounceCoachSearch,
        selectedItemId ?? '',
        sortField,
        sortOrder,
        pageSkip,
        10,
        item,
      );
    } else if (item === 'Delete coach') {
      setCoachId(id);
      setShowDeletePopup(true);
    } else if (item === 'Edit coach info') {
      redirect(`/admin/coaches/update/${id}`);
    }
  };
  const handleViewClick = e => {
    if (isPastDataFlag) {
      redirect(`/admin/past-data/coaches/view/${e.id}`);
    } else {
      redirect(`/admin/coaches/view/${e.id}`);
    }
  };
  const CoachColumn: GridColumnProps[] = [
    {
      field: 'coachName',
      title: 'Coach Name',
      cell: e => (
        <AdminCoachProfileCell e={e} onViewClick={e => handleViewClick(e)} />
      ),
      width: '300',
      sortable: true,
      locked: true,
    },
    {
      field: 'team',
      title: 'Team',
      cell: e => <TeamCell e={e} />,
      width: '200',
      sortable: true,
    },
    {
      field: 'address',
      title: 'Address',
      cell: e => <CoachAddressCell e={e} />,
      width: '200',
      sortable: false,
    },
    {
      field: 'email',
      title: 'Email',
      cell: e => <EmailCell e={e} />,
      width: '200',
      sortable: false,
    },
    {
      field: 'primaryPhone',
      title: 'Phone',
      cell: e => <CoachphoneCell e={e} />,
      width: '120',
      sortable: false,
    },
    {
      field: 'affiliations',
      title: 'Affiliations',
      cell: e => <CoachAffiliationCell e={e} />,
      width: '200',
      sortable: false,
    },
    {
      field: '',
      title: 'Extras',
      cell: e => <ExtrasCell e={e} />,
      width: '250',
      sortable: false,
    },
    {
      field: 'isPaymentComplete',
      title: 'Payment Completed',
      cell: e => <PaymentStatusCell e={e} />,
      width: '150',
      sortable: false,
    },
    {
      field: 'status',
      title: 'Status',
      cell: e => <CoachStatus e={e} />,
      width: '100',
      sortable: false,
    },
    {
      field: 'action',
      title: 'Action',
      cell: e => (
        <HorizontalKebabCell
          isAssigned={true}
          e={e}
          onMoreClick={handleMoreClick}
          isCoach={true}
          isPastDataFlag={isPastDataFlag}
        />
      ),
      width: '100',
      sortable: false,
    },
  ];
  const CoachPastDataColumn = CoachColumn.slice(0, -1);
  const handlePageChange = event => {
    setPageSkip(event.page.skip);
    fetchManageGetCoaches(
      '',
      debounceCoachSearch,
      selectedItemId ?? '',
      sortField,
      sortOrder,
      event.page.skip,
      10,
    );
  };
  const handleDeleteCoachList = () => {
    deleteCoachList(
      coachId,
      '',
      debounceCoachSearch,
      selectedItemId ?? '',
      sortField,
      sortOrder,
      pageSkip,
      10,
    );
    setShowDeletePopup(false);
  };
  const handleSortChange = e => {
    let sortOrderValue = e.dir === 'asc' ? 0 : 1;
    setSortOrder(sortOrderValue);
    setSortField(e.field);
    fetchManageGetCoaches(
      '',
      debounceCoachSearch,
      selectedItemId ?? '',
      e.field,
      sortOrderValue,
      pageSkip,
      10,
    );
  };

  return (
    <>
      {loading ? (
        <TableSkeletonLoader rowContent={8} width={100} headingWidth={80} />
      ) : (
        <CardWrapper style={{ paddingTop: '15px' }}>
          <CardSubWrapper className="d-flex"></CardSubWrapper>
          <GridWrapper>
            <CustomDataGrid
              showPagination
              data={coachProfileData?.coaches ?? []}
              columns={isPastDataFlag ? CoachPastDataColumn : CoachColumn}
              pageSize={10}
              totalCount={coachProfileData?.pageDetails?.count}
              onPageChange={event => {
                handlePageChange(event);
              }}
              skip={pageSkip}
              sortable={{
                allowUnsort: false,
                mode: 'single',
              }}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                let sortvalue = e.sort[0];
                setSort(e.sort);
                handleSortChange(sortvalue);
              }}
            />
            <CustomDeletePopup
              deleteIcon={true}
              headerUnderline={false}
              popupSubLabel="Are you sure you want to delete this coach?"
              className="body-scroll-lock height-full delete-update"
              popupLabel="Delete coach"
              setVisible={setShowDeletePopup}
              visible={showDeletePopup}
              align="centre"
              btnLabel1="Cancel"
              btnLabel2="Delete"
              handlebtn2Click={handleDeleteCoachList}
              handlebtn1Click={() => {
                setShowDeletePopup(false);
              }}
            />
          </GridWrapper>
        </CardWrapper>
      )}
    </>
  );
};
export default CoachesTable;
const GridWrapper = styled.div``;
