import React, { useEffect, useState } from 'react';
import { PersonalInfoForm } from './components/PersonalInfoForm';
import styled from 'styled-components';
import { ProfessionalAffiliationsForm } from './components/ProfessionalAffiliationsForm';
import {
  CoachRegistration,
  CoachService,
  GeneralLookup,
  LookupService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import { useNavigate } from 'react-router-dom';
import { BackNextButton } from 'app/components/BackNextButton';
import RadioButtonGroup from 'app/components/RadioButtonGroup';
import { useManagePageSlice } from 'app/pages/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageLoader } from 'app/pages/slice/selectors';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import Cookies from 'js-cookie';
import { FormSecondaryLabel } from 'app/components/SHLabel';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import { selectPhotoUpload } from 'app/pages/RiderRegistrationPage/slice/selectors';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/joy/Box';
import check from '../../assets/img/icons/check.png';
import { useErrorHandlingSlice } from 'app/error/slice';
import isApiError from 'utils/isApiError';

type Props = {};
const items = [
  { label: 'Personal Information', number: '1' },
  { label: 'Professional Affiliation', number: '2' },
];
const defaultValue: CoachRegistration = {
  id: null,
  isAssistant: true,
  firstName: '',
  lastName: '',
  email: '',
  primaryPhone: '',
  street: '',
  city: '',
  state: { id: '', name: '', children: null },
  zip: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  isTrial: false,
  hasSubmittedCertificateOfInsurance: false,
  hasVerifiedCertificateOfInsurance: false,
  isTeamAcceptingNewMembers: false,
  isInterestedInReferralProgram: false,
  professionalAffiliationMap: [],
  isReturning: true,
  team: {
    id: '',
    name: '',
    code: '',
    children: null,
    otherName: '',
    isAdminLive: false,
  },
  isMainCoach: false,
};
const defaultPhotoValue = {
  formData: {
    file: undefined,
  },
};

const AssistantCoachResgistration = (props: Props) => {
  const { handleRequest } = useApi();
  const { actions: ErrorHandlingActions } = useErrorHandlingSlice();
  const { coachAddNewCoach, coachUploadCoachPhoto, coachGetCoach } =
    CoachService;
  const { lookupGetAllLookups } = LookupService;
  const [coachRegisterPayload, setCoachRegisterPayload] =
    useState<CoachRegistration>(defaultValue);
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const [teamData, setTeamData] = useState<GeneralLookup[]>([]);
  const [stateData, setStateData] = useState<GeneralLookup[]>([]);
  const [affiliationsData, setAffiliationsData] = useState<GeneralLookup[]>([]);
  const [isReturningValue, setIsReturningValue] = useState<boolean | null>(
    null,
  );
  const [coachDefault, setCoachDefault] = useState<GeneralLookup>({
    id: '',
    name: '',
  });
  const navigate = useNavigate();
  const [newValue, setNewValue] = useState<boolean | undefined>(undefined);
  const [formNumber, setFormNumber] = useState<number>(0);
  //for Loader
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState(false);
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultPhotoValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const photoUploadDataRedux = useSelector(selectPhotoUpload);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);
  const [coachDetails, setCoachDetails] = useState<GeneralLookup[]>([]);
  const totalPages = 2;
  const determinateValue = ((formNumber + 1) / totalPages) * 100;

  const handleNextForm = () => {
    setFormNumber(formNumber => formNumber + 1);
  };
  const handleBackForm = () => {
    if (formNumber === 1) {
      setFormNumber(formNumber => formNumber - 1);
    }
    if (formNumber === 0) {
      Cookies.remove('payload');
      Cookies.remove('paymentCancel');
      Cookies.remove('coachDropdownData');
      Cookies.remove('TeamNewValue');
      setIsReturningValue(null);
      setNewValue(undefined);
      setCoachDefault({
        id: '',
        name: '',
      });
      setCoachRegisterPayload(defaultValue);
      setUploadFile(undefined);
      setFileAvailability(true);
    }
  };
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const handleGetLookups = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(
      lookupGetAllLookups(
        'STATE,ACTIVETEAM,PROFESSIONALAFFILIATIONS,PAYER_TYPES,ASSISTANT_COACH',
      ),
    )
      .then(res => {
        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Coach') {
            return item ?? {};
          }
        });
        setPayerTypes(payerTypeObj);

        let varTeam = res?.filter(item => {
          if (item?.type === 'ACTIVETEAM') {
            return item.values;
          }
        });
        let varState = res?.filter(item => {
          if (item?.type === 'STATE') {
            return item.values;
          }
        });
        let varAffiliations = res?.filter(item => {
          if (item?.type === 'PROFESSIONALAFFILIATIONS') {
            return item.values?.map(data => {
              return data.name;
            });
          }
        });
        let varCoachData = res.find(item => {
          if (item?.type === 'ASSISTANT_COACH') {
            return item.values;
          }
        });
        setCoachDetails(varCoachData.values);

        setTeamData(varTeam?.[0]?.values!);
        setStateData(varState?.[0]?.values!);
        setAffiliationsData(varAffiliations?.[0]?.values!);

        dispatch(PageActions.setPageLoader(false));
      })

      .catch(error => {});
  };
  const handleReturningSelection = event => {
    const eventString = JSON.stringify(event.value);
    Cookies.set('TeamNewValue', eventString);
    setNewValue(event.value);
  };
  const handleGetReturningCoachDetails = (coachId: string) => {
    handleRequest(
      coachGetCoach(coachId)
        .then(res => {
          if (res) {
            const coachGetRes: CoachRegistration = {
              id: res.id,
              isAssistant: res.isAssistant,
              firstName: res?.firstName ?? '',
              lastName: res?.lastName ?? '',
              email: res?.email ?? '',
              primaryPhone: res?.primaryPhone
                ? res?.primaryPhone.replaceAll('-', '')
                : '',
              street: res?.street ?? '',
              city: res?.city ?? '',
              state: res?.state ?? { id: '', name: '' },
              zip: res?.zip ?? '',
              emergencyContactName: res?.emergencyContactName ?? '',
              emergencyContactPhone: res?.emergencyContactPhone ?? '',
              isTrial: res?.isTrial ?? false,
              hasSubmittedCertificateOfInsurance: res?.isTrial ?? false,
              hasVerifiedCertificateOfInsurance: res?.isTrial ?? false,
              isTeamAcceptingNewMembers:
                res?.isTeamAcceptingNewMembers ?? false,
              isInterestedInReferralProgram:
                res?.isInterestedInReferralProgram ?? false,
              professionalAffiliationMap: res?.professionalAffiliationMap ?? [],
              isReturning: res?.isReturning ?? false,
              team: { id: res?.team?.id ?? '', name: res?.team?.name ?? '' },
              isMainCoach: res?.team?.isMainCoach ?? false,
            };
            setCoachRegisterPayload(coachGetRes);
          }
        })
        .catch(error => {}),
    );
  };

  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== formNumber) {
      setFormNumber(index);
    }
  };
  const handleItemClick = (index: number) => {};
  const handleSubmit = (data: CoachRegistration) => {
    const filteredPrimaryPhone = coachRegisterPayload?.primaryPhone.replaceAll(
      /[^\w\d]/g,
      '',
    );
    const payload: CoachRegistration = {
      ...data,
      primaryPhone: filteredPrimaryPhone,
      isReturning: isReturningValue ?? false,
    };
    dispatch(PageActions.setPageLoader(true));
    handleRequest(coachAddNewCoach(payload))
      .then(res => {
        if (res && res.errors) {
          dispatch(
            ErrorHandlingActions.dispatchToast({
              errorTxt: res.errors,
              show: true,
              errorType: 'error',
            }),
          );
        } else if (res && !isApiError(res)) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          if (photoUploadPayload?.formData?.file !== undefined) {
            handlePhotoUpload(res.id?.toString(), photoUploadPayload.formData);
            dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
          }
          const assistantReturningDataString = JSON.stringify(payload);
          Cookies.set('payload', assistantReturningDataString);
          if (res.id) {
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Assistant Coach created successfully',
                show: true,
                errorType: 'success',
              }),
            );
            navigate(`/payment/${payerTypes?.id}/${res?.id}/false/false`);
          } else {
            dispatch(
              actions.dispatchToast({
                errorTxt: res?.description,
                show: true,
                errorType: 'error',
              }),
            );
          }
        }
        dispatch(PageActions.setPageLoader(false));
      })
      .catch(error => {
        dispatch(
          actions.dispatchToast({
            errorTxt: error?.description,
            show: true,
            errorType: 'error',
          }),
        );
      });
  };
  const handlePhotoUpload = (
    riderId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(coachUploadCoachPhoto(riderId, formData))
      .then(res => {})
      .catch(error => {});
  };

  const handleRadioNxt = () => {
    if (newValue === true) {
      setIsReturningValue(true);
    } else if (newValue === false) {
      setIsReturningValue(false);
    }
  };
  const handleRadioBack = () => {
    Cookies.remove('payload');
    Cookies.remove('paymentCancel');
    Cookies.remove('coachDropdownData');
    Cookies.remove('TeamNewValue');
    navigate(-1);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    handleGetLookups();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    if (paymentCancelStatus) {
      const payementCancelParse = JSON.parse(paymentCancelStatus);
      setCancelPaymentCookie(payementCancelParse);
    }
  }, []);

  useEffect(() => {
    const associateData = Cookies.get('payload');
    const coachRes = Cookies.get('coachDropdownData');
    if (associateData) {
      setFormNumber(1);
      const returningOrNew = Cookies.get('TeamNewValue');
      const parseValue = JSON.parse(returningOrNew!);
      setIsReturningValue(parseValue);
      if (parseValue === true) {
        const coachResparse = JSON.parse(coachRes);
        setCoachDefault(coachResparse);
      }
      const associateDataParse = JSON.parse(associateData);
      setCoachRegisterPayload(associateDataParse);
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
    }
  }, []);

  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper id="CoachResgistration">
          {isReturningValue === null && (
            <RadioWrapper>
              <ReturningWrapper>
                <FormSecondaryLabel className="apply-label">
                  Are you a Returning Assistant Coach?
                </FormSecondaryLabel>
                <RadioButtonWrapper>
                  <RadioButtonGroup onChange={handleReturningSelection} />
                </RadioButtonWrapper>
              </ReturningWrapper>
              <BottomRightWrapper className="bg-color-change">
                <ButtonWrapper>
                  <BackNextButton
                    classNameBack="btn-back"
                    classNameNxt="btn-nxt"
                    onClickNxt={handleRadioNxt}
                    onClickBack={handleRadioBack}
                    next="Next"
                    back="Back"
                    disableNext={newValue === undefined}
                  />
                </ButtonWrapper>
              </BottomRightWrapper>
            </RadioWrapper>
          )}
          {isReturningValue !== null && (
            <>
              <div className="Stepper-wrapper">
                {isSmallScreen ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <CircularProgress
                      size="lg"
                      determinate
                      value={determinateValue}
                    >
                      {`${formNumber + 1} / ${totalPages}`}
                    </CircularProgress>
                    {items
                      .filter((_, index) => index === formNumber)
                      .map((item, index) => (
                        <h2 className="circular-stepper-heading" key={index}>
                          {item.label}
                        </h2>
                      ))}
                  </Box>
                ) : (
                  <Stepper
                    value={formNumber}
                    onChange={handleChangeStepper}
                    items={items}
                    className="form-stepper"
                  >
                    {items.map((item, index) => (
                      <StepperStep
                        key={index}
                        className={`stepper-step ${
                          index < formNumber ? 'completed' : ''
                        }`}
                        onClick={() => handleItemClick(index)}
                      >
                        {index < formNumber ? (
                          <span className="tick-icon">&#10003;</span>
                        ) : (
                          item.number
                        )}
                      </StepperStep>
                    ))}
                  </Stepper>
                )}
              </div>
              {formNumber === 0 ? (
                <PersonalInfoForm
                  handleNextForm={handleNextForm}
                  handleBackForm={handleBackForm}
                  coachRegisterPayload={coachRegisterPayload}
                  setCoachRegisterPayload={setCoachRegisterPayload}
                  teamData={teamData}
                  stateData={stateData}
                  isReturningValue={isReturningValue}
                  handleGetReturningCoachDetails={
                    handleGetReturningCoachDetails
                  }
                  setCoachDefault={setCoachDefault}
                  coachDefault={coachDefault}
                  coachDetails={coachDetails}
                />
              ) : formNumber === 1 ? (
                <ProfessionalAffiliationsForm
                  handleSubmit={handleSubmit}
                  handleBackForm={handleBackForm}
                  coachRegisterPayload={coachRegisterPayload}
                  setCoachRegisterPayload={setCoachRegisterPayload}
                  affiliationsData={affiliationsData}
                  isReturningValue={isReturningValue}
                  cancelPaymentCookie={cancelPaymentCookie}
                  setPhotoUploadPayload={setPhotoUploadPayload}
                  photoUploadPayload={photoUploadPayload}
                  setFileAvailability={setFileAvailability}
                  fileAvailability={fileAvailability}
                  setUploadFile={setUploadFile}
                  uploadFile={uploadFile}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </MainWrapper>
      )}
    </>
  );
};
const MainWrapper = styled.div`
  height: 100%;
  .circular-stepper-heading {
    color: #8a8a8a;
  }
  .MuiCircularProgress-progress,
  .MuiCircularProgress-sizeLg {
    color: #8a8a8a;
    stroke: #2dce98;
  }
  .css-zitgxn-JoyCircularProgress-track {
    stroke: #434343;
  }
  .k-step-done {
    .k-step-indicator-text {
      position: relative;
      ::after {
        position: absolute;
        content: '';
        z-index: 15;
        background: #2dce98;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        border-radius: 50px;
        left: -8px;
        height: 26px;
        width: 26px;
        top: -1px;
      }
    }
  }
  .k-stepper.form-stepper {
    pointer-events: none;
  }
  input {
    ::placeholder {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .k-stepper .k-step-indicator::after {
    box-shadow: unset !important;
  }
  .k-stepper .k-step-indicator::after {
    border-style: unset !important;
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
  .k-stepper {
    padding-bottom: 20px;
  }
  .Stepper-wrapper {
    padding-top: 50px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        padding: 0 10px;
        height: 55px;
        gap: 5px;
      }
      .circular-stepper-heading {
        font-size: 13px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 50px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 15px;
      }
      .circular-stepper-heading {
        font-size: 18px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 55px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 20px;
        gap: 25px;
      }
      .circular-stepper-heading {
        font-size: 22px;
      }
    }
  }
`;
const RadioWrapper = styled.div`
  padding: 70px 60px 0px 60px;
  width: 100%;

  height: 100%;
`;
const ReturningWrapper = styled.div`
  width: 100%;
  padding: 30px 20px;

  @media (min-width: 100px) and (max-width: 319px) {
    ${FormSecondaryLabel} {
      font-size: 11px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    ${FormSecondaryLabel} {
      font-size: 11px !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${FormSecondaryLabel} {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    ${FormSecondaryLabel} {
      font-size: 12px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    ${FormSecondaryLabel} {
      font-size: 14px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    ${FormSecondaryLabel} {
      font-size: 14px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    ${FormSecondaryLabel} {
      font-size: 15px;
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    ${FormSecondaryLabel} {
      font-size: 15px;
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    ${FormSecondaryLabel} {
      font-size: 16px;
    }
  }
`;
const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;

  &.completed {
    background-color: green !important;
  }
`;
const BottomRightWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 10px;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
const RadioButtonWrapper = styled.div`
  display: flex;
  padding: 15px 0;
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }
  .k-radio {
    background-color: transparent;
    border: 1px solid #ced4da;
  }
  .k-radio:checked,
  .k-radio.k-checked {
    border-color: 1px solid #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
  .k-radio-list {
    flex-flow: row-reverse;
    gap: 50px;
  }
  .k-radio-item .k-radio-label {
    color: #ffffff;
  }
`;
const ButtonWrapper = styled.div``;
export default AssistantCoachResgistration;
