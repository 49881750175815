/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CoachInfo } from '../models/CoachInfo';
import type { CoachRegistration } from '../models/CoachRegistration';
import type { CreateInsuranceApplication } from '../models/CreateInsuranceApplication';
import type { GeneralCoach } from '../models/GeneralCoach';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CoachService {

    /**
     * CreateInsuranceApplication
     * @param requestBody 
     * @returns any Created
     * @throws ApiError
     */
    public static coachCreateInsuranceApplication(
requestBody?: CreateInsuranceApplication,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/coach/insuranceApplication',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Register team
     * @param requestBody 
     * @returns CoachRegistration Created
     * @throws ApiError
     */
    public static coachAddNewCoach(
requestBody?: CoachRegistration,
): CancelablePromise<CoachRegistration> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/coach/addCoach',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to Upload Certificate of Insurance
     * @param coachId 
     * @param formData 
     * @returns string Created
     * @throws ApiError
     */
    public static coachUploadCertificateOfInsurance(
coachId: string,
formData?: {
certificateOfInsurance?: Blob;
},
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/coach/{coachId}/certificateOfInsurance',
            path: {
                'coachId': coachId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to get Coach List
     * @returns GeneralCoach Success
     * @throws ApiError
     */
    public static coachGetCoachList(): CancelablePromise<Array<GeneralCoach>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/coach/coach',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * API to get  Assistant Coach List
     * @returns GeneralCoach Success
     * @throws ApiError
     */
    public static coachGetAssistantCoach(): CancelablePromise<Array<GeneralCoach>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/coach/assistantCoach',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * API to get Coach with CoachId
     * @param coachId 
     * @param seasonId 
     * @returns CoachInfo Success
     * @throws ApiError
     */
    public static coachGetCoach(
coachId: string,
seasonId?: string,
): CancelablePromise<CoachInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/coach/coach/{coachId}',
            path: {
                'coachId': coachId,
            },
            query: {
                'seasonId': seasonId,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * API to upload Coach Photo
     * @param coachId 
     * @param formData 
     * @returns any Created
     * @throws ApiError
     */
    public static coachUploadCoachPhoto(
coachId: string,
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/coach/{coachId}/photo',
            path: {
                'coachId': coachId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
