import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { LoginCard } from 'app/components/LoginCard';
import horseImg from './assets/images/bg/horse.png';
import bigEllipse from './assets/images/icons/BigEllipse.png';
import smallEllipse from './assets/images/icons/SmallEllipse 3.png';
import showyedaImg from './assets/images/bg/show-yeda.png';
import yedaFullform from './assets/images/bg//yeda-fullform.png';
import { useNavigate } from 'react-router-dom';
import { ShowyedaLogo } from 'app/components/ShowyedaLogo';
import bgVideo from './assets/videos/showyedaLoginBg.mp4';
import background from './assets/images/bg/loginBg.jpg';
import useApi from 'services/mavapi/useApi';
import {
  AuthService,
  CoachRegistration,
  CoachService,
  Login,
  LoginUser,
  PasswordResetModel,
} from 'services/openapi';
import Cookies from 'js-cookie';
import { useManagePageSlice } from '../slice';
import { selectPageLoader } from '../slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import 'lazysizes';
import '@fontsource/inconsolata';
import useToast from 'hooks/Toast';
import PasswordResetEmail from 'app/components/PasswordResetEmail';
import arrow from './assets/images/icons/login-arrow.png';
import isApiError from 'utils/isApiError';
import { useErrorHandlingSlice } from 'app/error/slice';
import useCommonLookUpDatas from 'hooks/useCommonLookupData';

type Props = {};
const data = [
  { id: 1, name: 'Register Team', hover: false },
  { id: 2, name: 'Register Coach', hover: false },
  { id: 3, name: 'Register Rider', hover: false },
  { id: 4, name: 'Register Associate', hover: false },
  { id: 5, name: 'Register Try-it', hover: false },
];
const tryItData = [
  { tryId: 1, name: 'Team', hover: false },
  { tryId: 2, name: 'Coach', hover: false },
  { tryId: 3, name: 'Rider', hover: false },
  { tryId: 4, name: 'Alumni', hover: false },
  { tryId: 5, name: 'EWD', hover: false },
];

export const LoginPage = (props: Props) => {
  const { error: apiError, handleRequest } = useApi();
  const { authLogin, authChangePassword } = AuthService;
  const { coachGetCoach } = CoachService;
  const [isHovered, setIsHovered] = useState({});
  const [tryIsHovered, setTryIsHovered] = useState({});
  const [showFirstFooter, setShowFirstFooter] = useState(false);
  const navigate = useNavigate();
  const LoginDefaultData: Login = { userName: '', password: '' };
  const [loginData, setLoginData] = useState<Login>(LoginDefaultData);
  const [tokens, setTokens] = useState<LoginUser>();
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  const { actions } = useErrorHandlingSlice();
  const [popupVisible, setPopupVisible] = React.useState<boolean>(false);
  const [resetPasswordPage, setResetPasswordPage] = useState<number>(1);
  const [coachRes, setCoachRes] = useState<CoachRegistration>();
  const { getAllLookupData, lookUps, loading } = useCommonLookUpDatas();
  const [firstPasswordChange, setFirstPasswordChange] =
    useState<PasswordResetModel>({
      currentPassword: '',
      newPassword: '',
      ipAddress: null,
    });
  const handleHover = itemID => {
    setIsHovered(prevState => ({
      ...prevState,
      [itemID]: !prevState[itemID],
    }));
  };
  const handleTryHover = itemID => {
    setTryIsHovered(prevState => ({
      ...prevState,
      [itemID]: !prevState[itemID],
    }));
  };
  const handleShow = itemID => {
    if (itemID === 1) {
      navigate('/registration');
    }
    if (itemID === 2) {
      navigate('/coach-registration');
    }
    if (itemID === 3) {
      navigate('/rider-registration-page');
    }
    if (itemID === 4) {
      navigate('/associate-membership');
    }
    setShowFirstFooter(prevState => {
      if (itemID === 5) {
        return !prevState;
      }
      return prevState;
    });
  };
  const handleTryItClick = tryItId => {
    if (tryItId === 1) {
      navigate('/try-it-registration');
    }
    if (tryItId === 2) {
      navigate('/coach-registration/try-it');
    }
    if (tryItId === 3) {
      navigate('/tryit-rider-registration-page');
    }
    if (tryItId === 4) {
      navigate('/alumni-try-it-registration');
    }
    if (tryItId === 5) {
      navigate('/tryit-ewd-membership-form');
    }
  };
  const userRole = Cookies.get('userRole');
  const removeCookies = () => {
    Cookies.remove('payload');
    Cookies.remove('previousEnrolData');
    Cookies.remove('insurancePayload');
    Cookies.remove('paymentCancel');
    Cookies.remove('TeamNewValue');
    Cookies.remove('teamDropdownData');
    Cookies.remove('issuranceRadioButtonpayload');
    Cookies.remove('coachDropdownData');
    Cookies.remove('coachId');
    Cookies.remove('sifID');
    Cookies.remove('joinedDate');
    Cookies.remove('response');
    Cookies.remove('riderId');
    Cookies.remove('newRiderId');
    Cookies.remove('JoinedDate');
    Cookies.remove('coachLoginRes');
    Cookies.remove('emailid');
    Cookies.remove('addInsurance');
    Cookies.remove('payloadRadiovalues');
    Cookies.remove('OtherMedicalConditionData');
    Cookies.remove('isDiagnosis');
    Cookies.remove('diaganosisPayload');
    Cookies.remove('responseId');
    Cookies.remove('SifCreated');
    Cookies.remove('diagnosisFormUploadPayload');
    Cookies.remove('showId');
  };

  useEffect(() => {
    if (userRole !== 'ADMIN') {
      Cookies.remove('accessToken');
      Cookies.remove('userId');
      Cookies.remove('userName');
      Cookies.remove('userRole');
    }
    dispatch(PageActions.setPageLoader(false));
    removeCookies();
  }, []);
  function loginRedirection(response) {
    if (
      response?.id !== '0' &&
      ((response?.role === 'RIDER' && response.riderCategory !== 'Alumni') ||
        response?.role === 'EWD')
    ) {
      dispatch(
        actions.dispatchToast({
          errorTxt: 'Login was successful',
          show: true,
          errorType: 'success',
        }),
      );
      navigate('/rider-landing-page/dashboard');
    }
    // else if (response?.role === 'COACH') {
    //   await getCoachdetails(response.id?.toString() ?? '');
    //   const coachdDetailCookie = Cookies.get('coachLoginRes');
    // dispatch(
    //   actions.dispatchToast({
    //     errorTxt: 'Login was successful',
    //     show: true,
    //     errorType: 'success',
    //   }),
    // );
    //   if (coachdDetailCookie) {
    //     const parsed = JSON.parse(coachdDetailCookie);
    //     if (
    //       parsed?.certificateOfInsurancePath === null &&
    //       parsed.hasPaidForInsurance === false
    //     ) {
    //       navigate('/rider-landing-page/coach-insurance-application');
    //     } else {
    //       navigate('/rider-landing-page/coach-dashboard');
    //     }
    //   } else {
    //     navigate('/rider-landing-page/coach-dashboard');
    //   }
    // }
    else if (response.role === 'ADMIN') {
      navigate('/admin/teams');
    } else {
      dispatch(
        actions.dispatchToast({
          errorTxt: response.description,
          show: true,
          errorType: 'error',
        }),
      );
    }
  }

  const handleLoginUser = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(authLogin(loginData))
      .then(res => {
        if (res && !isApiError(res)) {
          setTokens(res);
          Cookies.set('userId', res.id);
          Cookies.set('userName', res.name);
          Cookies.set('userRole', res.role);
          Cookies.set('accessToken', res.accessToken);
          navigate('/login/new-password');
          dispatch(PageActions.setIsAccessToken(res?.accessToken ?? undefined));
          if (res.isPasswordChangeRequired === false) {
            loginRedirection(res);
          } else {
            setPopupVisible(true);
            setResetPasswordPage(2);
          }
        }
        dispatch(PageActions.setPageLoader(false));
      })
      .catch(error => {
        dispatch(PageActions.setPageLoader(false));
        dispatch(
          actions.dispatchToast({
            errorTxt: 'Incorrect username or password',
            show: true,
            errorType: 'error',
          }),
        );
      });
  };

  const handleForgotPassword = e => {
    setPopupVisible(true);
    setResetPasswordPage(1);
  };
  const handleResetPasswordPopupClose = () => {
    setPopupVisible(false);
  };
  const handleSubmitPassword = e => {
    handleRequest(
      authChangePassword(firstPasswordChange)
        .then(res => {
          if (res) {
            setPopupVisible?.(false);
            dispatch(
              actions.dispatchToast({
                errorTxt: res,
                show: true,
                errorType: 'success',
              }),
            );
            loginRedirection(tokens);
          }
        })
        .catch(error => {
          dispatch(
            actions.dispatchToast({
              errorTxt: 'Old Password is incorrect',
              show: true,
              errorType: 'error',
            }),
          );
        }),
    );
  };

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      if (video) {
        video.play().catch(error => {});
      }
    };

    const handleInteraction = () => {
      document.removeEventListener('touchstart', handleInteraction);
      document.removeEventListener('click', handleInteraction);
      playVideo();
    };

    document.addEventListener('touchstart', handleInteraction, { once: true });
    document.addEventListener('click', handleInteraction, { once: true });

    playVideo();
  }, []);
  useEffect(() => {
    getAllLookupData('SEASON');
  }, []);

  useEffect(() => {
    if (lookUps) {
      const seasonValues = lookUps?.find(
        item => item.type === 'SEASON',
      )?.values;
      const isAdminLiveFiltered = seasonValues?.find(item => item?.isAdminLive);
      Cookies.set('isAdminLiveFiltered', isAdminLiveFiltered?.name);
    }
  }, [lookUps]);
  return (
    <>
      {pageLoader || loading ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper>
          {/* <video
            ref={videoRef}
            width="100%"
            height="100vh"
            autoPlay
            loop
            muted
            className="lazyload"
            playsInline
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: 0,
            }}
          >
            <source src={bgVideo} type="video/mp4" />
          </video> */}
          <ShowyedaLogo className="logo" />
          <ContentWrapper className="login-ContentWrapper">
            <LeftWrapper className="login-LeftWrapper">
              <LoginCard
                onClickLogin={handleLoginUser}
                loginData={loginData}
                setLoginData={setLoginData}
                setShowFirstFooter={setShowFirstFooter}
                onForgotPassword={handleForgotPassword}
                heading="Showyeda Login"
              />
            </LeftWrapper>
            <CenterWrapper className="CenterWrapper">
              <div className="find-team-img">
                <img src={yedaFullform} alt="Find-team" />
              </div>
              <div className="showyeda-img">
                <img src={showyedaImg} alt="Show-yeda" />
              </div>
              <div className="new-register-text">
                <div>
                  <img src={arrow} alt="arrow" />
                </div>
                <p>
                  New to YEDA, please select one of the registration options
                  below
                </p>
              </div>
            </CenterWrapper>
            <RightWrapper className="RightWrapper">
              <img src={horseImg} alt="horse" />
            </RightWrapper>
          </ContentWrapper>
          <FooterWrapper
            className={`FooterWrapper ${showFirstFooter ? 'focused' : ''}`}
          >
            <div className="new-register-text">
              <div>
                <img src={arrow} alt="arrow" />
              </div>
              <p>
                New to YEDA, please select one of the <br />
                registration options above
              </p>
            </div>
            {showFirstFooter && (
              <FirstFooterWrapper className="FirstFooterWrapper">
                {tryItData.map(value => (
                  <ToggleWrapper
                    key={value.tryId}
                    className="sub-footer icon-container"
                    onMouseEnter={() => handleTryHover(value.tryId)}
                    onMouseLeave={() => handleTryHover(value.tryId)}
                    onClick={() => handleTryItClick(value.tryId)}
                  >
                    <div className="content">
                      <div
                        className={`tryIcon ${
                          tryIsHovered[value.tryId] ? 'tryHovered' : ''
                        }`}
                      >
                        <img src={smallEllipse} alt="ellipse" />
                      </div>
                      <span
                        className={`text-try ${
                          tryIsHovered[value.tryId] ? 'text-try-hovered' : ''
                        }`}
                      >
                        {value.name}
                      </span>
                    </div>
                  </ToggleWrapper>
                ))}
              </FirstFooterWrapper>
            )}
            <SecondFooterWrapper className="SecondFooterWrapper">
              {data.map(value =>
                showFirstFooter ? (
                  <ToggleWrapper
                    key={value.id}
                    onClick={() => handleShow(value.id)}
                    className="icon-block"
                  >
                    <div className="content">
                      <div
                        className={`icon ${
                          isHovered[value.id] ? 'hovered' : ''
                        }`}
                      >
                        <img
                          className="circle-img"
                          src={bigEllipse}
                          alt="ellipse"
                        />
                      </div>
                      <span
                        className={`text ${
                          isHovered[value.id] ? 'text-hovered' : ''
                        }`}
                      >
                        {value.name}
                      </span>
                    </div>
                  </ToggleWrapper>
                ) : (
                  <ToggleWrapper
                    key={value.id}
                    className="icon-block icon-container"
                    onMouseEnter={() => handleHover(value.id)}
                    onMouseLeave={() => handleHover(value.id)}
                    onClick={() => handleShow(value.id)}
                  >
                    <div className="content">
                      <div
                        className={`icon ${
                          isHovered[value.id] ? `hovered-${value.id}` : ''
                        }`}
                      >
                        <img src={bigEllipse} alt="ellipse" />
                      </div>
                      <span
                        className={`text ${
                          isHovered[value.id] ? 'text-hovered' : ''
                        }`}
                      >
                        {value.name}
                      </span>
                    </div>
                  </ToggleWrapper>
                ),
              )}
            </SecondFooterWrapper>
          </FooterWrapper>
          <PasswordResetEmail
            show={popupVisible}
            setShow={setPopupVisible}
            page={resetPasswordPage}
            setPage={setResetPasswordPage}
            onSubmitPassword={handleSubmitPassword}
            firstPasswordChange={firstPasswordChange}
            setFirstPasswordChange={setFirstPasswordChange}
          />
        </MainWrapper>
      )}
    </>
  );
};

const MainWrapper = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)),
    url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
  .para-one {
    color: white;
    font-size: 12px;
    z-index: 2;
  }
  .logo {
    padding: 20px 20px 0px;
    width: 10%;
    position: absolute;
    left: 0;
    top: 0;

    img {
      width: 100%;
      height: auto;
      min-width: 50px;
    }
  }

  @media (min-width: 1720px) and (max-width: 4000px) {
    .CenterWrapper {
      .new-register-text {
        top: 190px !important;
        p {
          font-size: 20px;
          text-align: left;
        }
      }
    }
    .FooterWrapper {
      bottom: 7%;
      right: 7%;
      .new-register-text {
        display: none;
      }
    }
    .logo {
      width: 13%;
    }
    .FirstFooterWrapper {
      gap: 40px;
      padding: 20px 0px;
      .text-try {
        font-size: 19px !important;
      }
      img {
        width: 23px;
        height: 23px;
      }
    }
    .SecondFooterWrapper {
      gap: 20px;
      img {
        width: 85px;
      }
      .text {
        font-size: 26px !important;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .CenterWrapper {
      .new-register-text {
        top: 170px !important;
        p {
          font-size: 18px;
          text-align: left;
        }
      }
    }
    .FooterWrapper {
      bottom: 7%;
      right: 7%;
      .new-register-text {
        display: none;
      }
    }
    .logo {
      width: 13%;
    }
    .FirstFooterWrapper {
      gap: 40px;
      padding: 15px 0px;
      .text-try {
        font-size: 17px !important;
      }
      img {
        width: 21px;
        height: 21px;
      }
    }
    .SecondFooterWrapper {
      gap: 15px;
      img {
        width: 75px;
      }
      .text {
        font-size: 24px !important;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .CenterWrapper {
      .new-register-text {
        p {
          font-size: 16px;
          text-align: left;
        }
      }
    }
    .FooterWrapper {
      bottom: 5%;
      right: 7%;
      .new-register-text {
        display: none;
      }
    }
    .logo {
      width: 13%;
    }
    .SecondFooterWrapper {
      gap: 10px;
      img {
        width: 65px;
      }
      .text {
        font-size: 20px !important;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .CenterWrapper {
      .new-register-text {
        top: 120px !important;
        p {
          font-size: 14px;
          text-align: left;
        }
      }
    }
    .FooterWrapper {
      bottom: 7%;
      right: 4%;
      .new-register-text {
        display: none;
      }
    }
    .logo {
      width: 13%;
    }
    .SecondFooterWrapper {
      gap: 10px;
      img {
        width: 60px;
      }
      .text {
        font-size: 19px !important;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .CenterWrapper {
      .new-register-text {
        gap: 5px;
        top: 100px !important;
        p {
          font-size: 14px;
          text-align: left;
        }
      }
    }
    .FooterWrapper {
      bottom: 6%;
      right: 4%;
      .new-register-text {
        display: none;
      }
    }

    .logo {
      width: 13%;
    }

    .SecondFooterWrapper {
      gap: 12px;
      img {
        width: 55px;
      }
      .text {
        font-size: 17px !important;
      }
    }
    .find-team-img {
      top: -120px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .CenterWrapper {
      .new-register-text {
        gap: 2px;
        top: 90px !important;
        p {
        }
      }
    }
    .FooterWrapper {
      bottom: 5%;
      right: 4%;
      .new-register-text {
        display: none;
      }

      .SecondFooterWrapper {
        gap: 10px;
        .text {
          font-size: 16px !important;
        }
        img {
          width: 50px;
        }
      }
    }

    .logo {
      width: 18%;
    }
    .find-team-img {
      top: -120px !important;
      width: 98%;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .find-team-img {
      top: -170px;
      left: unset;
      right: -30px;
    }
    .login-LeftWrapper {
      width: 65%;
    }
    .FooterWrapper {
      position: relative !important;
      right: 0%;
      padding-top: 60px;
      display: flex;
      flex-direction: column-reverse;
      .new-register-text {
        p {
          font-size: 15px;
          text-align: left;
        }
      }
      .FirstFooterWrapper,
      .SecondFooterWrapper {
        display: block;
        text-align: center;
        padding: 10px;
        .icon {
          display: none;
        }
        .text {
          font-size: 16px !important;
        }
        .text-try {
          font-size: 11px !important;
        }
        .hovered-5 {
          left: 11%;
        }
        .icon-block,
        .icon-container {
          padding: 0 10px 10px 10px;
          display: inline-block;
          .tryIcon {
            display: none;
          }
        }
      }
    }
    .logo {
      width: 27%;
    }
    .CenterWrapper {
      position: absolute;
      top: 0;
      display: none;
      .find-team-img {
        top: 0;
        right: 0;
        left: auto;
        width: 60px;
      }
      .showyeda-img {
        top: 20px;
        right: 0;
        width: 50px;
      }
    }
    .RightWrapper {
      width: 100px !important;
      top: 0;
      position: absolute;
      right: 0;
      display: none;
    }
    .login-LeftWrapper {
      width: 100%;
      padding: 0;
      & > div {
        max-width: 350px;
        margin: 0 auto;
        padding: 100px 20px 66px 20px !important;
      }
      .login-MainWrapper {
        padding: 100px 20px 0 20px !important;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .FooterWrapper {
      position: relative !important;
      padding-top: 60px;
      right: 0%;
      display: flex;
      flex-direction: column-reverse;
      .login-ContentWrapper {
        flex-direction: column-reverse;
        .login-LeftWrapper {
          width: 100% !important;
          padding: 0 !important;
          .login-MainWrapper {
            padding: 0 20px;
          }
        }
      }
      .new-register-text {
        p {
          font-size: 15px;
          text-align: left;
        }
      }
      .FirstFooterWrapper,
      .SecondFooterWrapper {
        display: block;
        text-align: center;
        padding: 10px;
        .icon {
          display: none;
        }
        .text {
          font-size: 15px !important;
        }
        .text-try {
          font-size: 11px !important;
        }
        .icon-block,
        .icon-container {
          padding: 0 10px 10px 10px;
          display: inline-block;
          .tryIcon {
            display: none;
          }
        }
      }
    }
    .CenterWrapper {
      position: absolute;
      top: 0;
      display: none;
      .find-team-img {
        top: 0;
        right: 0;
        left: auto;
        width: 60px;
      }
      .showyeda-img {
        top: 20px;
        right: 0;
        width: 50px;
      }
    }
    .RightWrapper {
      width: 100px !important;
      top: 0;
      position: absolute;
      right: 0;
      display: none;
    }
    .login-LeftWrapper {
      width: 100%;
      padding: 0;
      & > div {
        max-width: 330px;
        margin: 0 auto;
        padding: 180px 20px 66px 20px !important;
      }
      .login-MainWrapper {
        padding: 100px 20px 0 20px !important;
      }
    }
    .logo {
      img {
        min-width: 70px;
      }
    }
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .FooterWrapper {
      position: relative !important;
      padding-top: 60px;
      right: 0%;
      display: flex;
      flex-direction: column-reverse;
      .login-ContentWrapper {
        flex-direction: column-reverse;
        .login-LeftWrapper {
          width: 100% !important;
          padding: 0 !important;
          .login-MainWrapper {
            padding: 0 20px;
          }
        }
      }
      .FirstFooterWrapper,
      .SecondFooterWrapper {
        display: block;
        text-align: center;
        padding: 10px;
        .icon {
          display: none;
        }
        .text {
          font-size: 12px !important;
        }
        .text-try {
          font-size: 11px !important;
        }
        .icon-block,
        .icon-container {
          padding: 0 10px 10px 10px;
          display: inline-block;
          .tryIcon {
            display: none;
          }
        }
      }
    }
    .CenterWrapper {
      position: absolute;
      top: 0;
      display: none;
      .find-team-img {
        top: 0;
        right: 0;
        left: auto;
        width: 60px;
      }
      .showyeda-img {
        top: 20px;
        right: 0;
        width: 50px;
      }
    }
    .RightWrapper {
      width: 100px !important;
      top: 0;
      position: absolute;
      right: 0;
      display: none;
    }
    .login-LeftWrapper {
      width: 100%;
      padding: 0;
      & > div {
        max-width: 330px;
        margin: 0 auto;
        padding: 180px 20px 66px 20px !important;
      }
      .login-MainWrapper {
        padding: 100px 20px 0 20px !important;
      }
    }
    .logo {
      img {
        min-width: 70px;
      }
    }
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 10px;
`;
const LeftWrapper = styled.div`
  height: 100%;
  width: 35%;
  display: flex;
  align-items: center;
  padding: 40px 0px 40px 0px;
  .k-card-header {
    border-color: transparent !important;
  }
`;
const CenterWrapper = styled.div`
  width: 40%;
  height: auto;
  position: relative;
  p {
    font: normal normal normal 90px/108px Sandbrush Demo;
    color: #ffffff;
    font-size: 13px;
    margin: 0px;
  }

  .find-team-img {
    position: absolute;
    top: -150px;
    right: 0px;
    width: 90%;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
  .showyeda-img {
    position: absolute;
    top: -80px;
    right: 0;
    width: 35%;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
  .new-register-text {
    position: absolute;
    top: 150px;
    right: 0px;
    display: flex;
    align-items: baseline;
    justify-content: end;
    gap: 10px;
    word-wrap: break-word;
    p {
      font-family: 'Inconsolata' !important;
      font-size: 13px;
      color: #ffe45b;
      line-height: 2;
      text-align: left;
    }
  }
`;
const RightWrapper = styled.div`
  width: 25% !important;
  padding: 0 0 100px 0;
  img {
    width: 100%;
    max-width: 380px;
    height: auto;
  }
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 0 40px 0;
  z-index: 0;
`;
const FooterWrapper = styled.div`
  position: absolute;
  bottom: 10%;
  right: 10%;
  padding: 0 0 0px 0;
  &.focused {
    .SecondFooterWrapper {
      .icon-block {
        &:not(:nth-last-child(1)) {
          opacity: 0.5;
        }
      }
    }
  }
  .new-register-text {
    display: block;
    text-align: center;
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 10px;
    word-wrap: break-word;
    p {
      font-family: 'Inconsolata' !important;
      font-size: 16px;
      color: #ffe45b;
    }
  }
`;
const FirstFooterWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px 0;
  gap: 30px;
  img {
    width: 17px;
    height: 17px;
    // opacity: 0.4;
  }
  .tryIcon {
    position: relative;
    transition: left 0.2s;
    left: -15%;
    top: 0px;
  }
  .tryHovered {
    left: 22%;
  }
  .text-try {
    color: #b7b7b7;
    font-size: 14px !important;
    font-family: 'Inconsolata';
    position: relative;
    transition: right 0.5s;
    right: 0%;
  }
  .text-try-hovered {
    right: 5%;
    color: #ffffff;
  }
`;
const SecondFooterWrapper = styled.div`
  display: flex;
  justify-content: end;

  img {
    width: 70px;
    max-width: 70px;
    height: auto;
  }
  .text {
    font-size: 22px !important;
    color: #b7b7b7;
    font-family: 'Inconsolata';
    position: relative;
    transition: right 0.5s;
    right: -5%;
  }
  .text-hovered {
    right: 0%;
    color: #ffffff;
  }

  .icon {
    position: relative;
    transition: left 0.2s;
    left: 15%;
  }

  .hovered-1 {
    left: 29%;
  }
  .hovered-2 {
    left: 29%;
  }
  .hovered-3 {
    left: 29%;
  }
  .hovered-4 {
    left: 24%;
  }
  .hovered-5 {
    left: 27%;
  }
`;
const ToggleWrapper = styled.div`
  /* padding-right: 50px; */

  .content {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;
