import { BackNextButton } from 'app/components/BackNextButton';
import Loader from 'app/components/Loader';
import { TextField } from 'app/components/TextField';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import EWDRiderInformation from './components/EWDRiderInformation';
import EWDRiderTable from './components/EWDRiderTable';
import SHdropdown, { DropDownModel } from 'app/components/SHdropdown';
import useApi from 'services/mavapi/useApi';
import {
  EWD,
  EwdService,
  GeneralEWDs,
  GeneralLookup,
  GeneralLookupWrapper,
  GeneralRider,
  LookupService,
  RiderService,
} from 'services/openapi';

import SearchButton from 'app/components/SearchButton';
import SimpleReactValidator from 'simple-react-validator';
import CircularLoader from 'app/components/CircularLoader';
import { useManagePageSlice } from 'app/pages/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageLoader } from 'app/pages/slice/selectors';
import Cookies from 'js-cookie';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import { selectPhotoUpload } from 'app/pages/RiderRegistrationPage/slice/selectors';
import { useEwdRegistrationStateSlice } from '../../slice';
import isApiError from 'utils/isApiError';
import { selectDiagonosissUpload } from '../../slice/selectors';
import { useErrorHandlingSlice } from 'app/error/slice';
import { periviousValue } from 'app/pages/RiderRegistrationPage/components/staticData';
import { Button } from '@progress/kendo-react-buttons';
const defaultvalue: EWD = {
  id: '',
  isReturningRider: true,
  firstName: '',
  lastName: '',
  expectedGraduationYear: new Date().getFullYear() + 1,
  dateOfBirth: '',
  height: 0,
  weight: 0,
  primaryPhone: '',
  email: '',
  street: '',
  city: '',
  zip: '',
  backNumber: '',
  state: {
    id: '',
    name: '',
    children: null,
  },
  parent: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: {
      id: '',
      name: '',
      children: null,
    },
    zip: '',
  },
  divisionClassOne: {
    id: '',
    name: '',
    children: null,
  },
  divisionClassTwo: {
    id: '',
    name: '',
    children: null,
  },
  isTrial: false,
  professionalAffiliationMap: [],
  usePhotoForPromotion: false,
  ewdIdOld: null,
  emergencyContactName: '',
  emergencyContactPhone: '',
};
const defaultValue = {
  formData: {
    file: undefined,
  },
};

const EWDReturningMember = () => {
  const searchedBy: DropDownModel[] = [
    // { id: 1, text: 'Team/Category/Division' },
    { id: 2, text: 'Back Number' },
    { id: 3, text: 'Name' },
  ];
  const { error: apiError, handleRequest } = useApi();
  const { actions } = useErrorHandlingSlice();

  const { ewdCreateEwd, ewdUploadEwdPhoto, ewdUploadDiagnosisData } =
    EwdService;
  const { lookupGetAllLookups } = LookupService;
  const { riderGetBackNumberList } = RiderService;
  const [riderTableData, setRidertableData] = useState<
    GeneralRider[] | undefined
  >();
  const { riderSearchRiders, riderGetRider } = RiderService;
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [teamIdPayload, setTeamIdPayload] = useState<GeneralLookup>();
  const [stateData, setStateData] = useState<GeneralLookup[]>();
  const [medicalConditionsData, setMedicalConditionsData] = useState<
    GeneralLookup[]
  >([]);
  const [riderData, setRiderData] = useState<EWD>(defaultvalue);

  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showDiagonosis, setShowDiagonosis] = useState(false);
  const [lookUp, setLookUp] = useState<GeneralLookupWrapper[]>();
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [riderId, setRiderId] = useState<string | undefined>();
  const [childData, setChildData] = useState<GeneralLookup[]>();
  const [formValue, setFormValue] = React.useState<number>(0);
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  const { ewdCreateEwdDiagnosis } = EwdService;
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState();
  const [submitResponseId, setSubmitResponseId] = useState<
    string | undefined
  >();
  const [filteredSeasonLookupData, setFilteredSeasonLookupData] = useState<
    GeneralLookup[]
  >([]);
  const [categoryCheck, setCategoryCheck] = useState<
    GeneralLookup[] | undefined
  >();
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  const [seasonDivison, setSeasonDivision] = useState<
    (string[] | undefined)[] | undefined
  >([]);
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultValue);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const photoUploadDataRedux = useSelector(selectPhotoUpload);
  const { actions: manageEwdActions } = useEwdRegistrationStateSlice();
  const [backNumberData, setBackNumberData] = React.useState<
    string[] | undefined
  >([]);
  const [otherData, setOtherData] = useState<string>('');

  const handleDiagonosisFormUpload = (formData?: {
    File: Blob;
    ApplicationId: string;
  }) => {
    handleRequest(ewdUploadDiagnosisData(formData))
      .then(res => {})
      .catch(error => {});
  };
  function getBackNumberList() {
    handleRequest(riderGetBackNumberList())
      .then(data => {
        setBackNumberData(data);
      })
      .catch(() => {});
  }
  const [lookUpShow, setLookUpShow] = useState(false);
  const [backNumberShow, setBackNumberShow] = useState(true);
  const [nameShow, setNameShow] = useState(false);
  const [defaultDropDownValue, setDefaultDropDownValue] =
    useState<DropDownModel>({ id: 2, text: 'Back Number' });
  const [isName, setIsName] = useState(false);
  const selectSreachedby = e => {
    setIsChecked(true);
    setDefaultDropDownValue(e);
    setShowTable(false);
    setTeamValue(periviousValue);
    setCategoryValue(periviousValue);
    setDivisionChange(periviousValue);
    setBackNumber('');
    setNameChange('');
    if (e.id === 1) {
      setLookUpShow(true);
      setBackNumberShow(false);
      setNameShow(false);
      setIsName(false);
    } else if (e.id === 2) {
      setBackNumberShow(true);
      setLookUpShow(false);
      setNameShow(false);
      setIsName(false);
    } else {
      setNameShow(true);
      setLookUpShow(false);
      setBackNumberShow(false);
      setIsName(true);
    }
  };
  const [backNumber, setBackNumber] = useState('');
  const [nameValue, setNameChange] = useState('');
  const [seasonValue, setSeasonValue] = useState<GeneralLookup>();
  const [teamValue, setTeamValue] = useState<GeneralLookup>();
  const [categoryValue, setCategoryValue] = useState<GeneralLookup>();
  const [divisionDatas, setDivisionDatas] = useState<GeneralLookup[]>();
  const [categoryDefaultValue, setCategoryDefaultValue] =
    useState<GeneralLookup>();
  const [divisionValue, setDivisionChange] = useState<GeneralLookup>();
  const [categoryDatas, setCategoryDatas] = useState<GeneralLookup[]>();
  const [isBackNumberCheck, setIsBackNumberCheck] = useState<boolean | null>(
    null,
  );
  const [isphotoforPromotion, setIsphotoforPromotion] = useState<
    boolean | null
  >(null);
  const [heightWeight, setHeightWeight] = useState<heightWeightModel>();
  const [diagonosisUploadPayload, setDiagonosisUploadPayload] =
    useState<Blob>();
  const [diagonosisFileAvailability, setDiagonosisFileAvailability] =
    React.useState<boolean>(true);
  const [diagonosisUploadFile, setDiagonosisUploadFile] =
    useState<File | null>();
  const diagonosisUploadDataRedux = useSelector(selectDiagonosissUpload);

  const seasonChange = e => {
    setSeasonValue(e);
    setCategoryDatas(e.children);
    setTeamValue({
      id: '',
      name: '',
      code: '',
      children: null,
      otherName: '',
      isAdminLive: null,
    });
    setCategoryValue(periviousValue);
    setDivisionChange(periviousValue);
    setBackNumber('');
    setNameChange('');
  };
  const teamChangeData = e => {
    setTeamValue(e);
  };
  const onDivisionChange = e => {
    setDivisionChange(e);
  };
  const onCategoryChange = e => {
    setCategoryValue(e);
    setCategoryDefaultValue(e);
    setDivisionDatas(e.children);
  };
  const [categoryDefaultDatas, setCategoryDefaultDatas] =
    useState<GeneralLookup[]>();
  const [seasonId, setSeasonId] = useState<string>();
  const handleGetLookups = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(
      lookupGetAllLookups(
        'DIVISION,TEAM,CATEGORY,STATE,PROFESSIONALAFFILIATIONS,MEDICALCONDITIONS,PAYER_TYPES,SEASON,DIVISION,ACTIVETEAM',
      ),
    )
      .then(res => {
        setLookUp(res);
        res
          ?.find(obj => {
            return obj.type === 'SEASON';
          })
          ?.values?.find(item => {
            if (item.isAdminLive === true) {
              return setSeasonId(item?.id!);
            }
          });
        res
          ?.find(obj => {
            return obj.type === 'SEASON';
          })
          ?.values?.find(item => {
            if (item.isAdminLive === true) {
              return setCategoryDefaultDatas(item?.children!);
            }
          });
        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Rider') {
            return item ?? {};
          }
        });
        res?.filter(item => {
          if (item.type === 'CATEGORY') {
            return item.values?.map(data => {
              if (data.name === 'EWD') {
                return setChildData(data.children!);
              }
            });
          }
        });
        setPayerTypes(payerTypeObj);
        const categoryLookup = res?.find(obj => obj.type === 'CATEGORY');
        if (categoryLookup?.values) {
          let varState = res?.filter(item => {
            if (item?.type === 'STATE') {
              return item.values;
            }
          });

          let varMedicalConditions = res?.filter(item => {
            if (item?.type === 'MEDICALCONDITIONS') {
              return item.values?.map(data => {
                return data.name;
              });
            }
          });
          let seasonData =
            res?.find(obj => {
              return obj.type === 'SEASON';
            })?.values ?? [];
          let varDivison = res?.filter(item => {
            if (item?.type === 'DIVISION') {
              return item.values;
            }
          });
          let filteredDIvison = varDivison?.map(item =>
            item.values?.map(data => data.name),
          );
          const filteredSeasonData = seasonData.filter(season => {
            const currentYear = new Date().getFullYear();
            const seasonYear = parseInt(season.name.split('-')[1]);
            return seasonYear <= currentYear + 1;
          });
          setStateData(varState?.[0].values!);
          setMedicalConditionsData(varMedicalConditions?.[0].values!);
          setFilteredSeasonLookupData(filteredSeasonData);
          setSeasonDivision(filteredDIvison);
        }
        dispatch(PageActions.setPageLoader(false));
      })
      .catch(error => {
        dispatch(PageActions.setPageLoader(false));
      });
  };

  const handleGetRiderDetails = (id: string) => {
    const seasonIdPayload = seasonValue?.id! ? seasonValue?.id! : seasonId!;
    handleRequest(
      riderGetRider(id, seasonIdPayload)
        .then(res => {
          if (res) {
            const filteredPrimaryPhone = res?.primaryPhone
              ? res?.primaryPhone.replaceAll('-', '')
              : '';
            const filteredParentPhone = res?.parent?.phone
              ? res?.parent.phone.replaceAll('-', '')
              : '';
            setRiderData({
              ...riderData,
              id: null,
              ewdIdOld: parseInt(res?.id!),
              isReturningRider: true,
              backNumber: res.backNumber ?? '',
              firstName: res.firstName ?? '',
              lastName: res.lastName ?? '',
              expectedGraduationYear: res.expectedGraduationYear ?? 0,
              dateOfBirth: res.dateOfBirth ?? '',
              height: res.height ?? 0,
              weight: res.weight ?? 0,
              primaryPhone: filteredPrimaryPhone,
              email: res.email ?? '',
              street: res.street ?? '',
              city: res.city ?? '',
              zip: res.zip ?? '',
              state: res.state ?? {
                id: '',
                name: '',
                children: null,
              },
              team:
                res.team ??
                ({
                  id: '',
                  name: '',
                  children: null,
                } as GeneralLookup),
              emergencyContactName: res.emergencyContactName,
              emergencyContactPhone: res.emergencyContactPhone,
              parent: {
                ...riderData.parent,
                firstName: res?.parent?.firstName?.replaceAll(' ', ''),
                lastName: res.parent?.lastName,
                city: res.parent?.city,
                zip: res.parent?.zip,
                email: res.parent?.email,
                phone: filteredParentPhone,
                street: res.parent?.street,
                state: res.parent?.state,
              },
            });
            setHeightWeight({
              feet: Math.floor(res?.height!)
                ? Math.floor(res?.height!)
                : undefined,

              inches: Math.round((res?.height! - Math.floor(res?.height!)) * 12)
                ? Math.round((res?.height! - Math.floor(res?.height!)) * 12)
                : undefined,

              weight: res.weight ? res.weight : undefined,
            });
          }
          setShowInfo(!showInfo);
        })
        .catch(error => {}),
    );
  };

  const handleSearch = (
    seasonId: string,
    backNumber: string,
    riderName: string,
    categoryId: string,
    divisionId: string,
    teamId: string,
  ) => {
    setShowLoader(true);
    handleRequest(
      riderSearchRiders(
        seasonId,
        backNumber,
        riderName,
        categoryId,
        divisionId,
        teamId,
      )
        .then(res => {
          setRidertableData(res);
          setShowLoader(false);
          setShowTable(true);
          setCheckedMap(null);
          setIsChecked(true);
        })
        .catch(error => {}),
    );
  };
  const [checkedMap, setCheckedMap] = React.useState(null);
  const handleCheckboxClick = riderId => {
    if (isChecked === riderId) {
      setCheckedMap(null);
    } else {
      setCheckedMap(riderId);
      setIsChecked(false);
    }
    setRiderId(riderId);
  };
  const handleNxtBack = () => {
    setShowTable(false);
    setCheckedMap(null);
  };
  const handleNxt = () => {
    setRiderData(defaultvalue);
    handleGetRiderDetails?.(riderId!);
  };
  const handlePersonalNxtBack = () => {
    navigate('/rider-registration-page');
    Cookies.remove('payload');
    Cookies.remove('diaganosisPayload');
  };
  const handleDivionsNxt = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(ewdCreateEwd(riderData))
      .then(res => {
        if (res && !isApiError(res)) {
          Cookies.set('responseId', JSON.stringify(res));
          if (riderData.usePhotoForPromotion === false) {
            handlePhotoUpload(res.toString(), photoUploadPayload.formData);
          }
          dispatch(
            actions.dispatchToast({
              errorTxt: 'EWD rider registered successful',
              show: true,
              errorType: 'success',
            }),
          );
          setSubmitResponseId(res);
          setShowDiagonosis(!showDiagonosis);
          setFormValue(3);
          Cookies.set('isDiagnosis', true);
          dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
          dispatch(PageActions.setPageLoader(false));
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
        }
      })
      .catch(error => {
        if (error) {
          dispatch(
            actions.dispatchToast({
              errorTxt: error.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
        }
      });
  };
  const handlePhotoUpload = (
    riderId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(ewdUploadEwdPhoto(riderId, formData))
      .then(res => {})
      .catch(error => {});
  };

  const defaultData: GeneralEWDs = {
    riderId: submitResponseId,
    physicianName: '',
    physicianLicense: '',
    physicianPracticeLocation: '',
    medicalConditions: [],
  };
  const [diaganosisData, setDiaganosisData] =
    useState<GeneralEWDs>(defaultData);

  const handleEligibilityConditionsNext = () => {
    dispatch(PageActions.setPageLoader(true));
    const ewdRegisterCookie = Cookies.get('responseId');
    const diagnosisPayload = {
      ...diaganosisData,
      riderId: ewdRegisterCookie
        ? JSON.parse(ewdRegisterCookie)
        : submitResponseId,
    };
    handleRequest(ewdCreateEwdDiagnosis(diagnosisPayload))
      .then(res => {
        const uploadPayload = {
          File: diagonosisUploadPayload as Blob,
          ApplicationId: res,
        };
        Cookies.set(
          'diagnosisFormUploadPayload',
          JSON.stringify(uploadPayload),
        );
        if (res && !isApiError(res)) {
          if (cancelPaymentCookie) {
            Cookies.remove('paymentCancel');
          }
          dispatch(
            manageEwdActions.setDiagonosisPayloadUpload(
              diagonosisUploadPayload,
            ),
          );
          handleDiagonosisFormUpload(uploadPayload);
          const newMemberDataString = JSON.stringify(riderData);
          const diaganosisDataString = JSON.stringify(diaganosisData);
          Cookies.set('OtherMedicalConditionData', otherData);
          Cookies.set('payload', newMemberDataString);
          Cookies.set('diaganosisPayload', diaganosisDataString);
          dispatch(PageActions.setPageLoader(false));
          navigate(
            `/payment/${payerTypes?.id}/${
              ewdRegisterCookie
                ? JSON.parse(ewdRegisterCookie)
                : submitResponseId
            }/false/false`,
          );
        } else {
          dispatch(
            actions.dispatchToast({
              errorTxt: res.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
        }
      })
      .catch(error => {
        dispatch(
          actions.dispatchToast({
            errorTxt: error.description,
            show: true,
            errorType: 'error',
          }),
        );
        dispatch(PageActions.setPageLoader(false));
      });
  };

  const handleBackNumberChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 3 && /^\d*$/.test(inputValue)) {
      setBackNumber(inputValue);
      if (inputValue === '') {
      } else {
      }
    }
    if (inputValue.length >= 3) {
      setIsBackNumberCheck(false);
    } else {
      setIsBackNumberCheck(true);
    }
  };
  useEffect(() => {
    handleGetLookups();
    getBackNumberList();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
  }, []);
  useEffect(() => {
    const returningMemberData = Cookies.get('payload');
    const newDiaganosisData = Cookies.get('diaganosisPayload');
    if (returningMemberData) {
      const returningMemberDataParse = JSON.parse(returningMemberData);
      setRiderData(returningMemberDataParse);
      setShowInfo(true);
      setShowDiagonosis(true);
      setFormValue(4);
      setIsphotoforPromotion(returningMemberDataParse.usePhotoForPromotion);
      setHeightWeight({
        feet: Math.floor(returningMemberDataParse?.height!),
        inches: Math.round(
          (returningMemberDataParse?.height! -
            Math.floor(returningMemberDataParse?.height!)) *
            12,
        ),
        weight: returningMemberDataParse.weight,
      });
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
      setDiagonosisUploadPayload(diagonosisUploadDataRedux);
      setDiagonosisUploadFile(diagonosisUploadDataRedux as File);
      const OtherMedicalConditionData = Cookies.get(
        'OtherMedicalConditionData',
      );
      setOtherData(OtherMedicalConditionData);
    }
    if (newDiaganosisData) {
      const newDiaganosisDataParse = JSON.parse(newDiaganosisData);
      setDiaganosisData(newDiaganosisDataParse);
    }
  }, [cancelPaymentCookie]);
  useEffect(() => {
    dispatch(manageEwdActions.setFormValue(formValue));
  }, [formValue]);
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper>
          {showInfo ? (
            <>
              <EWDRiderInformation
                onClickBackPersonal={handlePersonalNxtBack}
                setRiderData={setRiderData}
                riderData={riderData}
                stateData={stateData}
                childData={childData}
                handleDivionsNxt={handleDivionsNxt}
                formValue={formValue}
                setFormValue={setFormValue}
                lookUp={lookUp}
                categoryCheck={categoryCheck}
                isphotoforPromotion={isphotoforPromotion}
                setIsphotoforPromotion={setIsphotoforPromotion}
                setHeightWeight={setHeightWeight}
                heightWeight={heightWeight}
                seasonDivison={seasonDivison}
                teamIdPayload={teamIdPayload}
                setTeamIdPayload={setTeamIdPayload}
                handleEligibilityConditionsNext={
                  handleEligibilityConditionsNext
                }
                medicalConditionsData={medicalConditionsData}
                diaganosisData={diaganosisData}
                setDiaganosisData={setDiaganosisData}
                setPhotoUploadPayload={setPhotoUploadPayload}
                photoUploadPayload={photoUploadPayload}
                backNumberData={backNumberData}
                setOtherData={setOtherData}
                otherData={otherData}
                setUploadFile={setUploadFile}
                uploadFile={uploadFile}
                setDiagonosisUploadPayload={setDiagonosisUploadPayload}
                diagonosisFileAvailability={diagonosisFileAvailability}
                diagonosisUploadFile={diagonosisUploadFile}
                setDiagonosisFileAvailability={setDiagonosisFileAvailability}
                setDiagonosisUploadFile={setDiagonosisUploadFile}
                setCategoryCheck={setCategoryCheck}
              />
            </>
          ) : (
            <>
              {showLoader ? (
                <LoaderWrapper>
                  <Loader />
                  <p>Please wait while we retrieve your information</p>
                </LoaderWrapper>
              ) : (
                <>
                  <p className="Team-Heading">Returning Rider</p>{' '}
                  <p className="para-one">
                    A membership registration forms for Equestrian with
                    Disabilities must be accompanied by a Diagnosis and Adaptive
                    Equipment Form.
                  </p>
                  <p className="para-two">
                    <i>
                      Payment of $80 fee must be accompanied with this form ($10
                      of the $80 will go towards the SIF as the YEDA Back # fee)
                    </i>
                  </p>
                  <OverflowWrapper>
                    <TextFieldGrid className="TextFieldGrid">
                      <TextFieldWrapper>
                        <SHdropdown
                          className="drop-down"
                          label="Season"
                          data={filteredSeasonLookupData}
                          defaultValue={
                            seasonValue
                              ? seasonValue
                              : lookUp
                                  ?.find(obj => {
                                    return obj.type === 'SEASON';
                                  })
                                  ?.values?.find(
                                    item => item.isAdminLive === true,
                                  ) ?? undefined
                          }
                          onDataChange={event => {
                            seasonChange(event);
                          }}
                        />
                      </TextFieldWrapper>
                      <TextFieldWrapper>
                        <SHdropdown
                          className="drop-down"
                          label="Searched by"
                          genericData={searchedBy}
                          genericDefaultValue={defaultDropDownValue}
                          onGeneralDataChange={event => {
                            selectSreachedby(event);
                          }}
                        />
                      </TextFieldWrapper>
                      {lookUpShow && (
                        <>
                          <TextFieldWrapper>
                            <SHdropdown
                              className="drop-down"
                              label="Team"
                              data={
                                lookUp?.find(obj => {
                                  return obj.type === 'TEAM';
                                })?.values ?? []
                              }
                              onDataChange={event => {
                                teamChangeData(event);
                              }}
                              defaultValue={teamValue}
                            />
                          </TextFieldWrapper>
                          <TextFieldWrapper>
                            <SHdropdown
                              className="drop-down"
                              label="Category"
                              data={
                                categoryDatas
                                  ? categoryDatas
                                  : categoryDefaultDatas
                              }
                              onDataChange={event => {
                                onCategoryChange(event);
                              }}
                              defaultValue={categoryValue}
                            />
                          </TextFieldWrapper>
                          <TextFieldWrapper>
                            <SHdropdown
                              className="drop-down"
                              label="Division"
                              data={divisionDatas}
                              onDataChange={event => {
                                onDivisionChange(event);
                              }}
                              defaultValue={divisionValue}
                            />
                          </TextFieldWrapper>
                        </>
                      )}
                      {backNumberShow && (
                        <>
                          <TextFieldWrapper>
                            <StyledTextField
                              label="Back Number (3 or 4 digits)"
                              type="text"
                              name="backNumber"
                              value={backNumber}
                              onChange={handleBackNumberChange}
                              validations={'required|min:3|max:4'}
                              validator={simpleValidator.current}
                            />
                          </TextFieldWrapper>
                        </>
                      )}
                      {nameShow && (
                        <>
                          <TextFieldWrapper>
                            <StyledTextField
                              label="Name"
                              type="text"
                              name="name"
                              value={nameValue}
                              onChange={e => {
                                setNameChange(e.target.value);
                                setIsName(e.target.value.trim() === '');
                              }}
                              validations={'required|max:40'}
                              validator={simpleValidator.current}
                            />
                          </TextFieldWrapper>
                        </>
                      )}
                    </TextFieldGrid>
                    <SearchButtonWrapper>
                      <Button
                        className="main-btn-back"
                        onClick={() => {
                          navigate('/rider-registration-page');
                        }}
                      >
                        Back
                      </Button>
                      <SearchButton
                        onClick={() =>
                          handleSearch(
                            seasonValue?.id! ? seasonValue?.id! : seasonId!,
                            backNumber,
                            nameValue,
                            categoryValue?.id!
                              ? categoryValue?.id!
                              : categoryDefaultValue?.id!,
                            divisionValue?.id!,
                            teamValue?.id!,
                          )
                        }
                        disableNext={
                          (isBackNumberCheck !== null
                            ? isBackNumberCheck
                            : false) || isName
                        }
                      >
                        Search
                      </SearchButton>
                    </SearchButtonWrapper>
                    {showTable ? (
                      <TableButtonWrapper>
                        <EWDRiderTable
                          riderTableData={riderTableData}
                          handleCheckboxClick={handleCheckboxClick}
                          checkedMap={checkedMap}
                        />
                        <InfoWrapper>
                          <p className="para-heading">
                            <i>PLEASE NOTE:</i>
                          </p>
                          <p className="para-content">
                            You are able to edit and update information in
                            Member Registration.
                          </p>
                        </InfoWrapper>
                        <ButtonWrapper className="bg-color-change">
                          <BackNextButton
                            classNameBack="btn-back"
                            classNameNxt="btn-nxt"
                            onClickNxt={handleNxt}
                            onClickBack={handleNxtBack}
                            back="Cancel"
                            next="Next"
                            disableNext={isChecked}
                          />
                        </ButtonWrapper>
                      </TableButtonWrapper>
                    ) : (
                      ''
                    )}
                  </OverflowWrapper>
                </>
              )}
            </>
          )}
        </MainWrapper>
      )}
    </>
  );
};
const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 20px 0px;
  p {
    margin: 0px;
  }
  .para-one {
    color: #9597a6;
    padding: 40px 0px 10px;
  }
  .para-two {
    color: #ff9900;
    border-bottom: 1px solid #585858;
    padding: 0px 0px 10px;
  }
  label {
    color: #9597a6;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .Team-Heading {
    margin: 0px;
    padding-top: 20px;
    text-align: center;
    color: #9597a6;
  }
`;
const LoaderWrapper = styled.div`
  position: relative;
  // top: 245px;
  // left: 260px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    position: absolute;
    top: 60%;
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: 100%;
  padding-bottom: 100px;
  color: #9597a6;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 9px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0px;
`;
const TextFieldWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  @media (min-width: 320px) and (max-width: 767.98px) {
    width: 100% !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50% !important;
  }
`;
const StyledTextField = styled(TextField)`
  flex: 0 0 50%;
  &.styled-field {
    margin-bottom: 0px !important;
  }
`;

const DropdownWrapper = styled.div`
  width: 50% !important;
  margin-bottom: 20px;
  p {
    margin: 0px;
    font-family: 'Roboto';
    font-size: 14px;
  }
  .drop-down {
    color: #9597a6;
  }
`;

const TableButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SearchButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  .main-btn-back {
    background-color: #2a2a2a;
    border: 1px solid #606060;
    color: #909090;
    height: 35px;
    &.light {
      background-color: #ededed;
    }
    &.dark {
      background-color: #2a2a2a;
    }
  }
  button {
    width: 100px;
    height: 30px;
    margin: 0px 10px;
    border-radius: 20px;
    background: #5b5a5a;
    border: 1px solid #8b8b8b;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  border: 1px solid #585858;
  border-radius: 10px;
  margin: 30px 0px 20px;
  padding: 2px 20px;
  background: #27292d;
  .para-heading {
    color: #ff9900;
    border-bottom: 1px solid #5d5c5c;
    font-size: 14px;
  }
  .para-content {
    font-family: 'Roboto';
    color: #a7a7a7;
    font-size: 12px;
    padding: 10px 0px;
  }

  @media (min-width: 100px) and (max-width: 319px) {
    .para-content {
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .para-content {
      font-size: 10px;
    }
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    .para-content {
      font-size: 11px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1719px) {
    .para-content {
      font-size: 12px;
    }
  }

  @media (min-width: 1720px) and (max-width: 4000px) {
    .para-content {
      font-size: 13px;
    }
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: 2px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
    @media (min-width: 1200px) and (max-width: 1365px) {
      height: 55px;
    }
    @media (min-width: 1366px) and (max-width: 1448px) {
      height: 70px;
    }
  }
`;

export default EWDReturningMember;
