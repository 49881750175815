import { Card, GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { Wrapper } from 'app/components/SHToolTip';
import styled from 'styled-components';
import SeasonImage from "./Components/Icons/settings.svg"
import ProfileImage from './Components/Icons/Profile.png';
import TeamImage from './Components/Icons/User-Settings-98.png';
import { useNavigate } from 'react-router-dom';

const SettingsCard = () => {
const navigate = useNavigate();
 const handleProfileClick = () => {
   navigate(`/admin/settings/profile-settings`);
 };
  const handleSeasonClick = () => {
    navigate(`/admin/settings/seasonSettings`);
  };
  return (
    <MainWrapper>
      <GridLayout
        gap={{ rows: 6, cols: 10 }}
        rows={[{ height: 'auto' }, { height: 'auto' }]}
        cols={[
          { width: 'calc(33.33% - 7px)' },
          { width: 'calc(33.33% - 7px)' },
          { width: 'calc(33.33% - 7px)' },
        ]}
      >
        <GridLayoutItem row={1} col={1} colSpan={1} className="first-item">
          <ContentWrapper onClick={handleSeasonClick} className="season-style">
            <img src={SeasonImage} alt="Season" />
            <p>Season Settings</p>
          </ContentWrapper>
        </GridLayoutItem>
        <GridLayoutItem row={1} col={2} colSpan={1} className="first-item">
          <ContentWrapper onClick={handleProfileClick} className="season-style">
            <img src={ProfileImage} alt="profile" />
            <p>Profile Settings</p>
          </ContentWrapper>
        </GridLayoutItem>
        <GridLayoutItem row={1} col={3} colSpan={1} className="first-item">
          <ContentWrapper>
            <img src={TeamImage} alt="team" />
            <p>Team Settings</p>
          </ContentWrapper>
        </GridLayoutItem>
      </GridLayout>
    </MainWrapper>
  );
};

export default SettingsCard;
const MainWrapper = styled(Wrapper)`
  .profile-img {
    display: none;
  }
  .border-div {
    display: none;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #caced8;
  padding: 30px;
  border-radius: 12px;
  margin-top: 20px;
  cursor: pointer;

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
    max-width: 120px;
    width: 100%;
  }

  p {
    margin: 0;
    font-size: 15px;
    font-family: 'Inter';
    letter-spacing: 0.1px;
    font-weight: 700;
    padding: 10px 0 0 0;
  }
`;
