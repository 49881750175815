import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import React from 'react';
import { useState } from 'react';
import { BackNextButton } from 'app/components/BackNextButton';
import SHDatePicker from 'app/components/SHDatePicker';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@progress/kendo-react-dialogs';
import SHdropdown from 'app/components/SHdropdown';
import { GeneralLookup, RegisterRider } from 'services/openapi';
import BackNumberTableComponent from 'app/components/BackNumberListComponent';
import { ApiError, RiderService } from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import SHYear from 'app/components/SHYear';
import { useInternationalization } from '@progress/kendo-react-intl';
import SimpleReactValidator from 'simple-react-validator';
import CheckBackNumberField from 'app/components/CheckBackNumberField';
import Cookies from 'js-cookie';
import { heightWeightModel } from '../..';
import InfoIconButton from 'app/components/InfoIconButton';
import SHBackNumberDropdown from 'app/components/SHBackNumberDropdown';

type Props = {
  onClickBackPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNxtPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  riderData: RegisterRider;
  setRiderData: React.Dispatch<React.SetStateAction<RegisterRider>>;
  stateData?: GeneralLookup[];
  currentGradeData?: GeneralLookup[];
  setIsBackNumberCheck: React.Dispatch<React.SetStateAction<boolean | null>>;
  isBackNumberCheck: boolean | null;
  setHeightWeight: React.Dispatch<React.SetStateAction<heightWeightModel>>;
  heightWeight: heightWeightModel;
  backNumberData: string[] | undefined;
};

const PersonalInfo = ({
  onClickNxtPersonal,
  onClickBackPersonal,
  riderData,
  setRiderData,
  stateData,
  currentGradeData,
  setIsBackNumberCheck,
  isBackNumberCheck,
  setHeightWeight,
  heightWeight,
  backNumberData,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const navigate = useNavigate();
  const { riderCheckBackNumberExist } = RiderService;
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();
  const [isCheckDisabled, setIsCheckDisabled] = useState(true);

  const handleChange = event => {
    const { name, value } = event.target;
    setRiderData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    if (isBackNumberCheck === false) {
      setIsBackNumberCheck(null);
    }
  };
  const [visible, setVisible] = React.useState<boolean>(false);
  const handleBack = () => {
    navigate('/rider-registration-page');
    Cookies.remove('paymentCancel');
  };

  const handleInfoIcon = () => {
    setVisible(!visible);
  };

  const checkBackNumber = (backNumber?: string) => {
    if (backNumber?.length !== 0) {
      handleRequest(riderCheckBackNumberExist(backNumber))
        .then(res => {
          console.log(res, 'resssss');
          setIsBackNumberCheck(res!);
        })
        .catch(() => setError(apiError));
    }
  };

  const intl = useInternationalization();
  const expectedGraduationChange = e => {
    setRiderData({
      ...riderData,
      expectedGraduationYear: e,
    });
  };
  const handleDate = e => {
    let date = intl.formatDate(e.value, "yyyy-MM-dd'T'HH:mm:ss");
    setRiderData?.({
      ...riderData!,
      dateOfBirth: date,
    });
  };
  const [valid, setValid] = useState<boolean>(false);
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [riderData]);

  const handleFeetChange = event => {
    const newFeet = parseInt(event.target.value);
    if (event.target.value && newFeet >= 0 && newFeet <= 9) {
      setHeightWeight(prevData => ({ ...prevData, feet: Math.floor(newFeet) }));
      const newHeightInch = newFeet + heightWeight.inches! / 12;
      if (riderData) {
        setRiderData({ ...riderData, height: newFeet });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({ ...prevData, feet: undefined }));
    }
  };

  const handleInchesChange = event => {
    const inputValue = event.target.value;
    const numericRegex = /^[\d\b]*$/;
    if (inputValue && numericRegex.test(inputValue)) {
      const newInches = parseInt(event.target.value);
      if (newInches >= 0 && newInches <= 11) {
        setHeightWeight(prevData => ({
          ...prevData,
          inches: Math.floor(newInches),
        }));
        const newHeightInch = heightWeight.feet! + newInches / 12;
        if (riderData) {
          setRiderData({ ...riderData, height: newHeightInch });
        }
      }
    } else if (inputValue === '') {
      setHeightWeight(prevData => ({
        ...prevData,
        inches: undefined,
      }));
    }
  };
  const handleWeightChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 3 && /^[0-9]*$/.test(inputValue)) {
      setRiderData({
        ...riderData,
        weight: inputValue,
      });
      setHeightWeight(prevData => ({
        ...prevData,
        weight: inputValue,
      }));
    }
  };
  // const handleBackNumberChange = e => {
  //   const inputValue = e.target?.value;
  //   if (inputValue?.length !== 4 && typeof isBackNumberCheck === 'boolean') {
  //     setIsBackNumberCheck(null);
  //   }
  //   if (inputValue?.length <= 4 && /^\d*$/.test(inputValue)) {
  //     setRiderData({
  //       ...riderData,
  //       backNumber: inputValue,
  //     });
  //   }
  //   if (inputValue?.length >= 3) {
  //     setIsCheckDisabled(false);
  //   } else {
  //     setIsCheckDisabled(true);
  //   }
  // };
  const handleBackNumberChange = name => {
    if (riderData) {
      setRiderData?.({
        ...riderData,
        backNumber: name.value,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setRiderData({
        ...riderData,
        primaryPhone: inputValue,
      });
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setRiderData({ ...riderData, zip: e.target.value });
    }
  };
  console.log(riderData, 'riderData');
  return (
    <MainWrapper className="form-MainWrapper">
      <OverflowWrapper className="OverflowWrapper">
        <TextFieldGrid className="TextFieldGrid">
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              onChange={handleChange}
              value={riderData?.firstName}
              label="First Name"
              type="text"
              name="firstName"
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              onChange={handleChange}
              value={riderData?.lastName}
              label="Last Name"
              type="text"
              name="lastName"
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper BackFieldMainWrapper">
            <BackFieldWrapper className="BackFieldWrapper">
              {/* <CheckBackNumberField
              onChange={handleBackNumberChange}
              value={riderData?.backNumber}
              validations={'required|min:3|max:4'}
              validator={simpleValidator.current}
              onCheckClick={() => checkBackNumber(riderData?.backNumber)}
              onInfoClick={handleInfoIcon}
              type="text"
              name="backNumber"
              label="Back Number (3 or 4 digits)"
              isBackNumberCheck={isBackNumberCheck}
              disabledCheck={isCheckDisabled}
            /> */}
              <SHBackNumberDropdown
                onChange={e => handleBackNumberChange(e.value)}
                data={backNumberData}
                defaultValue={riderData?.backNumber}
                label="Back Number (3 or 4 digits)"
                validations={'required|max:30'}
                validator={simpleValidator.current}
                isAbove={true}
                className="BackfieldNumber"
              />
              <InfoIconButton onClick={handleInfoIcon} className="info-icon" />
            </BackFieldWrapper>
            {visible && (
              <BackNumberTableComponent
                onClose={handleInfoIcon}
                backNumberData={backNumberData}
                isAbove={true}
              />
            )}
          </TextFieldWrapper>
          <GradeInputFeild className="GradeTextFeild">
            <SHdropdown
              className="drop-down"
              label="Current Grade"
              data={currentGradeData}
              onDataChange={e => {
                setRiderData({
                  ...riderData,
                  currentGrade: e,
                });
              }}
              defaultValue={riderData?.currentGrade!}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </GradeInputFeild>
          <SubWrapper>
            <GraduationDobWrapper className="GraduationDobWrapper">
              <GraduationSubWrapper className="GraduationSubWrapper">
                <SHYear
                  className="drop-down"
                  label="Expected Graduation Year"
                  defaultValue={
                    riderData?.expectedGraduationYear === 0
                      ? undefined
                      : riderData?.expectedGraduationYear
                  }
                  value={
                    riderData?.expectedGraduationYear === 0
                      ? undefined
                      : riderData?.expectedGraduationYear
                  }
                  onChange={expectedGraduationChange}
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </GraduationSubWrapper>
              <GraduationSubWrapper className="GraduationSubWrapper">
                <StyledSHDatePicker
                  onChange={handleDate}
                  placeholder=""
                  value={
                    riderData?.dateOfBirth
                      ? new Date(String(riderData?.dateOfBirth))
                      : undefined
                  }
                  name="dob"
                  className="StyledSHDatePicker datepicker-style"
                  dateLabel="Date of Birth"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </GraduationSubWrapper>
            </GraduationDobWrapper>
            <HeightInchWeightWrapper className="HeightInchWeightWrapper">
              <StyledHeightTextField
                onChange={handleFeetChange}
                value={heightWeight.feet}
                label="Height(Ft)"
                type="number"
                name="height"
                placeholder="Feet"
                validations={'required'}
                validator={simpleValidator.current}
              />
              <StyledInchTextField
                onChange={handleInchesChange}
                value={heightWeight.inches}
                type="number"
                label="Inches"
                name="inch"
                placeholder="Inches"
              />
              <NoteFieldWrapper className="flex-NoteWrapper">
                <span className="note-block">
                  <span className="note-head"></span> eg. 5ft 10in
                </span>
              </NoteFieldWrapper>
              <StyledWeightTextField
                onChange={handleWeightChange}
                value={heightWeight.weight}
                label="Weight"
                type="text"
                name="weight"
                placeholder="lbs"
                validations={'required'}
                validator={simpleValidator.current}
              />
            </HeightInchWeightWrapper>
          </SubWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              onChange={handlePhChange}
              value={riderData?.primaryPhone}
              label="Cell/Primary Ph#"
              type="tel"
              name="primaryPhone"
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              onChange={handleChange}
              value={riderData?.email}
              label="Email"
              type="email"
              name="email"
              validations={'required|email'}
              validator={simpleValidator.current}
              maxLength={60}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <HomeAddressWrapper className="horizontal-line">
          <p className="home-address">Home mailing address</p>
        </HomeAddressWrapper>
        <TextFieldGrid className="TextFieldGrid">
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              onChange={handleChange}
              value={riderData?.street}
              label="Street Address"
              type="text"
              name="street"
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              onChange={handleChange}
              value={riderData?.city}
              label="City"
              type="text"
              name="city"
              validations={'required|max:40'}
              validator={simpleValidator.current}
              maxLength={40}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="State"
              data={stateData}
              onDataChange={e => {
                setRiderData({
                  ...riderData,
                  state: e,
                });
              }}
              defaultValue={riderData?.state!}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              onChange={handleZipChange}
              value={riderData?.zip}
              label="Zip"
              type="text"
              name="zip"
              validations={'required|alpha_num|min:5|max:9'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
      </OverflowWrapper>
      <ButtonWrapper className="bg-color-change">
        <BackNextButton
          classNameBack="btn-back"
          classNameNxt="btn-nxt"
          onClickNxt={onClickNxtPersonal}
          onClickBack={handleBack}
          next="Next"
          back="Back"
          disableNext={valid}
        />
      </ButtonWrapper>
    </MainWrapper>
  );
};
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 180px);
  padding: 20px 0px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
  padding: 20px 60px 100px 60px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const SubWrapper = styled.div`
  display: flex;
  align-items: end;
  @media (min-width: 992px) and (max-width: 1199px) {
    display: block;
  }
  @media (min-width: 100px) and (max-width: 991px) {
    display: block;
    width: 100%;
  }
`;
const HomeAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  padding: 0 12px;

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
    color: #4f4f4f;
  }
`;
const TextFieldWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
`;
const StyledTextField = styled(TextField)`
  &.styled-field {
    margin-bottom: 0px !important;
  }
  input {
    width: 100% !important;
    border: 1px solid #434343;
    &.back-number {
      &.green-border {
        border: 1px solid green !important;
      }
      &.red-border {
        border: 1px solid red !important;
      }
    }
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const GradeInputFeild = styled.div`
  @media (min-width: 992px) and (max-width: 1200px) {
    &.GradeTextFeild {
      width: 50% !important;
    }
  }
  @media (min-width: 100px) and (max-width: 991px) {
    &.GradeTextFeild {
      width: 100% !important;
    }
  }
  width: 50%;
  margin-bottom: 20px;
`;

const BackFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .BackfieldNumber {
    width: 80%;
  }
  .info-icon {
    margin: 24px 12px 0px 0px !important;
  }

  @media (min-width: 100px) and (max-width: 575px) {
    width: 100%;
    .info-icon {
      margin: 17px 0px 0px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    .info-icon {
      margin: 17px 12px 0px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 100%;
    .info-icon {
      margin: 20px 12px 0px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .info-icon {
      margin: 18px 12px 0px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .info-icon {
      margin: 21px 12px 0px !important;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .info-icon {
      margin: 23px 12px 0px !important;
    }
  }
`;
const GraduationDobWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 10px 0px;
  padding: 0 12px 0 12px;
  display: flex;
  align-items: end;

  .k-input:not(:-webkit-autofill) {
    animation-name: unset !important;
  }
  .k-input-solid {
    width: 100% !important;
    height: 35px;
  }
  &.datepicker-style {
    display: flex;
    align-items: end;
  }
  .date {
    display: flex;
    flex-direction: column;
    p {
      margin: 0px;
    }
  }
`;
const GraduationSubWrapper = styled.div`
  width: 50%;
  .SHYear {
    margin-left: 0px !important;
  }
`;
const StyledSHDatePicker = styled(SHDatePicker)`
  display: flex;
  align-items: end;
  width: 50%;

  input {
    width: 100%;
    height: 35px;
  }
  span {
    border-color: #434343;
  }
`;
const HeightInchWeightWrapper = styled.div`
  display: flex;
  width: 50%;
  padding: 0 12px;
  span {
    font-size: 10px;
    display: flex;
    align-items: center;
  }
`;
const NoteFieldWrapper = styled.div`
  margin: 0px 10px;
  text-align: center;
  white-space: nowrap;
  .note-block {
    display: inline-grid;
    font-size: 12px;
    .note-head {
    }
  }
  &.flex-NoteWrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .note-block {
      display: inline-grid;
      font-size: 12px;
      .note-head {
      }
    }
  }
`;
const StyledWeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 33%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledInchTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 33%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledHeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 33%;
  padding-right: 10px;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  z-index: 2;

  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -3px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    backdrop-filter: blur(5px);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
export default PersonalInfo;
