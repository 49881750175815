import styled from 'styled-components';
import { TextField } from 'app/components/TextField';
import '@fontsource/roboto';
import React from 'react';
import { useState } from 'react';
import { BackNextButton } from 'app/components/BackNextButton';
import SHDatePicker from 'app/components/SHDatePicker';
import { useNavigate } from 'react-router-dom';
import SHdropdown from 'app/components/SHdropdown';
import {
  ApiError,
  GeneralLookup,
  RegisterRider,
  RiderService,
} from 'services/openapi';
import useApi from 'services/mavapi/useApi';
import SHYear from 'app/components/SHYear';
import { useInternationalization } from '@progress/kendo-react-intl';
import BackNumberTableComponent from 'app/components/BackNumberListComponent';
import SimpleReactValidator from 'simple-react-validator';
import CheckBackNumberField from 'app/components/CheckBackNumberField';
import Cookies from 'js-cookie';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import SHBackNumberDropdown from 'app/components/SHBackNumberDropdown';
import InfoIconButton from 'app/components/InfoIconButton';

type Props = {
  onClickBackPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onClickNxtPersonal?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  tryItRegistration: RegisterRider;
  setTryItRegistration: React.Dispatch<React.SetStateAction<RegisterRider>>;
  stateData?: GeneralLookup[];
  gradeLookUp: GeneralLookup[];
  setIsBackNumberCheck: React.Dispatch<React.SetStateAction<boolean | null>>;
  isBackNumberCheck: boolean | null;
  setHeightWeight: React.Dispatch<React.SetStateAction<heightWeightModel>>;
  heightWeight: heightWeightModel;
  backNumberData: string[] | undefined;
};

const NewMemberPersonalInfo = ({
  onClickNxtPersonal,
  onClickBackPersonal,
  tryItRegistration,
  setTryItRegistration,
  stateData,
  gradeLookUp,
  setIsBackNumberCheck,
  isBackNumberCheck,
  setHeightWeight,
  heightWeight,
  backNumberData,
}: Props) => {
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const intl = useInternationalization();
  const { riderCheckBackNumberExist } = RiderService;
  const { error: apiError, handleRequest } = useApi();
  const [, setError] = React.useState<ApiError | unknown>();

  const [isCheckDisabled, setIsCheckDisabled] = useState(true);

  const checkBackNumber = (backNumber?: string) => {
    if (backNumber?.length !== 0) {
      handleRequest(riderCheckBackNumberExist(backNumber))
        .then(res => {
          setIsBackNumberCheck(res!);
        })
        .catch(() => setError(apiError));
    }
  };
  const navigate = useNavigate();
  const [visible, setVisible] = React.useState<boolean>(false);
  const handleBack = () => {
    navigate(-1);
    Cookies.remove('payload');
    Cookies.remove('paymentCancel');
    Cookies.remove('riderId');
    Cookies.remove('payloadPlacement');
  };
  const handleInfoIcon = () => {
    setVisible(!visible);
  };

  const expectedGraduationChange = e => {
    setTryItRegistration({
      ...tryItRegistration,
      expectedGraduationYear: e,
    });
  };
  const handleDate = e => {
    let date = intl.formatDate(e.value, "yyyy-MM-dd'T'HH:mm:ss");
    setTryItRegistration({
      ...tryItRegistration,
      dateOfBirth: date,
    });
  };
  const [valid, setValid] = useState<boolean>(false);
  React.useEffect(() => {
    const formValid = simpleValidator.current.allValid();
    setValid(!formValid);
  }, [tryItRegistration]);

  const handleFeetChange = event => {
    const newFeet = parseInt(event.target.value);
    if (event.target.value && newFeet >= 0 && newFeet <= 9) {
      setHeightWeight(prevData => ({ ...prevData, feet: Math.floor(newFeet) }));
      const newHeightInch = newFeet + heightWeight.inches! / 12;
      if (tryItRegistration) {
        setTryItRegistration({ ...tryItRegistration, height: newFeet });
      }
    } else if (!event.target.value) {
      setHeightWeight(prevData => ({ ...prevData, feet: undefined }));
    }
  };
  const handleInchesChange = event => {
    const inputValue = event.target.value;
    const numericRegex = /^[\d\b]*$/;
    if (inputValue && numericRegex.test(inputValue)) {
      const newInches = parseInt(inputValue);

      if (newInches >= 0 && newInches <= 11) {
        setHeightWeight(prevData => ({
          ...prevData,
          inches: Math.floor(newInches),
        }));
        const newHeightInch = heightWeight.feet! + newInches / 12;

        if (tryItRegistration) {
          setTryItRegistration({ ...tryItRegistration, height: newHeightInch });
        }
      }
    } else if (inputValue === '') {
      setHeightWeight(prevData => ({
        ...prevData,
        inches: undefined,
      }));
    }
  };
  const handleWeightChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 3 && /^[0-9]*$/.test(inputValue)) {
      setTryItRegistration({
        ...tryItRegistration,
        weight: inputValue,
      });
      setHeightWeight(prevData => ({
        ...prevData,
        weight: inputValue,
      }));
    }
  };

  // const handleBackNumberChange = e => {
  //   const inputValue = e.target.value;
  //   if (inputValue?.length !== 2 && typeof isBackNumberCheck === 'boolean') {
  //     setIsBackNumberCheck(null);
  //   }
  //   if (inputValue.length <= 2 && /^\d*$/.test(inputValue)) {
  //     setTryItRegistration({
  //       ...tryItRegistration,
  //       backNumber: inputValue,
  //     });
  //   }
  //   if (inputValue.length >= 1) {
  //     setIsCheckDisabled(false);
  //   } else {
  //     setIsCheckDisabled(true);
  //   }
  // };
  const handleBackNumberChange = name => {
    if (tryItRegistration) {
      setTryItRegistration?.({
        ...tryItRegistration,
        backNumber: name.value,
      });
    }
  };
  const handlePhChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 10 && /^\d*$/.test(inputValue)) {
      setTryItRegistration({
        ...tryItRegistration,
        primaryPhone: inputValue,
      });
    }
  };
  const handleZipChange = e => {
    const inputValue = e.target.value;
    if (inputValue.length <= 9 && /^[a-zA-Z0-9]*$/.test(inputValue)) {
      setTryItRegistration({ ...tryItRegistration, zip: e.target.value });
    }
  };
  return (
    <MainWrapper className="form-MainWrapper">
      <OverflowWrapper className="OverflowWrapper">
        <TextFieldGrid className="TextFieldGrid">
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="First Name"
              type="text"
              name="firstName"
              onChange={e =>
                setTryItRegistration({
                  ...tryItRegistration,
                  firstName: e.target.value,
                })
              }
              value={tryItRegistration.firstName}
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Last Name"
              type="text"
              name="lastName"
              onChange={e =>
                setTryItRegistration({
                  ...tryItRegistration,
                  lastName: e.target.value,
                })
              }
              value={tryItRegistration.lastName}
              validations={'required|max:30'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>{' '}
          <TextFieldWrapper className="TextFieldWrapper BackFieldMainWrapper">
            <BackFieldWrapper className="BackFieldWrapper">
              {/* <CheckBackNumberField
              onChange={handleBackNumberChange}
              value={tryItRegistration?.backNumber}
              validations={'required|max:2'}
              validator={simpleValidator.current}
              onCheckClick={() =>
                checkBackNumber(tryItRegistration?.backNumber)
              }
              onInfoClick={handleInfoIcon}
              type="text"
              name="backNumber"
              className="back-number"
              label="Back Number"
              isBackNumberCheck={isBackNumberCheck}
              twoDigit={true}
              disabledCheck={isCheckDisabled}
            /> */}
              <SHBackNumberDropdown
                onChange={e => handleBackNumberChange(e.value)}
                data={backNumberData}
                defaultValue={tryItRegistration?.backNumber}
                label="Back Number (2 digits)"
                validations={'required|max:30'}
                validator={simpleValidator.current}
                isAbove={false}
                className="BackfieldNumber"
              />
              <InfoIconButton onClick={handleInfoIcon} className="info-icon" />
            </BackFieldWrapper>
            {visible && (
              <BackNumberTableComponent
                onClose={handleInfoIcon}
                backNumberData={backNumberData}
                isAbove={false}
              />
            )}
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="StyledTextField"
              label="Current Grade"
              data={gradeLookUp}
              onDataChange={e =>
                setTryItRegistration({
                  ...tryItRegistration,
                  currentGrade: e,
                })
              }
              defaultValue={tryItRegistration.currentGrade}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <SubWrapper>
            <GraduationDobWrapper className="GraduationDobWrapper">
              <GraduationSubWrapper className="GraduationSubWrapper">
                <SHYear
                  className="drop-down"
                  label="Expected Graduation Year"
                  defaultValue={
                    tryItRegistration?.expectedGraduationYear === 0
                      ? undefined
                      : tryItRegistration?.expectedGraduationYear
                  }
                  value={
                    tryItRegistration?.expectedGraduationYear === 0
                      ? undefined
                      : tryItRegistration?.expectedGraduationYear
                  }
                  onChange={expectedGraduationChange}
                />
              </GraduationSubWrapper>
              <GraduationSubWrapper className="GraduationSubWrapper">
                <StyledSHDatePicker
                  onChange={handleDate}
                  placeholder=""
                  value={
                    tryItRegistration.dateOfBirth
                      ? new Date(String(tryItRegistration?.dateOfBirth))
                      : undefined
                  }
                  name="dob"
                  className="datepicker-style"
                  dateLabel="Date of Birth"
                  validations={'required'}
                  validator={simpleValidator.current}
                />
              </GraduationSubWrapper>
            </GraduationDobWrapper>
            <HeightInchWeightWrapper className="HeightInchWeightWrapper">
              <StyledHeightTextField
                label="Height"
                onChange={handleFeetChange}
                value={heightWeight.feet}
                type="number"
                name="height"
                validations={'required'}
                validator={simpleValidator.current}
              />
              <StyledInchTextField
                onChange={handleInchesChange}
                value={heightWeight.inches}
                type="number"
                name="inch"
                placeholder="Inches"
                label="Inches"
              />
              <SpanInch>eg. 5ft 10in</SpanInch>
              <StyledWeightTextField
                label="Weight"
                type="text"
                name="weight"
                placeholder="lbs"
                onChange={handleWeightChange}
                value={heightWeight.weight}
                validations={'required'}
                validator={simpleValidator.current}
              />
            </HeightInchWeightWrapper>
          </SubWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Cell/Primary Ph#"
              type="tel"
              name="ph"
              onChange={handlePhChange}
              value={tryItRegistration.primaryPhone}
              validations={'required|numeric|min:10|max:10'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Email"
              type="email"
              name="email"
              onChange={e =>
                setTryItRegistration({
                  ...tryItRegistration,
                  email: e.target.value,
                })
              }
              value={tryItRegistration.email}
              validations={'required|email'}
              validator={simpleValidator.current}
              maxLength={60}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
        <HomeAddressWrapper className="horizontal-line">
          <p className="home-address">Home mailing address</p>
        </HomeAddressWrapper>
        <TextFieldGrid>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Street Address"
              type="text"
              name="streetAddress"
              onChange={e =>
                setTryItRegistration({
                  ...tryItRegistration,
                  street: e.target.value,
                })
              }
              value={tryItRegistration.street}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="City"
              type="text"
              name="city"
              onChange={e =>
                setTryItRegistration({
                  ...tryItRegistration,
                  city: e.target.value,
                })
              }
              value={tryItRegistration.city}
              validations={'required|max:40'}
              validator={simpleValidator.current}
              maxLength={40}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <SHdropdown
              className="drop-down"
              label="State"
              data={stateData}
              onDataChange={data =>
                setTryItRegistration({
                  ...tryItRegistration,
                  state: data,
                })
              }
              defaultValue={tryItRegistration?.state}
              validations={'required'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
          <TextFieldWrapper className="TextFieldWrapper ">
            <StyledTextField
              className="StyledTextField"
              label="Zip"
              type="text"
              name="zip"
              onChange={handleZipChange}
              value={tryItRegistration.zip}
              validations={'required|alpha_num|min:5|max:9'}
              validator={simpleValidator.current}
            />
          </TextFieldWrapper>
        </TextFieldGrid>
      </OverflowWrapper>
      <ButtonWrapper className="bg-color-change">
        <BackNextButton
          classNameBack="btn-back"
          classNameNxt="btn-nxt"
          onClickNxt={onClickNxtPersonal}
          onClickBack={handleBack}
          next="Next"
          back="Back"
          disableNext={valid}
        />
      </ButtonWrapper>
    </MainWrapper>
  );
};
const SubWrapper = styled.div`
  display: flex;
  align-items: end;
  @media (min-width: 992px) and (max-width: 1199px) {
    display: block;
  }
  @media (min-width: 100px) and (max-width: 991px) {
    display: block;
    width: 100%;
  }
`;
const SpanInch = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 12px;
`;
const MainWrapper = styled.div`
  color: #9597a6;
  width: 100%;
  height: calc(100vh - 230px);
  padding: 20px 0px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-family: 'Roboto';
    font-size: 14px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    // padding-top: 20px;
    label {
      font-size: 12px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    // padding-top: 20px;
    label {
      font-size: 13px;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    // padding-top: 20px;
    label {
      font-size: 14px;
    }
  }
`;
const OverflowWrapper = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
  padding: 20px 60px 100px 60px;
  ::-webkit-scrollbar {
    width: 5px;
    margin-left: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent !important;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background: transparent !important;
    border-radius: 10px;
  }
`;
const TextFieldGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const HomeAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  width: 100%;
  padding: 0 12px;

  ::after {
    content: '';
    flex-grow: 1;
    border-top: 1px solid #585858;
  }

  p {
    margin: 0 10px 0px 0px;
    white-space: nowrap;
    color: #4f4f4f;
  }
`;
const TextFieldWrapper = styled.div`
  width: 50%;
  margin-bottom: 20px;
`;
const StyledTextField = styled(TextField)`
  &.styled-field {
    margin-bottom: 0px !important;
  }
  input {
    width: 100% !important;
    border: 1px solid #434343;
    &.back-number {
      &.green-border {
        border: 1px solid green;
      }
      &.red-border {
        border: 1px solid red;
      }
    }
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const BackFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .BackfieldNumber {
    width: 80%;
  }
  .info-icon {
    margin: 24px 12px 0px 0px !important;
  }
  @media (min-width: 100px) and (max-width: 575px) {
    width: 100%;
    .info-icon {
      margin: 17px 0px 0px !important;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    .info-icon {
      margin: 17px 12px 0px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 100%;
    .info-icon {
      margin: 20px 12px 0px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .info-icon {
      margin: 18px 12px 0px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .info-icon {
      margin: 21px 12px 0px !important;
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .info-icon {
      margin: 23px 12px 0px !important;
    }
  }
`;
const GraduationDobWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 10px 0px;
  padding: 0 12px 0 12px;
  display: flex;
  align-items: end;
  .k-input:not(:-webkit-autofill) {
    animation-name: unset !important;
  }
  .k-input-solid {
    width: 100% !important;
    height: 35px;
  }
  &.datepicker-style {
    display: flex;
    align-items: end;
  }
  .date {
    display: flex;
    flex-direction: column;
    p {
      margin: 0px;
    }
  }
`;
const GraduationSubWrapper = styled.div`
  /* padding-top: 2px;
  display: flex;
  align-items: end; */
  width: 50%;
  .SHYear {
    margin-left: 0px !important;
  }
  /* button {
    width: 155px !important;
  }
  .drop-down {
    margin: 0px;
    width: 100%;
  }
  .drop-down > div {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    font-size: 14px;
    width: 100%;
  }
  .k-button .k-button-icon {
    margin: 5px 0px 0 55px;
  } */
`;
const StyledSHDatePicker = styled(SHDatePicker)`
  display: flex;
  align-items: end;
  width: 50%;

  input {
    width: 100%;
    height: 35px;
  }
  span {
    border-color: #434343;
  }
`;
const HeightInchWeightWrapper = styled.div`
  display: flex;
  gap: 5px;
  width: 50%;
  padding: 0 12px;
  // align-items: center;
  span {
    // padding-top: 10px;
    font-size: 10px;
    display: flex;
    align-items: center;
  }
`;
const StyledWeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 33%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledHeightTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 33%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const StyledInchTextField = styled(TextField)`
  margin: 0px 0px 10px !important;
  width: 33%;
  input {
    width: 100%;
    height: 35px;
  }
  ::placeholder {
    color: #8f8e8e !important;
  }
`;
const NoteFieldWrapper = styled.div`
  margin: 0px 20px;
  text-align: left;
  .note-block {
    display: inline-grid;
    font-size: 12px;
    .note-head {
    }
  }
  &.flex-NoteWrapper {
    .note-block {
      display: inline-grid;
      font-size: 12px;
      padding-top: 30px;
      .note-head {
      }
    }
  }
`;

const ButtonWrapper = styled.div`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 20px;
  z-index: 2;
  &.bg-color {
    background: rgb(42 42 42) 100%;
  }
  &.bg-color-change {
    position: absolute;
    bottom: -43px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      rgb(36 36 36 / 27%) 0%,
      rgb(42 42 42) 100%
    );
    border: 1px solid rgb(42 42 42);
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-width: 100px) and (max-width: 575px) {
    height: 45px;
    bottom: -20px !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    height: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    height: 55px;
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    height: 70px;
  }
`;
export default NewMemberPersonalInfo;
