import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PersonalInfoForm } from './components/PersonalInfoForm';
import { AlumniDivisionForm } from './components/AlumniDivisionForm';
import { PersonalAffiliationsForm } from './components/PersonalAffiliationsForm';
import RetriveReturningMemberDataForm from './components/RetriveReturningMemberDataForm';
import useApi from 'services/mavapi/useApi';
import {
  Alumni,
  AlumniService,
  GeneralLookup,
  GeneralLookupWrapper,
  GeneralRider,
  LookupService,
  RiderService,
} from 'services/openapi';
import { useNavigate } from 'react-router-dom';
import { RadioValueModel } from '../AlumniNewMemberResgistration';
import LoaderWrapper from 'app/components/CircularLoader/LoaderWrapper';
import CircularLoader from 'app/components/CircularLoader';
import { useManagePageSlice } from 'app/pages/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectPageLoader } from 'app/pages/slice/selectors';
import Cookies from 'js-cookie';
import { heightWeightModel } from 'app/pages/RiderRegistrationPage/components/NotReturningRider';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/joy/Box';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import check from '../../assets/img/icon/check.png';
import { selectPhotoUpload } from 'app/pages/RiderRegistrationPage/slice/selectors';
import { useRiderRegistrationStateSlice } from 'app/pages/RiderRegistrationPage/slice';
import isApiError from 'utils/isApiError';
import { useErrorHandlingSlice } from 'app/error/slice';

const items = [
  { label: 'Personal Information', number: 1 },
  { label: 'Alumni Divisions', number: 2 },
  { label: 'Professional Affiliations', number: 3 },
];

const defaultValue: Alumni = {
  id: null,
  isReturningRider: true,
  isTrial: false,
  backNumber: '',
  firstName: '',
  lastName: '',
  primaryPhone: '',
  email: '',
  team: {
    id: '',
    name: '',
    children: null,
  },
  street: '',
  city: '',
  state: {
    id: '',
    name: '',
    children: null,
  },
  zip: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  height: 0,
  weight: 0,
  divisionClass: {
    id: '',
    name: '',
    children: null,
  },
  haveNotCompetedInClassLopeCanter: false,
  haveNotEarnedTwentyFivePointsInAssocs: false,
  haveFinishedTopTwoAsAERInNationals: false,
  haveFinishedTopThreeAaAER: false,
  haveCompetedTwoSeasonsAsAER: false,
  professionalAffiliations: [],
  usePhotoForPromotion: false,
  alumniIdOld: null,
};
const defaultPhotoValue = {
  formData: {
    file: undefined,
  },
};

const AlumniReturningMemberRegistration = () => {
  const { handleRequest } = useApi();
  const { actions } = useErrorHandlingSlice();
  const { lookupGetAllLookups } = LookupService;
  const { alumniCreateAlumni, alumniUploadTeamPhoto } = AlumniService;
  const [riderTableData, setRidertableData] = useState<
    GeneralRider[] | undefined
  >();
  const { riderGetRider, riderGetBackNumberList } = RiderService;
  const { actions: manageAppActions } = useRiderRegistrationStateSlice();
  const navigate = useNavigate();
  const [backNumber, setBackNumber] = useState('');
  const [affiliations, setAffiliations] = useState<GeneralLookup[]>([]);
  const [childDivisonData, setChildDivisonData] = useState<GeneralLookup[]>();
  const [formNumber, setFormNumber] = useState<number>(0);
  const [lookUp, setLookUp] = useState<GeneralLookupWrapper[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [riderId, setRiderId] = useState<string | undefined>();
  const [aluminiRiderData, setAluminiRiderData] =
    useState<Alumni>(defaultValue);
  const [radioGroupValues, setRadioGroupValues] = useState<RadioValueModel>({
    haveNotCompetedInClassLopeCanter: undefined,
    haveNotEarnedTwentyFivePointsInAssocs: undefined,
    haveFinishedTopTwoAsAERInNationals: undefined,
    haveFinishedTopThreeAaAER: undefined,
    haveCompetedTwoSeasonsAsAER: undefined,
    usePhotoForPromotion: undefined,
  });
  const { actions: PageActions } = useManagePageSlice();
  const pageLoader = useSelector(selectPageLoader);
  const dispatch = useDispatch();
  const [categoryCheck, setCategoryCheck] = useState<
    GeneralLookup[] | undefined
  >();
  const [filteredSeasonLookupData, setFilteredSeasonLookupData] = useState<
    GeneralLookup[]
  >([]);
  const [cancelPaymentCookie, setCancelPaymentCookie] = useState();
  const [heightWeight, setHeightWeight] = useState<heightWeightModel>();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);
  const totalPages = 3;
  const determinateValue = ((formNumber + 1) / totalPages) * 100;
  const [isSearchPage, setIsSearchPage] = useState(true);
  const [photoUploadPayload, setPhotoUploadPayload] = useState<{
    formData?: {
      file?: Blob;
    };
  }>(defaultPhotoValue);
  const [fileAvailability, setFileAvailability] = React.useState<boolean>(true);
  const [uploadFile, setUploadFile] = useState<File | null>();
  const photoUploadDataRedux = useSelector(selectPhotoUpload);
  const [backNumberData, setBackNumberData] = React.useState<
    string[] | undefined
  >([]);

  const handleSearchNextForm = () => {
    handleGetAluminiRiderDetails(riderId!);
    setIsSearchPage(false);
    setFormNumber(0);
  };
  const handleNextForm = () => {
    setFormNumber(formNumber =>
      formNumber === 2 ? formNumber : formNumber + 1,
    );
    if (formNumber === 2) {
      dispatch(PageActions.setPageLoader(true));
      handleRequest(alumniCreateAlumni(aluminiRiderData))
        .then(res => {
          if (res && !isApiError(res)) {
            if (cancelPaymentCookie) {
              Cookies.remove('paymentCancel');
            }
            if (aluminiRiderData.usePhotoForPromotion === false) {
              handlePhotoUpload(res.toString(), photoUploadPayload.formData);
            }
            const newMemberDataString = JSON.stringify(aluminiRiderData);
            const radioValuesString = JSON.stringify(radioGroupValues);
            Cookies.set('payload', newMemberDataString);
            Cookies.set('payloadRadiovalues', radioValuesString);
            dispatch(
              actions.dispatchToast({
                errorTxt: 'Alumini rider registration successful',
                show: true,
                errorType: 'success',
              }),
            );
            dispatch(PageActions.setPageLoader(false));
            navigate(`/payment/${payerTypes?.id}/${res}/false/false`);
            dispatch(manageAppActions.setPhotoUpload(photoUploadPayload));
          } else {
            dispatch(
              actions.dispatchToast({
                errorTxt: res.description,
                show: true,
                errorType: 'error',
              }),
            );
            dispatch(PageActions.setPageLoader(false));
          }
        })
        .catch(error => {
          dispatch(
            actions.dispatchToast({
              errorTxt: error.description,
              show: true,
              errorType: 'error',
            }),
          );
          dispatch(PageActions.setPageLoader(false));
          setFormNumber(4);
        });
    }
  };
  const handlePhotoUpload = (
    riderId: string,
    formData?: {
      file?: Blob;
    },
  ) => {
    handleRequest(alumniUploadTeamPhoto(riderId, formData))
      .then(res => {})
      .catch(error => {});
  };
  const handleBackForm = () => {
    if (formNumber === 0) {
      navigate('/rider-registration-page');
    } else {
      setFormNumber(formNumber =>
        formNumber === 0 ? formNumber : formNumber - 1,
      );
    }
  };
  const [payerTypes, setPayerTypes] = useState<GeneralLookup>();
  function getBackNumberList() {
    handleRequest(riderGetBackNumberList())
      .then(data => {
        setBackNumberData(data);
      })
      .catch(() => {});
  }

  const [categoryDefaultDatas, setCategoryDefaultDatas] =
    useState<GeneralLookup[]>();
  const [seasonId, setSeasonId] = useState<string>();
  const handleGetLookups = () => {
    dispatch(PageActions.setPageLoader(true));
    handleRequest(
      lookupGetAllLookups(
        'DIVISION,TEAM,CATEGORY,STATE,PROFESSIONALAFFILIATIONS,PAYER_TYPES,SEASON,ACTIVETEAM',
      ),
    )
      .then(res => {
        setLookUp(res!);
        res
          ?.find(obj => {
            return obj.type === 'SEASON';
          })
          ?.values?.find(item => {
            if (item.isAdminLive === true) {
              return setSeasonId(item?.id!);
            }
          });
        res
          ?.find(obj => {
            return obj.type === 'SEASON';
          })
          ?.values?.find(item => {
            if (item.isAdminLive === true) {
              return setCategoryDefaultDatas(item?.children!);
            }
          });
        let varPayerTypes = res?.filter(item => {
          if (item?.type === 'PAYER_TYPES') {
            return item.values;
          }
        });
        let payerTypeObj = varPayerTypes?.[0]?.values?.find(item => {
          if (item?.name === 'Rider') {
            return item ?? {};
          }
        });

        setPayerTypes(payerTypeObj);
        const categoryLookup = res?.find(obj => obj.type === 'CATEGORY');
        if (categoryLookup?.values) {
          let filteredcategory = categoryLookup?.values?.filter(item => {
            if (item.name === 'Alumni') {
              return { item, checked: false };
            }
          });
          let varAffiliations = res?.filter(item => {
            if (item?.type === 'PROFESSIONALAFFILIATIONS') {
              return item.values?.map(data => {
                return data.name;
              });
            }
          });
          let seasonData =
            res?.find(obj => {
              return obj.type === 'SEASON';
            })?.values ?? [];
          const filteredSeasonData = seasonData.filter(season => {
            const currentYear = new Date().getFullYear();
            const seasonYear = parseInt(season.name.split('-')[1]);
            return seasonYear <= currentYear + 1;
          });
          setChildDivisonData(filteredcategory?.[0].children!);
          setAffiliations(varAffiliations?.[0].values!);
          setFilteredSeasonLookupData(filteredSeasonData);
        }
        dispatch(PageActions.setPageLoader(false));
      })
      .catch(error => {
        console.log('error', error);
        dispatch(PageActions.setPageLoader(false));
      });
  };
  const [seasonValue, setSeasonValue] = useState<GeneralLookup>();

  const handleGetAluminiRiderDetails = (id: string | undefined) => {
    const seasonIdPayload = seasonValue?.id! ? seasonValue?.id! : seasonId!;
    dispatch(PageActions.setPageLoader(true));
    handleRequest(
      riderGetRider(id, seasonIdPayload)
        .then(res => {
          if (res) {
            const filteredPrimaryPhone = res?.primaryPhone
              ? res?.primaryPhone.replaceAll('-', '')
              : '';
            setAluminiRiderData({
              ...aluminiRiderData,
              id: null,
              isReturningRider: true,
              backNumber: res?.backNumber ?? '',
              firstName: res?.firstName ?? '',
              lastName: res?.lastName ?? '',
              height: res?.height ?? 0,
              weight: res?.weight ?? 0,
              primaryPhone: filteredPrimaryPhone,
              email: res?.email ?? '',
              street: res?.street ?? '',
              city: res?.city ?? '',
              zip: res?.zip ?? '',
              state: res?.state ?? {
                id: '',
                name: '',
                children: null,
              },
              team: {
                id: '',
                name: '',
                children: null,
              } as GeneralLookup,
              emergencyContactName: res.emergencyContactName!,
            });
            setHeightWeight({
              feet: Math.floor(res?.height!)
                ? Math.floor(res?.height!)
                : undefined,

              inches: Math.round((res?.height! - Math.floor(res?.height!)) * 12)
                ? Math.round((res?.height! - Math.floor(res?.height!)) * 12)
                : undefined,

              weight: res.weight ? res.weight : undefined,
            });
            dispatch(PageActions.setPageLoader(false));
          }
        })
        .catch(error => {
          console.log(error, 'error');
          dispatch(PageActions.setPageLoader(false));
        }),
    );
  };

  const [checkedMap, setCheckedMap] = React.useState(null);
  const handleCheckboxClick = riderId => {
    if (isChecked === riderId) {
      setCheckedMap(null);
    } else {
      setCheckedMap(riderId);
      setIsChecked(false);
    }
    setRiderId(riderId);
  };
  const handleChangeStepper = (e: StepperChangeEvent) => {
    const index = Number(e.value);
    if (index >= 0 && index !== formNumber) {
      setFormNumber(index);
    }
  };
  const handleItemClick = (index: number) => {};
  useEffect(() => {
    handleGetLookups();
    getBackNumberList();
    const paymentCancelStatus = Cookies.get('paymentCancel');
    setCancelPaymentCookie(paymentCancelStatus);
  }, []);
  useEffect(() => {
    const returningMemberData = Cookies.get('payload');
    const radioValuesCookieData = Cookies.get('payloadRadiovalues');

    if (returningMemberData) {
      const returningMemberDataParse = JSON.parse(returningMemberData);
      const radioValuesCookieDataParse = JSON.parse(radioValuesCookieData);
      setAluminiRiderData(returningMemberDataParse);
      setRadioGroupValues(radioValuesCookieDataParse);
      setIsSearchPage(false);
      setFormNumber(2);
      setHeightWeight({
        feet: Math.floor(returningMemberDataParse?.height!),
        inches: Math.round(
          (returningMemberDataParse?.height! -
            Math.floor(returningMemberDataParse?.height!)) *
            12,
        ),
        weight: returningMemberDataParse.weight,
      });
      setPhotoUploadPayload(photoUploadDataRedux ?? {});
      setUploadFile(photoUploadDataRedux?.formData?.file as File);
    }
  }, [cancelPaymentCookie]);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 767);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {pageLoader ? (
        <LoaderWrapper>
          <CircularLoader />
        </LoaderWrapper>
      ) : (
        <MainWrapper id="AlumniNewMemberResgistration">
          {isSearchPage ? (
            <RetriveReturningMemberDataForm
              categoryDefaultDatas={categoryDefaultDatas}
              seasonId={seasonId}
              setSeasonValue={setSeasonValue}
              seasonValue={seasonValue}
              handleNextForm={handleSearchNextForm}
              handleBackForm={handleBackForm}
              backNumber={backNumber}
              setBackNumber={setBackNumber}
              lookUp={lookUp}
              riderTableData={riderTableData}
              setRidertableData={setRidertableData}
              handleCheckboxClick={handleCheckboxClick}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              setCheckedMap={setCheckedMap}
              checkedMap={checkedMap}
              filteredSeasonLookupData={filteredSeasonLookupData}
            />
          ) : (
            <>
              <p className="Team-Heading">Returning Member</p>
              <div className="Stepper-wrapper">
                {isSmallScreen ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <CircularProgress
                      size="lg"
                      determinate
                      value={determinateValue}
                    >
                      {`${formNumber + 1} / ${totalPages}`}
                    </CircularProgress>
                    {items
                      .filter((_, index) => index === formNumber)
                      .map((item, index) => (
                        <h2 className="circular-stepper-heading" key={index}>
                          {item.label}
                        </h2>
                      ))}
                  </Box>
                ) : (
                  <Stepper
                    value={formNumber}
                    onChange={handleChangeStepper}
                    items={items}
                    className="form-stepper"
                  >
                    {items.map((item, index) => (
                      <StepperStep
                        key={index}
                        className={`stepper-step ${
                          index < formNumber ? 'completed' : ''
                        }`}
                        onClick={() => handleItemClick(index)}
                      >
                        {index < formNumber ? (
                          <span className="tick-icon">&#10003;</span>
                        ) : (
                          item.number
                        )}
                      </StepperStep>
                    ))}
                  </Stepper>
                )}
              </div>

              <>
                {formNumber === 0 ? (
                  <PersonalInfoForm
                    handleNextForm={handleNextForm}
                    handleBackForm={handleBackForm}
                    aluminiRiderData={aluminiRiderData}
                    setAluminiRiderData={setAluminiRiderData}
                    lookUp={lookUp}
                    categoryCheck={categoryCheck}
                    setHeightWeight={setHeightWeight}
                    heightWeight={heightWeight}
                    backNumberData={backNumberData}
                    setCategoryCheck={setCategoryCheck}
                  />
                ) : formNumber === 1 ? (
                  <AlumniDivisionForm
                    handleNextForm={handleNextForm}
                    handleBackForm={handleBackForm}
                    childDivisonData={childDivisonData}
                    aluminiRiderData={aluminiRiderData}
                    setAluminiRiderData={setAluminiRiderData}
                    lookUp={lookUp}
                    radioGroupValues={radioGroupValues}
                    setRadioGroupValues={setRadioGroupValues}
                  />
                ) : formNumber === 2 ? (
                  <PersonalAffiliationsForm
                    handleNextForm={handleNextForm}
                    handleBackForm={handleBackForm}
                    affiliations={affiliations}
                    setAffiliations={setAffiliations}
                    aluminiRiderData={aluminiRiderData}
                    setAluminiRiderData={setAluminiRiderData}
                    radioGroupValues={radioGroupValues}
                    setRadioGroupValues={setRadioGroupValues}
                    setPhotoUploadPayload={setPhotoUploadPayload}
                    photoUploadPayload={photoUploadPayload}
                    setFileAvailability={setFileAvailability}
                    fileAvailability={fileAvailability}
                    setUploadFile={setUploadFile}
                    uploadFile={uploadFile}
                  />
                ) : (
                  <></>
                )}
              </>
            </>
          )}
        </MainWrapper>
      )}
    </>
  );
};

export default AlumniReturningMemberRegistration;
const MainWrapper = styled.div`
  height: 100%;
  //stepper-style
  .Team-Heading {
    padding-top: 20px;
    text-align: center;
    color: #9597a6;
  }
  .circular-stepper-heading {
    color: #8a8a8a;
  }
  .MuiCircularProgress-progress,
  .MuiCircularProgress-sizeLg {
    color: #8a8a8a;
    stroke: #2dce98;
  }
  .css-zitgxn-JoyCircularProgress-track {
    stroke: #434343;
  }
  .k-step-done {
    .k-step-indicator-text {
      position: relative;
      ::after {
        position: absolute;
        content: '';
        z-index: 15;
        background: #2dce98;
        background-image: url(${check});
        background-repeat: no-repeat;
        background-size: inherit;
        background-position: center;
        border-radius: 50px;
        left: -8px;
        height: 26px;
        width: 26px;
        top: -1px;
      }
    }
  }
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  .k-stepper.form-stepper {
    pointer-events: none;
  }
  input {
    ::placeholder {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  .k-stepper .k-step-indicator::after {
    box-shadow: unset !important;
  }
  .k-stepper .k-step-indicator::after {
    border-style: unset !important;
  }
  .k-stepper .k-step-label .k-step-text {
    width: 200px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #8a8a8a;
    font-size: 12px;
    max-width: unset !important;
  }
  .k-progressbar {
    background: #5b5b5b;
  }
  .k-progressbar .k-selected {
    background: #2dce98;
  }
  .k-stepper .k-step-done .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-current .k-step-indicator {
    border-color: #2dce98;
    color: white;
    background-color: #2dce98;
  }
  .k-stepper .k-step-indicator {
    border-color: #5b5b5b;
    color: #c4c4c4;
    background-color: #5b5b5b;
    width: 25px;
    height: auto;
  }
  .k-stepper {
    padding-bottom: 20px;
  }
  @media (min-width: 100px) and (max-width: 319px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        padding: 0 10px;
        height: 55px;
        gap: 5px;
      }
      .circular-stepper-heading {
        font-size: 13px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 50px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 10px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 15px;
      }
      .circular-stepper-heading {
        font-size: 18px;
      }
      .MuiCircularProgress-root {
        --CircularProgress-size: 55px;
      }
    }
    .k-stepper .k-step-label .k-step-text {
      width: 55px;
      font-size: 11px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .Stepper-wrapper {
      width: -webkit-fill-available;
      .MuiBox-root {
        height: 55px;
        padding: 0 20px;
        gap: 25px;
      }
      .circular-stepper-heading {
        font-size: 22px;
      }
    }
  }
  //Page Responsive
  @media (min-width: 100px) and (max-width: 319px) {
    .form-MainWrapper {
      padding-top: 10px;
      .inner-BorderWrapper {
        padding: 20px 10px;
      }
      .TextFieldWrapper {
        width: 100%;
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .BackFieldWrapper {
        width: 100%;
        .StyledTextField {
        }
      }
      .StyledTextField {
        margin: 0px 0px;
      }
      .SHdropdown {
        margin: 0px 0px !important;
      }
      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 11px !important;
        }
      }
      .TextFieldGrid {
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .MainAddressWrapper {
        width: 100% !important;
      }
      .StyledHeightTextField {
        margin: 0px !important;
        width: 33.33%;
        input {
          width: 100%;
        }
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .StyledWeightTextField {
        margin: 0px !important;
        width: 33.33%;
        input {
          width: 100%;
        }
      }
      #BottomRightWrapper {
        height: 50px;
      }
      .NoteFieldWrapper {
        margin: 0px;
      }
      .HeightFieldWrapper {
        width: 100%;
        gap: 10px;
        padding-bottom: 10px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .form-MainWrapper {
      padding-top: 10px;
      .inner-BorderWrapper {
        padding: 20px 20px;
      }
      .TextFieldWrapper {
        width: 100%;
        &.BackNumberFieldWrapper {
          .BackNumberField-label {
            margin: 0px !important;
          }
        }
        &.height-weight-wrapper {
          .FlexFieldWrapper {
            flex-direction: column;
            align-items: flex-start;
          }
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
            align-items: baseline;
            width: 100%;
            height: 100%;
          }
        }
      }
      .StyledTextField {
        margin: 0px 0px;
      }
      .SHdropdown {
        margin: 0px 0px !important;
      }
      #InfoLabelWrapper {
        padding: 0 0px 10px 0px !important;
        label {
          font-size: 13px !important;
        }
      }
      .TextFieldGrid {
        padding: 10px 0px !important;
        &.height-note-grid {
          padding: 0px 0px !important;
          flex-direction: column-reverse !important;
        }
      }
      .MainAddressWrapper {
        margin: 0px !important;
        padding: 0px 0px 85px 0px !important;
      }
      .NoteFieldWrapper {
        margin: 0px !important;
        .note-block {
          font-size: 10px;
          letter-spacing: 0.2px;
        }
      }
      .StyledWeightTextField {
        margin: 0px !important;
        width: 33.33%;
        input {
          width: 100%;
        }
      }

      .StyledHeightTextField {
        width: 33.33%;
        input {
          width: 100%;
        }
        &.StyledHeightTextField-first {
          margin: 0px !important;
        }
      }
      .horizontal-line {
        margin: 20px 0px 15px 0px;
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .HeightFieldWrapper {
        width: 100%;
        gap: 10px;
        padding-bottom: 10px;
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 20px;
      }

      #InfoLabelWrapper {
        label {
          font-size: 10px !important;
        }
      }
      .TextFieldWrapper {
        width: 100%;
        &.height-weight-wrapper {
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
          }
        }
      }
      .StyledTextField {
        margin: 0px 8px;
      }
      .StyledWeightTextField {
        margin: 0px 8px 0px 0px !important;
      }
      .SHdropdown {
        margin: 0px 8px;
      }
      .note-block {
        font-size: 10px;
      }
      .NoteFieldWrapper {
        margin: 0 8px;
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledHeightTextField {
        margin: 0 8px !important;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 30px;
      }
      .SHdropdown {
        margin: 0px 12px;
      }

      #InfoLabelWrapper {
        label {
          font-size: 10px !important;
        }
      }
      .TextFieldWrapper {
        width: 100%;
        &.height-weight-wrapper {
          .note-block {
            font-size: 11px !important;
            padding-top: 22px !important;
          }
        }
      }
      .StyledTextField {
        margin: 0px 12px;
      }
      .note-block {
        font-size: 10px;
      }
      .NoteFieldWrapper {
        margin: 0 8px;
        .long-note {
          width: 100%;
        }
      }

      #BottomRightWrapper {
        height: 50px;
      }
      .StyledHeightTextField {
        margin: 0 8px !important;
      }
      .HeightFieldWrapper {
        width: 75%;
        gap: 10px;
        input {
          width: 100%;
        }
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 35px 30px 35px;
      }
      .StyledHeightTextField {
        margin: 0px 0 0 12px !important;
        width: 25%;
        input {
          width: 100%;
        }
      }
      .StyledWeightTextField {
        width: 25%;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
        .long-note {
          width: 100%;
        }
      }
      .note-block {
        font-size: 11px;
      }
      .NoteWrapper {
        width: 100%;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 40px 30px 40px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 100%;
        .StyledHeightTextField {
          margin: 0px !important;
          width: 25%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1448px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 50px 30px 50px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 100%;
        .StyledHeightTextField {
          margin: 0px !important;
          width: 25%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
    }
  }
  @media (min-width: 1449px) and (max-width: 1719px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 55px 40px 55px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 100%;
        .StyledHeightTextField {
          margin: 0px !important;
          width: 25%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
    }
  }
  @media (min-width: 1720px) and (max-width: 4000px) {
    .form-MainWrapper {
      .inner-BorderWrapper {
        padding: 20px 60px 50px 60px;
      }
      .FlexFieldWrapper {
        padding: 0 12px;
        gap: 10px;
      }
      .HeightFieldWrapper {
        gap: 10px;
        width: 100%;
        .StyledHeightTextField {
          margin: 0px !important;
          width: 25%;
          input {
            width: 100%;
          }
        }
      }
      .StyledWeightTextField {
        width: 25%;
        margin: 0px !important;
        input {
          width: 100%;
        }
      }
      .NoteFieldWrapper {
        margin: 0px 12px;
      }
    }
  }
`;
const StepperStep = styled.button`
  background-color: green !important;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-weight: bold;

  &.completed {
    background-color: green !important;
  }
`;
